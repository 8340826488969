<template>
    <div class="task-info">
        <div v-for="(item, index) in colorOption" :key="index">
            <div class="task-title" v-if="item.type === tableData.type" :style="{'background-color': item.color}">
                <div @click="backTask" class="hand op" style="margin-left: 14px">
                    <i class="el-icon-arrow-left" style="margin-right: 4px; font-size: 16px; vertical-align: middle"></i>
                    <span style="vertical-align: middle">返回</span>
                </div>
                <div style="font-size: 18px">
                    <span>{{tableData.title}}</span>
                </div>
                <div style="font-size: 16px; margin-right: 14px">
                    <i class="el-icon-document hand op" style="margin-right: 20px" @click="showTask = true"> 发布</i>
                    <i class="el-icon-edit hand op" v-if="user.uuid === tableData.uuid" style="margin-right: 20px" @click="editTask"> 编辑</i>
                    <i class="el-icon-delete hand op" v-if="user.uuid === tableData.uuid" @click="deleteTask"> 删除</i>
                </div>
            </div>
        </div>

        <div style="width: 100%; background-color: #FFFFFF; padding: 16px 20px 20px 20px">
            <div style="width: 94%; display: flex; justify-content: space-between; margin-bottom: 16px; color: #999999; font-size: 16px">
                <span class="contentType">
                    <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                    <span>{{tableData.roomName}}</span>
                </span>
                <span>|</span>
                <span class="contentType" style="color: #444444">
                    <i class="el-icon-s-claim" style="margin-right: 5px;"></i>
                    <span v-if="tableData.type === 1">任务委托</span>
                    <span v-if="tableData.type === 2">服务委托</span>
                    <span v-if="tableData.type === 3">采购序列</span>
                </span>
                <span>|</span>
                <span>下达任务：{{tableData.taskCount}}</span>
                <span>|</span>
                <span>完成数：<span style="color: #5BC130">{{tableData.taskFinishCount}}</span></span>
                <span>|</span>
                <span>未完成数：<span style="color: #FF5E5E">{{tableData.taskUnFinishCount}}</span></span>
                <span>|</span>
                <span>参与人员：<span style="color: black" v-if="tableData.teskUserList">{{tableData.teskUserList.length}}人</span>
                    <el-popover placement="bottom-end" width="300" trigger="hover">
						<div style="padding: 10px; height: 260px">
							<div v-for="(item, index) in tableData.teskUserList" :key="index">
								<div style="display: flex; margin-bottom: 10px;">
                                    <img :src="item.headIco" style="width: 40px; height: 40px; border-radius: 50%">
                                    <div style="font-size: 18px; font-weight: 600; margin-top: 6px; margin-left: 8px">{{item.nickName}}</div>
                                </div>
							</div>
						</div>
						<span class="hand" slot="reference" style="display: inline-block; font-size: 14px; margin-left: 20px">展开显示 <i style="font-size: 16px;" class="el-icon-caret-bottom"></i></span>
					</el-popover>
                </span>
                <span>|</span>
                <span>创建于{{tableData.creatTime}}</span>
                <span v-if="tableData.type === 3">|</span>
                <span v-if="tableData.type === 3" @click="getTaskCartList" class="hand op">购物车</span>
            </div>


            <el-table :data="taskList" border stripe style="width: 96%" :header-cell-style="{background:'#E8E8E8',color:'#000000'}">
                <el-table-column  prop="nickName" label="承接方" width="140">
                    <template slot-scope="scope">
                            <span style="font-weight: bold;" v-if="tableData.type === 1 || tableData.type === 2">{{scope.row.trusteeName}}</span>
                            <span style="font-weight: bold;" v-if="tableData.type === 3">{{scope.row.nickName}}</span>
                    </template>
                </el-table-column>
                <el-table-column  prop="title" label="任务名称" width="240"></el-table-column>
                <el-table-column  prop="detail" label="任务描述" width="300">
                    <template slot-scope="scope">
                        <div style="max-height: 90px;text-overflow:ellipsis;-webkit-line-clamp:4;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;">
                           {{scope.row.detail}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column  prop="finishTime" label="截止日期" width="200"></el-table-column>
                <el-table-column  prop="remaining_time" label="完成情况" width="140">
                    <template slot-scope="scope">
                        <div v-for="(item, index) in statusList" :key="index">
                            <span style="font-weight: bold;" v-if="scope.row.status === item.status">{{item.name}}</span></div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="110">
<!--                    <template slot-scope="scope">-->
<!--                        <el-button @click.native.prevent="orderInfo(scope.row.order_no,scope.row.is_task)" type="text" size="small">-->
<!--                            查看订单-->
<!--                        </el-button>-->
<!--                    </template>-->
                    <template slot-scope="scope">
                        <div v-if="tableData.type === 1">
                            <el-button @click.native.prevent="toTaskInfo(scope.row.id)" type="text" size="small">
                                查看详情
                            </el-button>
                        </div>
                        <div v-if="tableData.type === 2">
                            <el-button @click.native.prevent="toServiceInfo(scope.row.orderNo)" type="text" size="small">
                                查看详情
                            </el-button>
                        </div>
                        <div v-if="tableData.type === 3">
                            <el-button @click.native.prevent="toGoodsInfo(scope.row.id)" type="text" size="small">
                                查看详情
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--发布弹窗-->
        <el-dialog :visible.sync="showTask" :close-on-click-modal="false" width="52%" :show-close="false" class="event-form">
            <div class="top">
                <span class="contentType">
                    <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                    <span>{{tableData.roomName}}</span>
                </span>
                <span v-if="tableData.type === 1">添加任务委托</span>
                <span v-if="tableData.type === 2">添加服务订单</span>
                <span v-if="tableData.type === 3">添加商品订单</span>
                <img src="../../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="closeEvent">
            </div>
            <div>
                <ServiceForm :taskId="tableData.id" :roomId="tableData.roomId" v-if="tableData.type === 2"></ServiceForm>
                <AddDelegate @susTask="susTask" :taskId="tableData.id" :roomId="tableData.roomId" v-if="tableData.type === 1"></AddDelegate>
                <ProductForm :taskId="tableData.id" :roomId="tableData.roomId" v-if="tableData.type === 3"></ProductForm>
            </div>
        </el-dialog>

        <!--编辑弹窗-->
        <el-dialog :visible.sync="showInfo" :close-on-click-modal="false" width="52%" :show-close="false" class="event-form">
            <div class="top">
                            <span class="contentType">
                                <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                                <span>{{tableData.roomName}}</span>
                            </span>编辑任务序列
                <img src="../../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showInfo = false">
            </div>
            <div style="padding: 20px 40px">
                <div style="margin-bottom: 40px">
                    <div class="title-form">序列名称</div>
                    <el-input
                        type="text"
                        v-model="taskForm.title"
                        :show-word-limit="true"
                        maxlength="30"
                        minlength="2"
                        placeholder="请输入序列名称">
                    </el-input>
                </div>
                <div style="margin-bottom: 40px">
                    <div class="title-form">序列说明</div>
                    <el-input
                        type="textarea"
                        v-model="taskForm.content"
                        :show-word-limit="true"
                        maxlength="500"
                        :rows="5"
                        placeholder="请输入序列说明">
                    </el-input>
                </div>
                <div style="display: flex; justify-content: center">
                    <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;" @click="editTasksList">确认修改</div>
                </div>
            </div>
        </el-dialog>

        <!--序列购物车-->
        <el-dialog :visible.sync="showCart" width="52%" :close-on-click-modal="false" :show-close="false" class="event-form">
            <div class="top">
                <span>采购序列购物车</span>
                <img src="../../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showCart = false">
            </div>
            <div style="padding: 20px">
                <TaskCart :taskId="taskId"></TaskCart>
            </div>

        </el-dialog>
    </div>

</template>

<script>
    import ServiceForm from "./task/ServiceForm";
    import AddDelegate from "./task/AddDelegate";
    import ProductForm from "./task/ProductForm";
    import NullBox from "./task/NullBox";
    import TaskCart from "./task/TaskCart";
    export default {
        name: "TaskInfo",
        components: {TaskCart, NullBox, ProductForm, AddDelegate, ServiceForm},
        data() {
            return {
                tableData: {},
                statusList: [{status: 0, name: '进行中'}, {status: 1, name: '已结束'}],
                colorOption: [
                    {type: 1, color: "#FF9800"},
                    {type: 2, color: "#2970FF"},
                    {type: 3, color: "#FF5E5E"},
                ],

                taskForm: {},
                showInfo: false,
                showTask: false,
                showCart: false,

                cartList: [],

                taskList: [],
            }
        },

        props: {
            taskId: {
                type: Number,
                default() {
                    return 0
                }
            },
        },

        computed: {
          is_login(){
            return this.$store.state.is_login
          },
          user(){
            return this.$store.state.user
          }
        },

        mounted() {
            this.getTaskInfo()
        },

        methods: {
            // 获取序列购物车列表
            getTaskCartList() {
                this.showCart = true
            },

            // 创建完成
            susTask() {
                this.showTask = false;
                this.getTaskInfo()
            },

            // 删除任务序列
            deleteTask() {
                let that = this;
                this.utils.confirm('确认删除此序列？', function () {
                    that.newApi.delTaskSequence({sequenceId: that.taskId}).then(res => {
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data);
                            that.backTask()
                        }
                    })
                })
            },

            // 确认修改任务序列
            editTasksList() {
                let param = {};
                param.sequenceId = this.taskId;
                param.title = this.taskForm.title;
                param.content = this.taskForm.content;
                this.newApi.editTaskSequence(param).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.showInfo = false;
                        this.getTaskInfo()
                    }
                })
            },

            editTask() {
                this.showInfo = true;
                this.taskForm = {
                    title: this.tableData.title,
                    content: this.tableData.content,
                }
            },

            // 获取任务序列详情
            getTaskInfo() {
                this.newApi.getTaskSequenceInfo({sequenceId: this.taskId}).then(res => {
                    this.tableData = res.data
                    if (res.data.type === 3) {
                        this.newApi.getTaskOrderList({questId: this.taskId}).then(res => {
                            if (res.isSuccess === 1) {
                                this.taskList = res.data;
                            }
                        })
                    }
                    if (res.data.type === 1) {
                        this.taskList = res.data.teskList;
                    }
                    if (res.data.type === 2) {
                        this.taskList = res.data.teskList;
                    }
                })
            },

            // 返回
            backTask() {
                this.$parent.toBack()
            },


            // 关闭事件弹窗
            closeEvent() {
                this.showTask = false
            },

            // 查看订单
            orderInfo(orderNo,isTask) {
                if(isTask == 0){
                    window.open('/user/order/info/'+orderNo);
                }else{
                    window.open('/user/task_order_info/'+orderNo);
                }
            },

            // 查看详情
            toTaskInfo(id) {
                this.utils.b('/room/serviceInfo/' + id)
            },
            toGoodsInfo(id) {
                this.utils.b('/room/productInfo/' + id)
            },
            toServiceInfo(id) {
                this.utils.b('/shop/serviceInfo/' + id)
            },
        }
    }
</script>

<style scoped>
    .task-info .task-title {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        color: #FFFFFF;
        font-size: 16px;
    }

    .task-info .contentType{
        font-size: 16px;
        color: #031F88;
        margin-right: 10px;
    }

    .task-info .event-form .top {
        text-align: center;
        position: relative;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }
</style>
