<template>
    <div style="padding:20px 40px; min-height: 800px">
<!--        <div class="title-box">-->
<!--            <div>由你组织</div>-->
<!--            <div style="font-size: 14px">{{myEventList.myCreat.length}}个活动</div>-->
<!--        </div>-->
        <div style="display: flex; flex-wrap: wrap" class="event-box" v-if="docList.length > 0">
            <DocCard type="all" v-for="item in docList" :key="item.id" :data="item" style="margin-right: 20px;margin-bottom: 20px;width: 303px"></DocCard>
        </div>

    </div>
</template>

<script>
    import DocCard from "@/pages/room/components/event/DocCard";
    export default {
        name: "moreDocView",
      components: {DocCard},
      data() {
            return {
              docList:[],
            }
        },

        props: {
            roomId: {
                type: String,
                default() {
                    return ''
                }
            }
        },

        mounted() {
            this.getDocList();
        },

        methods: {
            // 获取付费文档列表
          getDocList() {
            var that = this;
                this.newApi.getDocList({
                  roomId: that.roomId
                }).then(res => {
                  that.docList = res.data
                })
            },


            // 进入事件详情
            toEvent(id) {
              this.$router.push({ path: '/room/docDetail/' + id})
            },
        }
    }
</script>

<style scoped>
    .title-box {
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .event-body {
        width: 210px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .event-body .event-title {
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
    }

    .event-body .active {
        width: 210px;
        background-image: url("../../imgs/title1.png");
    }

    .event-body .event-content {
        min-height: 120px;
        padding: 10px 20px 10px 20px;
        line-height: 30px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-body .event-info {
        min-height: 120px;
        padding: 20px;
        line-height: 20px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-body .event-content .image-ico {
        width: 100%;
        height: 112px;
    }

    .event-body .event-content .image-btn {
        width: 110px;
        height: 28px;
        margin-top: 6px;
        margin-right: 14px;
        text-align: center;
        line-height: 28px;
        font-weight: 600;
        border-radius: 5px;
        background-color: #E4E6EB;
    }

    .event-body .event-content .send-btn {
        width: 44px;
        height: 24px;
        margin-top: 6px;
        line-height: 28px;
        padding: 2px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #E4E6EB;
        font-weight: 600;
        text-align: center;
    }
</style>
