<template>
	<div class="content-all">
    <div v-if="data.menuType !== 0">
      <VideoContent v-if="(data.type === 1 && data.menuType === 4) || (data.type === 1 && data.isKwFormat === 1 && data.content.bgbType === 8)" :data="data"></VideoContent>
      <ImagesContent v-if="(data.type === 1 && data.menuType === 3) || (data.type === 1 && data.isKwFormat === 1 && data.content.bgbType === 7)" :data="data"></ImagesContent>
      <Enclosure v-if="(data.type === 1 && data.menuType === 5) || (data.type === 1 && data.isKwFormat === 1 && data.content.bgbType === 9)" :data="data"></Enclosure>
      <Article v-if="(data.type === 1 && data.menuType === 1) || (data.type === 1 && data.isKwFormat === 1 && data.content.bgbType === 6)" :data="data"></Article>
      <HotTemplates v-if="data.type === 2" :data="data"></HotTemplates>
      <DiyTemplates v-if="data.type === 3" :data="data"></DiyTemplates>
      <Dynamic :data="data" v-if="data.type === 1 && data.menuType === 2"></Dynamic>
    </div>

    <div v-if="data.menuType === 0">
      <VideoContent v-if="Object.keys(data.content).includes('videos') && data.content.videos.length > 0" :data="data"></VideoContent>
      <ImagesContent v-else-if="Object.keys(data.content).includes('images') && data.content.images.length > 0" :data="data"></ImagesContent>
      <Enclosure v-else-if="Object.keys(data.content).includes('enclosure') && data.content.enclosure.length > 0 && !Object.keys(data.content).includes('title')" :data="data"></Enclosure>
      <Article v-else-if="Object.keys(data.content).includes('title')" :data="data"></Article>
      <HotTemplates v-else-if="!Object.keys(data.content).includes('content') && data.type === 2" :data="data"></HotTemplates>
      <DiyTemplates v-else-if="!Object.keys(data.content).includes('content') && data.type === 3" :data="data"></DiyTemplates>
      <Dynamic :data="data" v-else></Dynamic>
    </div>
     <el-empty v-if="data.length === 0" :image-size="300" description="您还没有加入room，请先加入room"></el-empty>
	</div>
</template>

<script>
	//动态
	import Dynamic from './Dynamic'
	import ImagesContent from "./ImageContent";
	import VideoContent from "./VideoContent";
	import Enclosure from "./Enclosure";
	import Article from "./Article";
	import HotTemplates from "./HotTemplates";
	import DiyTemplates from "./DiyTemplates";
	export default {
		name:"ContentALL",
		components: {
			DiyTemplates,
			HotTemplates,
			Article,
			Enclosure,
			VideoContent,
			ImagesContent,
			Dynamic
		},
        computed: {

        },
		data () {
			return {
			}
		},
		props:{
			data:{
				type:Object,
				default(){
                    return {
                        content:{},
                    }
                }
			},
			showRoomName:{
				type:Boolean,
				default(){
					return true;
				}
			},
        },
		created:function(){

		},
		mounted: function () {
		},
		methods:{

		}
	}

</script>

<style scoped>

</style>
