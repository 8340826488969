<template>
  <div>
    <div class="message-body">
      <div style="margin-bottom: 10px">
        <div class="title-form">邀请Room管理员</div>
        <div style="display: flex; flex-wrap: wrap">
          <div style="display: flex; justify-content: center;" class="list-content" v-for="(friend, index) in roomUserList.adminList" :key="index">
            <img class="head-ico" style="width: 40px; height: 40px; border-radius: 50%; margin: auto 6px;" :src="friend.headIco">
            <div style="margin: auto 0; width: 70%">
              <div style="font-size: 16px; font-weight: 600; margin-bottom: 2px">
                {{friend.nickName}}
              </div>
              <div style="color: #AAAAAA; font-size: 14px">
                <span v-if="friend.address.cityName">{{friend.address.cityName[1]}}</span><span v-if="friend.major && friend.address.cityName"> | </span>{{friend.major}}
              </div>
            </div>
            <div v-if="friend.select === false" @click="friend.select = true" class="hand op list-btn" style="color: #FF9800; border: 1px #FF9800 solid">选择好友</div>
            <div v-if="friend.select === true"  @click="friend.select = false" class="hand op list-btn" style="color: #FFFFFF; background-color: #FF9800">取消</div>
          </div>
        </div>
      </div>
      <div>
        <div class="title-form">邀请Room成员</div>
        <div style="display: flex; flex-wrap: wrap">
          <div style="display: flex; justify-content: center;" class="list-content" v-for="(friend, index) in roomUserList.userList" :key="index">
            <img class="head-ico" style="width: 40px; height: 40px; border-radius: 50%; margin: auto 6px;" :src="friend.headIco">
            <div style="margin: auto 0; width: 70%">
              <div style="font-size: 16px; font-weight: 600; margin-bottom: 2px">
                {{friend.nickName}}
              </div>
              <div style="color: #AAAAAA; font-size: 14px">
                <span v-if="friend.address.cityName">{{friend.address.cityName[1]}}</span><span v-if="friend.major && friend.address.cityName"> | </span>{{friend.major}}
              </div>
            </div>
            <div v-if="friend.select === false" @click="friend.select = true" class="hand op list-btn" style="color: #FF9800; border: 1px #FF9800 solid">选择好友</div>
            <div v-if="friend.select === true"  @click="friend.select = false" class="hand op list-btn" style="color: #FFFFFF; background-color: #FF9800">取消</div>
          </div>
        </div>

      </div>
    </div>
    <div style="padding-bottom: 10px">
      <div class="hand op" @click="toVideo" style="width: 180px; height: 40px; margin: 20px auto; text-align: center; line-height: 40px; border-radius: 5px; background-color: #2970FF; color: #FFFFFF; font-size: 16px; font-weight: 600">发起视频会议</div>
    </div>
  </div>

</template>

<script>

export default {
  name: "videoSelect",
  components: {},
  data() {
    return {
      roomUserList: [],
    }
  },

  props: {
    roomId: {
      type: String,
      default() {
        return ''
      }
    }
  },
  watch: {
    roomId: {
      handler() {
        this.getRoomUser()
      }
    }
  },

  computed: {
    user() {
      return this.$store.state.user
    },
    is_login(){
      return this.$store.state.is_login
    },
  },

  mounted() {
      this.getRoomUser()
  },

  methods: {
    // 获取Room内部成员
    getRoomUser() {
      this.newApi.getRoomUserList({
        roomId: this.roomId
      }).then(res =>{
        for (const item of res.data.adminList) {
            item.select = false
        }
        for (const item of res.data.userList) {
          item.select = false
        }
        this.roomUserList = res.data;
      })
    },

    // 发起视频通话
    toVideo() {
      let userList = [];
      for (const item of this.roomUserList.adminList) {
        if (item.select === true) {
          userList.push(item.uuid)
        }
      }
      for (const item of this.roomUserList.userList) {
        if (item.select === true) {
          userList.push(item.uuid)
        }
      }
      if (userList.length === 0) {
        this.utils.msg('请选择至少一个成员')
        return
      }
      this.$emit('setVideo', userList)
      this.$emit('closeEvent')
      this.getRoomUser()
    },
  }


}
</script>

<style scoped>
.message-body .message-body {
  min-height: 200px;
  padding: 20px;
}

.message-divider .el-divider--horizontal {
  margin: 0;
}

.message-body .title-form {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.message-body .list-content {
  width: calc(100%/3 - 15px);
  height: 64px;
  border: 1px #CED0D4 solid;
  margin: 0 10px 10px 0;
}

.message-body .list-btn {
  width: 78px;
  height: 24px;
  line-height: 24px;
  margin: auto 10px;
  border-radius: 5px;
  text-align: center;
}
</style>