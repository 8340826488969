<template>
   <div onselectstart="return false;" style="overflow:hidden;min-width: 1500px;background-color: #f0f0f0;" @click.stop="popupStstus=false" @contextmenu.prevent >
        <div style="width: 1500px;margin:108px auto 0 auto;display: flex;">
            <div class="page_content" style="width:1206px;height: auto;overflow:hidden;flex: 1; margin-left: 294px;min-height: 500px;">
<!--	            路径选择-->
	            <div  style="width: calc(100% - 40px);height: 40px;background: #c1c1c1;padding: 0 20px;margin-top: 5px">
		            <div style="width: auto;" v-for="(p,k) in path" :key="k" class="hand op">
			            <div class="hand op path"  :style="path.length===k+1?'font-size: 20px;font-weight: bold;':''" @click="toPath(p.id,k)">{{p.name}}</div>
		            </div>

	            </div>
<!--	            右键菜单-->
	            <transition name="fade">
		            <div v-show="popupStstus" ref="popup" class="popup" >
			            <div v-for="menu in menu" :key="menu.val" @click="selectMenu(menu)" class="menu-item" @mouseover="highlightMenu" @mouseleave="unhighlightMenu">
				            {{ menu.name }}
			            </div>
		            </div>
	            </transition>
	            <p style="width: 100%;height: 20px;background: #f0f0f0;line-height: 20px;margin: 5px 20px;">使用鼠标右键在空白地方创建文件夹,将下方的ROOM可拖拽至创建的文件夹中</p>
                <div @contextmenu="showPopup2"  style="overflow:hidden;position: relative; margin-top: 5px; background-color: #FFFFFF;min-height: 200px; width: 100%;padding:30px;box-sizing: border-box;" ref="container" >
                  <div @click="showPopover = true" class="hand op" style="position: absolute; top: 10px; right: 20px; width: 100px; text-align: center; padding: 4px 0; background-color: #1C7EFF; color: #FFFFFF; border-radius: 10px">新建文件夹</div>
<!--	                文件夹和文件渲染及右键操作-->
					<div class="folder" style="position: relative" v-for="(item,index) in folder_list" :key="index"  :data-item="JSON.stringify(item)" >
            <div style="position: absolute; top: 4px; right: 4px; z-index: 1000" v-if="item.name">
              <el-dropdown trigger="click" @command="toOption($event,item)">
                <div class="hand op">
                  <i style="font-size: 16px; font-weight: 600" class="el-icon-s-tools"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="delete">删除</el-dropdown-item>
                  <el-dropdown-item command="reName">重命名</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
						<el-tooltip :disabled="(item.name && item.name.length < 14) || (item.roomInfo && item.roomInfo.name && item.roomInfo.name.length < 14)" effect="dark" :content="item.name?item.name:item.roomInfo.name" placement="top">
							<div v-if="item.roomId===0" style="position: relative;width: 100%;height:100%;cursor: pointer;"  @dblclick="openFolder(item.id,item.name)" @drop="handleDrap($event,item)"  :draggable="false" @dragover.prevent >
								<div style="overflow:hidden;margin-top:10px;height: 50px;width: 100%;display: flex;justify-content: center;flex-direction:column;align-items: center;">
									<img class="unselectable" src="../../../src/assets/imgs/room/folder_close.png" style="width:36px;height:36px;">
								</div>
								<div class="unselectable" style="width: 100%; height: 60px; line-height: 20px;text-align: center; font-size: 16px; font-weight: bold; overflow: hidden;display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3;text-overflow: ellipsis; ">
									{{ item.name }}
								</div>
							</div>
							<div v-else style="position: relative;width: 100%;height:100%;cursor: pointer;background: #f8f3da" :draggable="true" @dragstart="handleDrapStart($event, item.roomInfo,path[path.length-1].id)" @dragend="dragend()">
								<div style="overflow:hidden;margin-top:10px;height: 50px;width: 100%;display: flex;justify-content: center;flex-direction:column;align-items: center;">
									<img class="unselectable" :src="item.roomInfo.cover" style="width:36px;height:36px;">
								</div>
								<div class="unselectable" style="width: 100%; height: 60px; line-height: 20px;text-align: center; font-size: 16px; font-weight: bold; overflow: hidden;display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3;text-overflow: ellipsis; ">
									{{item.roomInfo.name}}
								</div>
							</div>
						</el-tooltip>
					</div>
                </div>
	            <p style="width: 100%;height: 20px;background: #f0f0f0;line-height: 20px;margin: 5px 20px;">可管理ROOM</p>
	            <div style="overflow:hidden;margin-top: 5px; background-color: #FFFFFF;min-height: 495px; width: 100%;padding:20px 30px ;box-sizing: border-box;" @drop="handleDrapRoom($event)" @dragover.prevent>
		            <div style="width:calc((100% - 75px) / 5);margin-bottom:17px;margin-right:15px;float: left"  v-for="(item,index) in my_room_list" :key="index"  :data-item="JSON.stringify(item)">
			            <div class="new-room" :draggable="true" @dragstart="handleDrapStart($event, item,0)" @dragend="dragend()">
				            <div class="hb hand" style="margin: 41px 0;padding: 0 10px" @click="toRoom(item.id)">
					            <img class="room_head" :src="item.cover">
					            <div class="room_name">{{item.name}}</div>
				            </div>
				            <div class="hb" style="margin-top:25px;background: #2970FF;height: 24px;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;box-sizing: border-box;padding: 0 10px;font-size: 12px;color: #FFFFFF;">
					            <div>关注  {{ item.fansInput }}</div>
				            </div>
			            </div>
		            </div>
	            </div>
	            <!--修改文件夹的名称-->
	            <el-dialog :close-on-click-modal="false" :visible.sync="showReName" width="400px" top="300px" class="event-form" custom-class="dialog">
		            <div style="text-align: center; padding-top: 30px; font-size: 18px; font-weight: 600">修改文件夹名称</div>
		            <div style="padding: 20px 40px; overflow: hidden">
			            <div style="margin-bottom: 30px">
				            <el-input v-model="reName" placeholder="请输入文件夹名称"></el-input>
			            </div>
			            <div style="display: flex; justify-content: center; margin-bottom: 10px">
				            <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;" @click="updateName">确定</div>
			            </div>
		            </div>
	            </el-dialog>
	            <!--新建文件夹-->
	            <el-dialog :close-on-click-modal="false" :visible.sync="showPopover" width="400px" top="300px" class="event-form" custom-class="dialog">
		            <div style="text-align: center; padding-top: 30px; font-size: 18px; font-weight: 600">新建文件夹</div>
		            <div style="padding: 20px 40px; overflow: hidden">
			            <div style="margin-bottom: 30px">
				            <el-input v-model="folderName" placeholder="请输入文件夹名称"></el-input>
			            </div>
			            <div style="display: flex; justify-content: center; margin-bottom: 10px">
				            <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;" @click="newFolderAdd(path[path.length-1].id)">确定</div>
			            </div>
		            </div>
	            </el-dialog>
            </div>
        </div>


    </div>
</template>

<script>
import Head from "../../components/common/Head";
import RoomNav from './components/RoomNav'
// import Folder from './components/Folder'
	// import NewRoom from './components/NewRoom'
	export default {
		name:"Manage",
		components: {
			Head,
			RoomNav,
			// Folder,
			// NewRoom
		},
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            }
        },
		data () {
			return {
				id:"",
        selectId: '',
				name:"",
				folder_list:[],
				my_room_list:[],
				dialogFormVisible: false,
				title:"",
				findex:0,
				path:[{id:0,name:"主页/"}],
                folderName: '',
				folderMenu:[{"val":"del","name":"删除文件夹"},{"val":"save","name":"重命名"}],
				roomMenu:[],
				blankMenu:[{"val":"createFolders","name":"创建文件夹"}],
				rightData:"",
				menu:[],
				popupStstus:false,
				showPopover:false,
                showReName: false,
                reName: '',
				folder_bg:false,
			}
		},
		props:{

        },
		created:function(){
			this.$EventBus.$on('my_folder_list_remove', (id) => {
				this.my_room_list = this.my_room_list.filter(item => item.id !== id);
			})
			this.$EventBus.$on('my_folder_room_list_add', (roomInfo) => {
				if (this.my_room_list.some(item => item.id === roomInfo.id)) {
					return false;
				}
				this.my_room_list.push(roomInfo);
			})
		},
		mounted: function () {
			let that = this;
			this.backTop();
			this.getFolderList(0);
			that.newApi.getFolderAndRoomList({"fid":0}).then(ret =>{
				that.my_room_list = ret.data.roomList;
				that.folder_list = ret.data.folderList
			})
		},
		methods:{
      // 删除重命名操作
      toOption(event, item) {
        if (event === 'delete') {
          this.newApi.delFolderRecord({id: item.id}).then(res =>{
            if (res.isSuccess == 1) {
              this.utils.sus(res.data);
              this.getFolderList(this.path[this.path.length-1].id);
            }else {
              this.utils.err("操作失败");
            }
          })
        }
        if (event === 'reName') {
          this.selectId = item.id;
          this.reName = item.name;
          this.showReName = true;
        }
      },

			// 开始进行拖拽
			handleDrapStart(event, roomInfo,folderId) {
				this.folder_bg = true;
				roomInfo.folderId = folderId;
				event.dataTransfer.setData("roomInfo", JSON.stringify(roomInfo));
			},
			// 拖拽到文件夹中
			handleDrap(event,data) {
				let that = this;
				let roomInfo = JSON.parse(event.dataTransfer.getData("roomInfo"));
				if (roomInfo){
					that.newApi.addRoomsInFolder({roomId:roomInfo.id,fid:data.id}).then((res)=>{
						if (res.isSuccess==1){
							this.folder_list = this.folder_list.filter(item => item.roomId !== roomInfo.id);
							this.my_room_list = this.my_room_list.filter(item => item.id !== roomInfo.id);
							that.$EventBus.$emit("my_room_list_remove"+roomInfo.folderId, roomInfo.id);
							that.$EventBus.$emit("my_room_list_add"+data.id, roomInfo);
						}
					})
				}
			},
			// 拖拽到公共区中
			handleDrapRoom(event) {
				let that = this;
				let roomInfo = JSON.parse(event.dataTransfer.getData("roomInfo"));
				if (roomInfo){
					if (that.my_room_list.some(item => item.id=== roomInfo.id)) {
						this.folder_list = this.folder_list.filter(item => item.roomId !== roomInfo.id);
						return false;
					}
					that.newApi.delFolderRoom({id:roomInfo.id}).then((res)=>{
						if (res.isSuccess==1){
							this.folder_list = this.folder_list.filter(item => item.roomId !== roomInfo.id);//当前的文件夹列表删除
							that.my_room_list.push(roomInfo);//当前room列表增加
							that.$EventBus.$emit("my_room_list_remove"+roomInfo.folderId, roomInfo.id);//通知左侧的文件夹删除
							that.$EventBus.$emit("my_room_list_add0", roomInfo);// 通知左侧的room列表增加

						}
					})
				}
			},
			// 拖拽完成把数据清除一下
			dragend(){
				event.dataTransfer.clearData();
			},


			showPopup2(event) {
				let target = event.target;
				while (target && target !== this.$el) {
					if (target.classList.contains('folder')) {
						const item = target.getAttribute('data-item');
						const popup = this.$refs.popup;
						// 设置弹窗的位置,立即在鼠标位置显示出来
						popup.style.left = `${event.pageX}px`;
						popup.style.top = `${event.pageY}px`;
						this.popupStstus=true;
						this.rightData = JSON.parse(item);
						this.menu = JSON.parse(item).roomId === 0 ? this.folderMenu : this.roomMenu;return;
					}
					target = target.parentNode;
				}
				this.menu = this.blankMenu;
				const popup = this.$refs.popup;
				// 设置弹窗的位置,立即在鼠标位置显示出来
				popup.style.left = `${event.pageX}px`;
				popup.style.top = `${event.pageY}px`;
				this.popupStstus=true;
				this.popupStstus=true;
			},

			highlightMenu(menu) {
				menu.isHighlighted = true;
			},
			unhighlightMenu(menu) {
				menu.isHighlighted = false;
			},


    // 文件夹重命名
    updateName() {
      let params = {};
      params.name = this.reName
      params.id = this.selectId ? this.selectId : this.rightData.id
        this.newApi.editRoomFolderName(params).then(res => {
            if (res.isSuccess === 1) {
                this.utils.sus(res.data);
                this.showReName = false;
                this.selectId = '';
                this.reName = "";
                this.getFolderList(this.path[this.path.length-1].id)
            }
        })
    },

			/**
			 * 选择鼠标右键的选择项
			 * @param data
			 */
			selectMenu(item){
				switch (item.val) {
					case "del":
						this.newApi.delFolderRecord({id: this.rightData.id}).then(res =>{
							if (res.isSuccess == 1) {
								this.utils.sus(res.data);
								this.getFolderList(this.path[this.path.length-1].id);
							}else {
								this.utils.err("操作失败");
							}
						})
						break;
					case "save":
						this.reName = this.rightData.name;
						this.showReName = true;
						break;
					case "delRoom":
						this.newApi.delFolderRoom({id: id}).then(res =>{
							if (res.isSuccess == 1) {
								this.utils.sus(res.data);
								this.getFolderList(this.path[this.path.length-1].id);
							}else {
								this.utils.err("操作失败");
							}
						})
						break;
					case "createFolders":
						this.showPopover=true;
						break;
				}
				this.popupStstus=false;
			},

			/**
			 * 关闭创建文件夹的窗口
			 */
			closePopover(){
				this.showPopover=false;
				this.folderName = '';
			},

			backTop () {
				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			/**
			 * 通过路径打开页面
			 * @param id
			 * @param index
			 */
			toPath(id,index){
				this.getFolderList(id);
				const l = (this.path.length)-(index+1)
				this.path.splice(index+1,l);
			},
			// 打开文件夹
			openFolder(id,name){
				this.getFolderList(id);
				var info = {name:name+'/',id:id}
				this.path.push(info);
			},
            // 进入room详情
            toRoom(id) {
                this.utils.a('/room/info/' + id)
            },

            // 新建room文件夹
			newFolderAdd(fid){
				if (this.folderName==""){
					this.utils.err("文件夹名不可为空");return false;
				}
                this.newApi.createRoomFolder({name: this.folderName, fid: fid}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.folderName = '';
	                    this.getFolderList(this.path[this.path.length-1].id);
                        this.utils.sus(res.data);
	                    this.closePopover();
                    }
                })
			},

            // 获取room文件夹
            getFolderList(id){
                var that = this;
                this.newApi.getRoomFolderList({fid: id}).then(ret =>{
                    that.folder_list = ret.data;
                })
            },

			rename_folder:function(){
				var that = this;
				this.ajax('rename_folder',{
					name:that.name,
					id : that.id
				},function(){
					that.dialogFormVisible = false;
					that.$refs.folder[that.findex].updata(that.name);
                    that.$EventBus.$emit("changeFolder");
					that.name = "";
				})
			},
		}
	}

</script>

<style scoped>
.path{
	font-size: 16px;line-height: 40px;float: left;width: auto;
}
.path:hover{
	text-decoration:underline;font-size: 20px;
}


/**
文件夹样式
 */
.folder {
	float:left;width:calc(100%/11  - 10px);margin: 10px;height: 120px;
	display: flex; justify-content: flex-start;align-items:center;
	box-shadow: 0 0 8px 2px rgba(0,0,0,0.3);
	border-radius: 5px;overflow: hidden;
}
.highlight {
	border-color: #f00;
}

.folder .management-box {
	width: 240px;
	height: 400px;
	border: 1px #F5F5F5 solid;
}

.folder .management-tittle {
	height: 30px;
	font-size: 20px;
	display: flex;
	justify-content: space-between;
	font-weight: 600;
	padding: 10px;
	background-color: #F5F5F5;
}

.folder .management-body {
	overflow: auto;
	width: 100%;
	max-height: 360px;
}

.folder .friend-num {
	font-size: 14px;
	margin-top: 6px;
	color: #D2D2D2;
}

.folder .new-room{
    height: 146px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #CBCBCB;
}

.folder .hb{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.foot-btn {
    width: 128px;
    height: 20px;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
}

.room_head{
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #707070;
    box-sizing: border-box;
    border-radius: 50%;
}
.room_name{
    width: calc( 100% - 45px);
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
    color: #555555;
    font-weight: 600;
    font-size: 14px;
}

.popup {
	position: fixed;
	background-color: #494848;
	border-radius: 4px;
	z-index: 999;
	color: #f0f0f0;
	overflow: hidden;
	padding: 5px 0;
}
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
.menu-item {
	cursor: pointer; /* 添加指针样式，表示可点击 */
	padding: 5px 15px; /* 设置上下内边距 */
	width: calc(100% - 30px); /* 设置宽度 */
}

.menu-item:hover {
	background-color: #8a8787; /* 鼠标悬停时改变背景颜色 */

}

.custom-popover {
	left: 50% !important;
	top: 50% !important;
	transform: translate(-50%, -50%) !important;
}

/deep/.el-dialog__headerbtn {
	font-size:30px !important;
}
/deep/.dialog{
	border-radius: 10px !important;
}
.unselectable {
	-webkit-user-drag: none;
	-webkit-user-select: none; /* Safari 3.1+ */
	-moz-user-select: none; /* Firefox 2+ */
	-ms-user-select: none; /* IE 10+ */
	user-select: none; /* Standard syntax */
}

.new-room{
	width: 100%;
	height: 146px;
	background: #FFFFFF;
	box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
	border-radius: 5px;
	border: 1px solid #CBCBCB;
}
.hb{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.room_head{
	width: 40px;
	height: 40px;
	background: #FFFFFF;
	border: 1px solid #707070;
	box-sizing: border-box;
	border-radius: 50%;
}
.room_name{
	width: calc( 100% - 45px);
	word-break: break-all;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2; /* 这里是超出几行省略 */
	overflow: hidden;
	color: #555555;
	font-weight: 600;
	font-size: 14px;
}
</style>
