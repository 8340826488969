<template>
	<div class="date-page">
		<div class="date-header">
			<div class="year">
				<span class="btn op sc hand"  @click="preMonthClick()"><</span>
				<span >{{year}} 年 {{month}} 月</span>
				<span  class="btn op sc hand" @click="nextMonthClick()">></span>
			</div>
		</div>
		<div class="date-content">


			<div class="weekList">
				<div class="week" v-for="item in weekList" :key="item">周{{ item }}</div>
			</div>
			<div class="dataList">

				<div class="date"
					 v-for="(item, index) in dayList"
					:class="{
						 'today':today.year == item.year && today.month == item.month && today.day == item.day,
						 'activeDay':activeDay.year == item.year && activeDay.month == item.month && activeDay.day == item.day
					 }"
					:key="index"
					@click="detailClick(item,index,$event)">


					<div class="name">{{ item.day }}</div>
					<div class="contentList">
						<div class="content" v-for="content in item.contentList">
							<img :src="content.headIco">
							<div class="nickName">{{content.nickName}}</div>
							<div class="text">{{content.content}}</div>
						</div>
					</div>

				</div>
			</div>
		</div>

		<div id="addForm" :style="{left:formLeft+'px',top:formTop+'px'}" v-show="formShow == 'add' || formShow == 'edit'">
			<div class="title">{{activeDay.year}}年{{activeDay.month}}月{{activeDay.day}}日 - {{ formShow == 'add' ? '创建日程' : '编辑日程' }} <span class="op sc hand" @click="formShow = 'hide'">x</span>  </div>
			<el-input type="textarea" class="input"
				placeholder="请输入内容"
				v-model="addContent">
			</el-input>
			<div class="bottom">
				<el-button @click="save">保存</el-button>
				<el-button  @click="del" v-show="  formShow == 'edit'">删除</el-button>
			</div>
		</div>

		<div id="contentListView" :style="{left:formLeft+'px',top:formTop+'px'}" v-show="formShow == 'list'">
			<div class="title">{{activeDay.year}}年{{activeDay.month}}月{{activeDay.day}}日 <span class="op sc hand" @click="formShow = 'hide'">x</span>  </div>
			<div class="contentList">
				<div class="content" v-for="(content,index) in activeDay.contentList" @click="edit(content,index)">
					<img :src="content.headIco">
					<div class="nickName">{{content.nickName}}</div>
					<div class="text">{{content.content}}</div>
				</div>
			</div>
			<div class="bottom">
				<el-button @click="formShow='add'">添加新事件</el-button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'Calendar',
	data() {
		return {
			year: "",
			month: "",
			dayList: [],
			today: {},
			activeDay:{},
			show: false,
			weekList: ['日','一', '二', '三', '四', '五', '六'],
			formLeft:0,
			formTop:0,
			formShow:'hide',
			addContent:"",
			editIndex:0,
			calendarEventList:[]
		}
	},
	computed: {
		me() {
			return this.$store.state.user
		}
	},
	props:{
		roomId:{//不传默认为0表示打开个人日历
			type:Number,
			default:0
		}
	},
	watch:{
		roomId(){
			this.getToday();
      this.getAllList()
		}
	},
	mounted() {
    this.getToday();
		this.getAllList()
	},
	methods: {
		// 获取所有列表
		getAllList() {
		  let params = {};
		  params.type = 1;
		  params.date = this.year+'-'+this.month+'-'+ '01'+ ' 00:00:00';
		  if (this.roomId) {
			params.roomId = this.roomId;
			params.type = 2;
		  }
		  this.newApi.getCalendarEventMoreList(params).then(res => {
			this.calendarEventList = res.data;
			this.getMonthDays(this.year, this.month - 1);
		  })
		},

		// 获取当前日期
		edit(content,index){
			if (content.uuid != this.me.uuid){
				this.utils.err("不可编辑别人写的");return;
			}
			this.formShow='edit';
			this.editIndex=index;
			this.addContent=content.content;
		},
		getRoomCalendar(){
			const that = this;
			this.newApi.getCalendarEventMoreList({
				roomId:this.roomId,

			}).then(res =>{
				that.calendarEventList = res.data;
				that.getToday();
			})
		},
		getToday() {
			let date = new Date();
			this.year = date.getFullYear();
			this.month = date.getMonth() + 1;
			this.day = date.getDate();//获取这是周几
			// console.log(this.year,this.month,this.day)
			//初始化数据
			this.getMonthDays(this.year, this.month - 1);
			//得到当月今天的位置index
			this.today = {
				day : this.day,
				year: this.year,
				month: this.month
			}
			this.activeDay = {
				day : this.day,
				year: this.year,
				month: this.month
			}
		},
		// 上一月
		preMonthClick() {
			this.month--;
			if (this.month <= 0) {
				this.month = 12;
				this.year--;
			}
			this.getMonthDays(this.year, this.month - 1);
		},
		// 下一月
		nextMonthClick() {
			this.month++;
			if (this.month >= 13){
				this.month = 1;
				this.year++;
			}
			this.getMonthDays(this.year, this.month - 1);
		},
		// 获取日期数组
		getMonthDays(year, month) {
			// 定义每个月的天数，如果是闰年第二月改为29天
			let daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
			if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
				daysInMonth[1] = 29;
			}
			// 当月第一天为周几
			let targetDay = new Date(year, month, 1).getDay();
			let calendarDateList = [];
			let preNum = targetDay;
			let nextNum = 0;
			if (targetDay > 0) {
				// 当前月份1号前的自然周剩余日期，置空
				for (let i = 0; i < preNum; i++) {
					calendarDateList.push({day: "", year: this.year, month: this.month});
				}
			}
			for (let i = 0; i < daysInMonth[month]; i++) {
				// 正常显示的日期
				const contentList = [];
				for (let j = 0; j < this.calendarEventList.length; j++) {
					const y = parseInt(this.calendarEventList[j].date.substring(0, 4));
					const m = parseInt(this.calendarEventList[j].date.substring(5, 7));
					const d = parseInt(this.calendarEventList[j].date.substring(8, 10));
					// console.log(this.calendarEventList[j].date,y,m,d)
					if (y === this.year && m === this.month && d === i + 1){
						contentList.push(this.calendarEventList[j]);
					}
				}
				calendarDateList.push({
					day: i + 1,
					year: this.year,
					month: this.month,
					contentList:contentList
				})
			}
			// 当前月份最后一天的自然周剩余日期，置空
			nextNum = 6 - new Date(year, month + 1, 0).getDay();
			for (let i = 0; i < nextNum; i++) {
				calendarDateList.push({day: "", year: this.year, month: this.month});
			}
			this.dayList = calendarDateList;
		},
		// 当前点击日期
		detailClick (day,index, e) {
			const dateString = day.year + "-" + day.month + "-" + day.day + " 00:00:00";
			const timestamp = Math.round(new Date(dateString).getTime()/1000);
			console.log(timestamp);

			const now = new Date(); // 获取当前时间
			const year = now.getFullYear(); // 获取当前年份
			const month = now.getMonth(); // 获取当前月份
			const days = now.getDate(); // 获取当前日期
			const zeroTime = new Date(year, month, days); // 当天零点的时间对象
			const zeroTimeTimestamp = Math.round(zeroTime.getTime()/1000); // 当天零点的时间戳
			console.log(zeroTimeTimestamp)
			if(timestamp < zeroTimeTimestamp){
				this.utils.err("不能小于当天时间");
				return false;
			}

			this.addContent = "";
			if (this.dayList[index].day == ""){
				this.formShow = "hide";
			}else {
				if (this.dayList[index].contentList && this.dayList[index].contentList.length>0){
					this.formShow = "list";
				}else{
					this.formShow = "add";
				}
			}

			this.formLeft = e.x;
			this.formTop = e.y;
			this.activeDay = day

			var width = 500;
			var height = 300;
			if (e.x + width > window.innerWidth){
				//右侧越过边界
				this.formLeft = window.innerWidth-width;
			}
			if (e.y + height > window.innerHeight){
				//右侧越过边界
				this.formTop = window.innerHeight-height;
			}
		},
		del(){
			this.addContent = "";
			this.formShow = "hide";
			this.newApi.deleteCalendarEvent({
				id:this.activeDay.contentList[this.editIndex].id
			})
			this.activeDay.contentList.splice(this.editIndex,1);;

		},
		save(){
			if (this.addContent == ""){
				return;
			}
			if(this.formShow == "add"){
				const params = {
          uuid: this.me.uuid,
          typeTwo: 1,
					roomId: this.roomId,
					type:this.roomId === 0 ? 1 : 2,  // 1个人日历 2Room日历
					content:this.addContent,
					date:this.activeDay.year+"-"+(this.activeDay.month < 10 ? '0'+this.activeDay.month:this.activeDay.month)+"-"+(this.activeDay.day < 10 ? '0'+this.activeDay.day:this.activeDay.day)+" 00:00:00"
				};
				this.activeDay.contentList.push({
					//先把写好的数据放进去，看起来流畅
					headIco: this.me.headIco,
          nickName: this.me.nickName,
					content:this.addContent
				})

				const that = this;
				this.newApi.addCalendarEvent(params).then(res =>{
					that.calendarEventList.push(res.data)
					that.activeDay.contentList[that.activeDay.contentList.length-1].id = res.data.id; //更新数据
				})



			}else{
				this.activeDay.contentList[this.editIndex].content = this.addContent;
				this.newApi.saveCalendarEvent({
					id:this.activeDay.contentList[this.editIndex].id,
					content: this.activeDay.contentList[this.editIndex].content
				})
			}

			this.addContent = "";
			this.formShow = "hide";
		}
	}
}
</script>
<style lang="scss" scoped>

.date-page{
	width: 100%; user-select:none; position: relative;
	.year {
		font-weight: 700;
		font-size: 18px;  line-height: 30px;height: 30px;overflow: auto;
		span{
			display: block; float: left;
		}
		.btn{
			width: 30px; margin: 0 10px; font-weight: 600;
			text-align: center;border-radius: 10px;
		}
	}

	.weekList {
		display: flex;
		align-items: center;
		gap: 10px;
		border-bottom: 1px solid #f3f3f3; margin-bottom: 10px;
		.week {
			width: 14.285%;
			background-color: #FFFFFF;
			padding: 10px 0;
			text-align: center;
		}
	}
	.dataList {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		.today{
			color: #0080ff;
			font-weight: bold;
		}
		.date {
			height: 110px;
			width: calc( 100% / 7 - 8.8px ) ;
			text-align: center;
			background: #f3f3f3;
			.name{
				font-size: 20px; line-height:40px;
			}
			.contentList{
				max-height: 70px;
				font-size: 10px;
				overflow: hidden;
				.content{
					text-align: left;
					overflow: auto;
					margin-bottom:5px;

					img{
						width: 20px; height: 20px; border-radius: 50%;
						float: left;
					}
					.nickName{
						float: left;height: 20px;line-height: 20px;
						color: #1C7EFF; margin: 0 5px;
					}
					.text{
						line-height: 20px;
					}
				}
			}
		}
		.activeDay {
			background-color: rgb(169, 225, 243);
		}
	}


	#addForm,#contentListView{
		width: 500px;height: 300px; background: #FFF;position: fixed; box-shadow: 1px 1px 3px 1px #6d737b;
		.title{
			padding:0 15px; color: #000; background: #b0d4ff; height:40px;line-height: 40px;
			position: absolute;top: 0;left: 0;width: calc( 100% - 30px ); display: flex;
			justify-content: space-between;
			span{
				float: right; color: #2f2f2f; font-size: 15px;

			}
		}

		.input{
			padding: 0 !important;
			margin-top: 40px;
			textarea{
				height: 220px; border-radius: 0;
			}
		}

		.contentList{
			margin: 40px 0; overflow-y: scroll; height: 220px;
			.content{
				padding: 5px;cursor:pointer;
				img{
					width: 20px; height: 20px; border-radius: 50%;
					float: left;
				}
				.nickName{
					float: left;height: 20px;line-height: 20px;
					color: #1C7EFF; margin: 0 5px;
				}
				.text{
					line-height: 20px;
				}

			}
			.content:hover{
				background: #f3f3f3;
			}
		}

		.bottom{
			padding:0 15px; color: #000; background: #b0d4ff; height:40px;line-height: 40px;
			position: absolute;bottom: 0;left: 0;width: calc( 100% - 30px );
		}
	}
}

</style>
