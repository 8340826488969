<template>
  <div style="overflow:hidden;min-width: 1500px;">
    <div style="max-width: 1500px;margin:108px auto;overflow: hidden;" class="task">
      <div class="page_left" style="width:284px; height: calc(100vh - 108px); float: left;position: fixed;">
        <TaskNav></TaskNav>
      </div>
      <div style="width:1206px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);height: auto;float: left;margin-left: 294px;min-height: 1000px;background: #FFFFFF;">
        <div>
          <div style="overflow:hidden;" v-if="showTaskInfo === true">
            <TaskInfo :taskId="taskId"></TaskInfo>
          </div>
          <div v-else>
            <div style="overflow:hidden;padding: 6px; display: flex; flex-wrap: wrap">
              <div class="task-card">
                <div class="hand op sc" style="overflow:hidden; margin: auto 0" @click="showAddTask">
                  <div style="text-align:center; margin-bottom: 40px">
                    <i class="el-icon-plus"
                       style="font-size: 40px; font-weight: 600; color: #2970FF;text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);"> </i>
                  </div>
                  <div
                      style="width: 120px;height: 24px;font-size: 20px; font-weight: 600; color: #2970FF;line-height: 0;">
                    创建新的序列
                  </div>
                </div>
              </div>

              <div
                  style="width:calc((100% - 56px)/4); margin: 0 10px 10px 0; height: 356px; border: 2px solid rgb(228, 231, 237)"
                  v-for="item in taskList" :key="item.id">
                <div style="overflow:hidden">
                  <RoomTask @reTaskList="getRoomTaskList(roomInfo)" :data="item" :roomName="roomInfo.name"></RoomTask>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showTaskInfo === false" style="width:1206px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-top: 10px; height: auto;float: left;margin-left: 294px;min-height: 1000px;background: #FFFFFF;" >
        <el-table :data="roomTaskList" border stripe style="width: 96%; margin: 20px " :header-cell-style="{background:'#E8E8E8',color:'#000000'}">
          <el-table-column  prop="nickName" label="承接方" width="140">
            <template slot-scope="scope">
              <span style="font-weight: bold;">{{scope.row.shopName}}</span>
            </template>
          </el-table-column>
          <el-table-column  prop="title" label="任务类型" width="240">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 2">Room服务订单</span>
              <span v-if="scope.row.type === 3">Room采购订单</span>
            </template>
          </el-table-column>
          <el-table-column  prop="detail" label="任务详情" width="440">
            <template slot-scope="scope">
              <div style="max-height: 90px;text-overflow:ellipsis;-webkit-line-clamp:4;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;" v-for="(goods, key) in scope.row.roomOrderList" :key="goods.id">
                {{key + 1}}.{{goods.goodsName}}（{{goods.orderNo}}）
              </div>
            </template>
          </el-table-column>
          <el-table-column  prop="createTime" label="创建日期" width="200"></el-table-column>
          <el-table-column fixed="right" label="操作" width="110">
            <template slot-scope="scope">
              <div class="hand op" @click="intoTask(scope.row)"
                   style="font-size: 18px; font-weight: 600; margin-right: 10px">
                <span style="font-size: 14px; color: #031F88"> 添加到序列</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!--创建弹窗-->
    <el-dialog :visible.sync="showTask" width="52%" :close-on-click-modal="false" :show-close="false" class="event-form">
      <div class="top">
                            <span class="contentType">
                                <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                                <span>{{ roomInfo.name }}</span>
                            </span>创建任务序列
        <img src="../expert/imgs/cancel.png"
             style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;"
             @click="closeEvent">
      </div>
      <div style="padding: 20px 40px">
        <div style="margin-bottom: 40px">
          <div class="title-form">序列类型</div>
          <el-radio v-model="taskForm.type" :label="1">任务序列</el-radio>
          <el-radio v-model="taskForm.type" :label="2">服务序列</el-radio>
          <el-radio v-model="taskForm.type" :label="3">采购序列</el-radio>
        </div>

        <div style="margin-bottom: 40px">
          <div class="title-form">序列名称</div>
          <el-input
              type="text"
              v-model="taskForm.title"
              :show-word-limit="true"
              maxlength="30"
              minlength="2"
              placeholder="请输入序列名称">
          </el-input>
        </div>
        <div style="margin-bottom: 40px">
          <div class="title-form">序列说明</div>
          <el-input
              type="textarea"
              v-model="taskForm.content"
              :show-word-limit="true"
              maxlength="500"
              :rows="5"
              placeholder="请输入序列说明">
          </el-input>
        </div>
        <div style="display: flex; justify-content: center">
          <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;" @click="addTasksList">
            立即创建
          </div>
        </div>
      </div>
    </el-dialog>

    <!--创建弹窗-->
    <el-dialog :visible.sync="showSelect" width="1020px" :close-on-click-modal="false" :show-close="false" class="event-form">
      <div class="top">
                            <span class="contentType">
                                <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                                <span>{{ roomInfo.name }}</span>
                            </span>添加订单到序列
        <img src="../expert/imgs/cancel.png"
             style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;"
             @click="showSelect = false">
      </div>
      <div style="padding: 20px 40px">
        <div style="display: flex; flex-wrap: wrap">
          <div v-for="(item,index) in selectTaskList" :key="index">
            <div @click="setSelect(index, item)" class="hand op"
                 :style="index === zIndex?'background: #F0F0F0':'background: #FFFFFF'"
                 style=" width: 300px;height: 100px;border-radius: 5px;border: 1px solid #CED0D4; margin: 0 10px 10px 0;">
              <div style="text-align: center; padding: 0 10px">
                <div
                    style="font-size: 16px; margin-top: 10px; font-weight: 600; text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;">
                  {{ item.title }}
                </div>
                <div
                    style="font-size: 14px; margin-top: 4px;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;" @click="addOrderToTask">
            确认添加
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Head from "../../components/common/Head";
import RoomTask from './components/RoomTask';
import TaskInfo from "./components/TaskInfo";
import TaskNav from "./components/taskNav";

export default {
  name: 'Task',
  components: {
    TaskNav,
    TaskInfo,
    Head,
    RoomTask,
  },
  computed: {
    is_login() {
      return this.$store.state.is_login
    }
  },
  data() {
    return {
      typeName: '',
      showTask: false,
      taskForm: {
        title: '',
        content: '',
        type: 1
      },
      roomInfo: {},
      taskType: '',
      taskId: '',

      taskList: [],
      showTaskInfo: false,

      roomTaskList: [],

      showSelect: false,
      selectTaskList: [],
      selectTask: {},
      taskInfo: {},
      zIndex: '',
      // 订单状态
      orderStatusList: [
        {name: '待缴保证金', color: '#F44336', status: 2},
        {name: '待付款', color: '#F44336', status: 3},
        {name: '进行中', color: '#FF9800', status: 4},
        {name: '待验收', color: '#FF9800', status: 5},
        {name: '已完成', color: '#8BC34A', status: 9},
        {name: '已撤单', color: '#999999', status: 10},
        {name: '已退款', color: '#999999', status: 11},
        {name: '退款中', color: '#999999', status: 7},
      ],
    }
  },
  props: {},
  watch: {},
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    //选择哪种套餐
    setSelect(index, item) {
      var that = this;
      that.zIndex = index;
      that.selectTask = item;
    },

    // 转移至任务序列
    intoTask(item) {
      let params = {};
      this.taskInfo = item;
      params.type = item.type;
      params.roomId = this.roomInfo.id;
      params.orderNo = item.orderNo;
      this.newApi.getGoodsAndserviceToTaskList(params).then(res => {
        if (res.isSuccess === 1) {
          this.selectTaskList = res.data;
          this.showSelect = true;
        }
      })
    },

    // 确认转移
    addOrderToTask() {
      if (JSON.stringify(this.selectTask) === '{}') {
        this.utils.err('请选择一个任务序列');
        return;
      }
      let params = {};
      params.type = this.taskInfo.type;
      params.roomId = this.roomInfo.id;
      params.orderNo = this.taskInfo.orderNo;
      params.questId = this.selectTask.id;
      this.newApi.goodsAndserviceToPurchaseOrder(params).then(res => {
        if (res.isSuccess === 1) {
          this.utils.sus(res.data);
          this.showSelect = false;
          this.getRoomTaskList(this.roomInfo)
        }
      })
    },

    // 获取Room任务序列
    getRoomTaskList(roomInfo) {
      this.showTaskInfo = false;
      this.roomInfo = roomInfo;
      this.roomTaskList = [];
      this.selectTask = {};
      this.taskInfo = {};
      this.zIndex = '';
      this.newApi.getTaskSequenceList({roomId: roomInfo.id}).then(res => {
        if (res.isSuccess === 1) {
          this.taskList = res.data;
        }
      })
      this.newApi.getRoomGoodsOrderList({roomId: roomInfo.id}).then(res => {
        if (res.isSuccess === 1) {
          this.roomTaskList = res.data;
        }
      })
    },

    // 进入任务详情
    toTaskInfo(id) {
      this.taskId = id;
      this.showTaskInfo = true;
    },


    // 获取room的任务序列
    getRoomTask(item) {
      this.roomInfo = item;
    },

    // 返回首页
    toBack() {
      this.taskId = '';
      this.getRoomTaskList(this.roomInfo)
    },

    // 切换菜单标签
    // clickNavigation(item) {
    // 	this.taskId = '';
    // 	this.activeName = item
    // },

    // 展示创建任务弹窗
    showAddTask() {
      this.showTask = true;
      this.taskForm = {
        title: '',
        content: ''
      };
    },

    // 关闭事件弹窗
    closeEvent() {
      this.showTask = false
    },

    // 创建序列
    addTasksList() {
      var params = {};
      params.roomId = this.roomInfo.id;
      params.title = this.taskForm.title;
      params.content = this.taskForm.content;
      params.type = this.taskForm.type;
      if (!params.title) {
        this.utils.err('请输入任务序列名称！');
        return
      }
      if (!params.content) {
        this.utils.err('请输入任务序列内容！');
        return
      }
      this.newApi.addTaskSequence(params).then(res => {
        if (res.isSuccess === 1) {
          this.utils.sus(res.data);
          this.showTask = false;
          this.getRoomTaskList(this.roomInfo)
        }
      })
    },

    back() {
      window.history.go(-1);
    },
  }
}

</script>

<style>
.task .tab {
  text-align: center;
  margin: auto 0;
  float: left;
  cursor: pointer;
}

.task .tab-search {
  width: 30%;
  float: right;
  margin: 10px 2%;
  cursor: pointer;
}

.task .up {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.1s;
}

.task .head_white {
  height: 26px;
  padding: 14px 0;
  width: 160px
}

.task .head_white_word {
  width: 120px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  margin-left: 43px;
  float: left;
}

.task .head_white_line {
  width: 80px;
  margin-left: 60px;
  height: 6px;
  background-color: #FF9800;
  border-radius: 5px;
  position: relative;
  margin-top: 8px;
  float: left;
}

.task .task-card {
  width: calc((1194px - 56px) / 4);
  margin: 0 10px 10px 0;
  background: #FBFBFB;
  height: 356px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(228, 231, 237);
}

.event-form .top {
  text-align: center;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #444444;
  border: 1px solid #CECFD0;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
}

.event-form .title-form {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.event-form .contentType {
  font-size: 18px;
  color: #031F88;
  position: absolute;
  left: 20px;
  font-weight: 600;
  margin-right: 10px;
}

.event-form .foot-btn {
  width: 128px;
  height: 20px;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.event-form .el-dialog__header {
  padding: 0;
}

.event-form .el-dialog__body {
  padding: 0;
}
</style>
