<template>
    <div class="task-nav">
        <div class="shadow" style="width:100%;overflow:hidden;height:auto;background-color: #FFFFFF;" v-if="is_login == 1" >
            <div @click="backTask" class="hand op" style="padding: 0 5%; margin-top: 10px">
                <i class="el-icon-arrow-left" style="margin-right: 4px; font-size: 16px; vertical-align: middle"></i>
                <span style="vertical-align: middle">返回</span>
            </div>
            <div  style="overflow:hidden;margin-top: 12px;padding: 0 14px;">
              <el-autocomplete
                  style="display:inline"
                  value-key="name"
                  v-model="searchContent"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入room名称"
                  @select="SelectRoom"
                  :highlight-first-item="true"
                  :trigger-on-focus="false"
                  :clearable = "true"
              ></el-autocomplete>
            </div>

            <div style="width:calc( 100% + 12px);height:calc(100vh - 200px); overflow-y: scroll;overflow-x: hidden; margin-top: 10px;">
                <div style="width: 100%;">
                    <div class="hand op" v-for="(item,index) in my_room_list" v-bind:key="index" @click="click_room(item)">
                        <div :class="item.id === roomInfo.id ? 'click-change' : 'no-change'">
                            <div style="width: 30px;float:left;height: 30px;line-height: 30px;margin-top: 5px;margin-right:  10px;">
                                <img :src="item.cover+'-80x80'" style="width: 100%;height: 100%;border-radius: 20px;"/>
                            </div>
                            <el-tooltip effect="light" :content=" item.name "  placement="right">
                                <div class="room_name" style="width: calc(100% - 40px);float:left;height: 40px;overflow: hidden;font: 14px Microsoft YaHei;line-height: 40px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" >{{item.name}}</div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "taskNav",
        data() {
            return {
                roomInfo: {},
                roomType: 0,
                searchContent: '',
                my_room_list: [],
            }
        },

        computed: {
            is_login(){
                return this.$store.state.is_login
            }
        },

        watch: {
            roomInfo: {
                deep: true,
                handler() {
                }
            }
        },

        mounted() {
            this.get_my_room_list()
        },

        methods: {
          //搜索room
          querySearchAsync(searchContent, cb) {
            this.newApi.getMyDutyRoomList({name:searchContent,roomType:0}).then(res=>{
              cb(res.data);
            })

          },
          SelectRoom(item){
            this.roomInfo = item
            this.$parent.getRoomTaskList(item)
          },

            // 返回
            backTask() {
                this.$router.push('/room/index')
            },

            // 获取room列表
            get_my_room_list:function(){
                var that = this;
                that.newApi.getMyDutyRoomList({type: 0, roomType: 0}).then(ret =>{
                    that.my_room_list = ret.data;
                    that.roomInfo = ret.data[0]
                    that.$parent.getRoomTaskList(that.roomInfo)
                })
            },

            // 切换标签选择
            screenRoomList(roomType) {
                this.roomType = roomType;
                this.my_room_list = [];
                this.get_my_room_list()
            },

            // 选择room
            click_room:function (item){
                this.roomInfo = item
                this.$parent.getRoomTaskList(item)
            },

        }
    }
</script>

<style scoped>
    .task-nav {
        width: 100%;
        height: 100%;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }

    .click-change {
        width: calc(100% - 32px);
        height: 40px;
        margin:0 12px;
        padding: 0 0 0 8px;
        background-color: #E8E8E8;
    }

    .no-change {
        width: calc(100% - 32px);
        height: 40px;
        margin:0 12px;
        padding: 0 0 0 8px;
    }
</style>
