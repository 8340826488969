var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-list"},[(_vm.enrollList.length > 0)?_c('ActiveCard',{attrs:{"data":_vm.enrollList[0],"roomId":parseInt(_vm.roomId),"type":"home"}}):_vm._e(),(_vm.videoList.length > 0)?_c('div',{staticClass:"event-body"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.clockList.length > 0)?_c('div',{staticClass:"event-body"},[_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.docList.length>0)?_c('DocCard',{attrs:{"data":_vm.docList[0]}}):_vm._e(),(_vm.dailyList.length > 0)?_c('div',{staticClass:"event-body"},[_vm._m(4),_c('div',{staticClass:"event-info"},[_c('div',{staticStyle:{"margin-bottom":"10px","color":"#F3425F"}},[_vm._v(_vm._s(_vm.dailyList[0].date))]),_c('div',[_vm._v(" "+_vm._s(_vm.dailyList[0].content)+" ")])])]):_vm._e(),(_vm.manyList.length > 0)?_c('div',{staticClass:"event-body"},[_vm._m(5),_c('div',{staticClass:"event-info"},[_c('div',{staticStyle:{"margin-bottom":"10px","color":"#F3425F"}},[_vm._v("2022-02-21 AM10：30")]),_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" 备注：xxxxxxxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxxxxxx ")]),_c('div',[_c('div',{staticStyle:{"color":"#999999","margin-bottom":"10px"}},[_vm._v("好友：")]),_c('div',{staticStyle:{"display":"flex","flex":"content"}},_vm._l((6),function(item){return _c('img',{key:item,staticStyle:{"width":"38px","height":"38px","border-radius":"50%","margin-right":"8px"},attrs:{"src":""}})}),0)])])]):_vm._e(),(_vm.payList.length > 0)?_c('div',{staticClass:"event-body"},[_vm._m(6),_vm._m(7)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-title video"},[_c('span',{staticStyle:{"margin-left":"30px"}},[_vm._v("视频直播")]),_c('span',{staticStyle:{"color":"#FFFFFF","float":"right","font-size":"14px","margin-right":"10px"}},[_vm._v("更多"),_c('i',{staticClass:"el-icon-arrow-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-content"},[_c('div',[_c('div',{staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v("线上报名参加活动")]),_c('div',{staticStyle:{"color":"#2970FF"}},[_vm._v("2022/6/18 PM18:30")]),_c('div',[_c('span',[_vm._v("活动地点： ")]),_vm._v(" "),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("线上")])]),_c('div',[_c('span',[_vm._v("热度： ")]),_vm._v(" "),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("889人将参加")])])]),_c('div',[_c('img',{staticClass:"image-ico",attrs:{"src":""}}),_c('div',{staticClass:"hand op image-btn"},[_vm._v("点击报名")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-title punch"},[_c('span',{staticStyle:{"margin-left":"30px"}},[_vm._v("打卡事件")]),_c('span',{staticStyle:{"color":"#FFFFFF","float":"right","font-size":"14px","margin-right":"10px"}},[_vm._v("更多"),_c('i',{staticClass:"el-icon-arrow-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-content"},[_c('div',[_c('div',{staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v("线上报名参加活动")]),_c('div',{staticStyle:{"color":"#2970FF"}},[_vm._v("2022/6/18 PM18:30")]),_c('div',[_c('span',[_vm._v("打卡地点： ")]),_vm._v(" "),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("线上")])])]),_c('div',[_c('img',{staticClass:"image-ico",attrs:{"src":""}}),_c('div',{staticClass:"hand op image-btn"},[_vm._v("点击报名")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-title",staticStyle:{"background-color":"#FF9800"}},[_c('i',{staticClass:"el-icon-message-solid",staticStyle:{"margin-right":"6px","line-height":"40px"}}),_vm._v(" 日程提醒 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-title",staticStyle:{"background-color":"#FD764C"}},[_c('i',{staticClass:"el-icon-message-solid",staticStyle:{"margin-right":"6px","line-height":"40px"}}),_vm._v(" 多人预约日程 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-title pay"},[_c('span',{staticStyle:{"margin-left":"30px"}},[_vm._v("支付事件")]),_c('span',{staticStyle:{"color":"#FFFFFF","float":"right","font-size":"14px","margin-right":"10px"}},[_vm._v("更多"),_c('i',{staticClass:"el-icon-arrow-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-content"},[_c('div',[_c('div',{staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v("线上审批支付")]),_c('div',{staticStyle:{"color":"#2970FF"}},[_vm._v("2022/6/18 PM18:30")]),_c('div',[_c('span',[_vm._v("支付金额： ")]),_vm._v(" "),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("999元")])])]),_c('div',[_c('div',{staticClass:"hand op image-btn"},[_vm._v("点击查看")])])])
}]

export { render, staticRenderFns }