<template>
    <div class="info-order">
        <div v-if="type === 'expert'" class="expert-info">
            <div class="expert-box" v-for="(item, index) in expertList" :key="index">
                <div style="display: flex; margin-bottom: 10px">
                    <img :src="item.headIco" class="head-img">
                    <div style="margin-right: 10px; margin-top: 4px">
                        <div style="font-size: 18px; margin-bottom: 4px;width: 100px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis; font-weight: 600">{{item.nickName}}</div>
                        <div style="margin-right: 10px">
                            <img src="../../../assets/imgs/other/adress.png" style="margin-right: 2px; vertical-align: middle">
                            <span style="color: #666666; vertical-align: middle; font-size: 14px" v-if="item.address.cityName">{{item.address.cityName[1]}}</span>
                        </div>
                    </div>
                    <div style="margin-top: 16px">
                        <span class="head-btn hand op" @click="utils.b('/expert/space/' + item.uuid)">咨询</span>
                    </div>
                </div>

                <div style="display: flex; margin-bottom: 14px">
                    <div class="title-ico"></div>
                    <div style="font-size: 16px; font-weight: 600; color: #666666; line-height: 18px">专家领域咨询话题</div>
                </div>

                <div class="topic-box" v-if="item.topics">
                    <div class="topic-font" v-for="(sub, key) in item.topics.slice(0,3)" :key="key">#{{sub.title}}</div>
                </div>
            </div>
        </div>

        <div v-if="type === 'merchants'" class="expert-info">
            <div class="shop-box" v-for="(item, index) in serviceList" :key="index">
                <ServiceCard :roomId="Number($route.params.id)" :data="item" :name="index"></ServiceCard>
            </div>
        </div>

        <div v-if="type === 'product'" class="expert-info">
            <div class="shop-box" v-for="(item, index) in productList" :key="index">
                <CaseCard :data="item" :roomId="Number($route.params.id)" :beFrom="'1'"></CaseCard>
            </div>
        </div>
    </div>
</template>

<script>
    import ServiceCard from "./ServiceCard";
    import CaseCard from "./CaseCard";
    export default {
        name: "InfoOrder",
        components: {CaseCard, ServiceCard},
        props: {
            type: {
                type: String,
                default() {
                    return ""
                }
            }
        },
        data() {
            return {
                page: 1,
                pageSize: 20,
                more: 1,
                expertList: [],
                serviceList: [],
                productList: [],
            }
        },

        watch: {
            type: {
                handler(newType) {
                    this.page = 1;
                    this.pageSize = 20;
                    this.more = 1;
                    this.expertList = [];
                    this.serviceList = [];
                    this.productList = []
                    if (newType === 'expert') {
                        this.getExpertList()
                    }
                    if (newType === 'merchants') {
                        this.getServiceList()
                    }
                    if (newType === 'product') {
                        this.getProductList()
                    }
                },
            }
        },

        updated() {
            var that = this;
            window.onscroll = function(){
                that.topLong = window.scrollTop;
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                if(scrollHeight - scrollTop - windowHeight <= 0){
                    if (that.more == 1) {
                        if (that.type === 'expert') {
                            that.getExpertList()
                        }
                        if (that.type === 'merchants') {
                            that.getServiceList()
                        }
                        if (that.type === 'product') {
                            that.getProductList()
                        }
                    }else{
                        console.log("已加载完");
                    }
                }
            }
        },

        mounted() {
            let that = this;
            if (that.type === 'expert') {
                that.getExpertList()
            }
            if (that.type === 'merchants') {
                that.getServiceList()
            }
            if (that.type === 'product') {
                that.getProductList()
            }
        },

        methods: {
            // 获取服务列表
            getServiceList() {
                this.newApi.getRoomShopServiceList({roomId: this.$route.params.id, page: this.page, pageSize: this.pageSize}).then(res => {
                    if (res.isSuccess === 1) {
                        for (const item of res.data) {
                            this.serviceList.push(item)
                        }
                        if (res.data.length < this.pageSize) {
                            this.more = 0;
                        } else {
                            this.page ++;
                        }
                    }
                })
            },

            // 获取产品列表
            getProductList() {
                this.newApi.getRoomShopGoodsList({roomId: this.$route.params.id, page: this.page, pageSize: this.pageSize}).then(res => {
                    for (const item of res.data) {
                        this.productList.push(item)
                    }
                    if (res.data.length < this.pageSize) {
                        this.more = 0;
                    } else {
                        this.page ++;
                    }
                })
            },

            // 获取room中的专家列表
            getExpertList() {
                this.newApi.getRoomExpretList({roomId: this.$route.params.id, page: this.page, pageSize: this.pageSize}).then(res => {
                    for (const item of res.data) {
                        this.expertList.push(item)
                    }
                    if (res.data.length < this.pageSize) {
                        this.more = 0;
                    } else {
                        this.page ++;
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .info-order {
        width: 100%;
        background-color: #FFFFFF;
        padding: 20px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .expert-info {
        display: flex;
        flex-wrap: wrap;
    }

    .expert-box {
        width: 26%;
        height: 234px;
        margin-right: 16px;
        margin-bottom: 16px;
        padding: 16px 20px;
        border-radius: 5px;
        background-color: #F6F6F6;
    }

    .head-img {
        width: 60px;
        height: 60px;
        background: #FFFFFF;
        border-radius: 50%;
        margin-right: 8px;
    }

    .head-btn {
        width: 40px;
        height: 20px;
        padding: 2px 10px;
        color: #FFFFFF;
        background: #FF9800;
        border-radius: 5px;
    }

    .title-ico {
        width: 6px;
        height: 18px;
        line-height: 18px;
        background-color: #FF9800;
        margin-right: 8px;
    }

    .topic-box {
        width: 100%;
        max-height: 80px;
        font-size: 14px;
    }

    .topic-font {
        line-height: 20px;
        margin-bottom: 8px;
        text-overflow:ellipsis;
        -webkit-line-clamp:2;
        -webkit-box-orient:vertical;
        overflow:hidden;
        display:-webkit-box;
    }

    .shop-box {
        width: 31%;
        margin-right: 10px;
    }

    .look-btn {
        width: 82px;
        height: 22px;
        border: 1px #FF9800 solid;
        color: #FF9800;
        padding: 6px 12px;
        border-radius: 5px;
    }

    .expert-btn {
        width: 82px;
        height: 22px;
        background-color: #FF9800;
        color: #FFFFFF;
        padding: 6px 12px;
        border-radius: 5px;
    }

    .old-ico {
        width: 46px;
        height: 10px;
        background-color: #FF9800;
        color: #FFFFFF;
        margin-right: 8px;
        padding: 0 2px;
        border-radius: 5px;
    }

    .up-ico {
        width: 14px;
        height: 14px;
        background-color: #0B9D35;
        color: #FFFFFF;
        margin-right: 8px;
        padding: 0 2px;
        border-radius: 5px;
    }


</style>
