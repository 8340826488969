<template>
    <div>
        <div class="Dynamic" v-if="show">
            <div class="head">
                <div class="user" @click="openInfo(data.user.uuid, data.user.userType)">
                    <img :src="data.user.headIco + '-200x200'" class="userIco" >
                    <div class="major">
                        <div class="userName">
                            <div>{{data.user.nickName}}</div>
                            <img v-show="data.user.isVip == 1" src="../imgs/newVip.png" style="width: 24px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.v == 1" src="../imgs/newV.png" style="width: 20px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.authenticationStatus >= 2" src="../imgs/newP.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.expertAuthStatus === 2" src="../imgs/newS.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                        </div>
                        <div class="userMajor"><span v-if="data.user.address.cityName">{{data.user.address.cityName[1]}}</span><span v-if="data.user.major && data.user.address.cityName"> | </span>{{data.user.major}}</div>
                    </div>
                </div>

                <div class="action" v-if="user.uuid == data.user.uuid">
                    <el-dropdown @command="more_action">
                      <span class="el-dropdown-link">
                        <i class="el-icon-caret-bottom" style="font-size: 24px"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-if="user.uuid == data.user.uuid" command="edit">编辑</el-dropdown-item>
                            <el-dropdown-item v-if="user.uuid == data.user.uuid"  command="delete" >删除</el-dropdown-item>
                          </el-dropdown-menu>
                  </el-dropdown>
                </div>
            </div>

            <!-- 内容 -->
            <div @click="content_info">
                <div class="substance content">
                    <span class="contentType" @click="toRoom" v-show="this.$parent.showRoomName">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                        <span>{{data.roomName}}</span>
                    </span>
                    <span class="t">{{data.content.content}}</span>
                    <span v-if="data.content.link" @click="toLink(data.link)" class="t" style="color: #1C7EFF; margin-right: 4px"><i class="el-icon-paperclip"></i>{{data.content.link}}</span>
                </div>
                <!-- 图片 -->
                <div class="imgs" v-show="data.content.images.length > 0">
                    <!-- 单图 -->
                    <div v-if="data.content.images.length == 1" style="width:100%;height:406px;overflow: hidden;">
                        <img :src="data.content.images[0].url" style="width:auto;height:100%;">
                    </div>

                    <!-- 二至四张图 -->
                    <div v-if="data.content.images.length >= 2 && data.content.images.length <= 4" style="width:100%;height:200px;display: flex;justify-content: space-between;align-items: center;">
                        <div style="width: calc((100% - 15px) / 4);height: 100%" v-for="item in 4" :key="item" >
                            <img :src="data.content.images[item - 1].url+'-200x200'" style="width: 100%;height: 100%;" v-if="data.content.images.length >= item">
                        </div>
                    </div>

                    <!-- 大于四张图 -->
                    <div v-if="data.content.images.length > 4"  style="width:100%;height:406px;">
                        <div class="spacebetween">
                            <div style="width: 406px; height: 406px">
                                <img :src="data.content.images[0].url+'-1024x1024'" style="width: 100%;height: 100%;">
                            </div>
                            <div style="width:calc(100% - 411px);height: 406px; display: flex;flex-direction: column;justify-content: space-between;">
                                <div class="spacebetween" style="height: calc((100% - 5px) / 2)">
                                    <img :src="data.content.images[1].url+'-200x200'" style="width:calc((100% - 5px) / 2);height:100%;">
                                    <img :src="data.content.images[2].url+'-200x200'" style="width:calc((100% - 5px) / 2);height:100%;">
                                </div>
                                <div class="spacebetween"  style="height: calc((100% - 5px) / 2)">
                                    <img :src="data.content.images[3].url+'-200x200'" style="width:calc((100% - 5px) / 2);height:100%;">
                                    <img :src="data.content.images[4].url+'-200x200'" style="width:calc((100% - 5px) / 2);height:100%;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div style="display: flex">
                    <div class="volume" style="margin-right: 20px">{{data.creationTime}}</div>
<!--                    <div class="volume">阅读量:{{data.clickCount}}</div>-->
                </div>
                <div>
                    <DynamicOperation :data="data"></DynamicOperation>
                </div>
            </div>
        </div>
        <!--转发-->
        <el-dialog :visible.sync="showTran" class="event-form">
            <div style="padding: 40px 40px;">
                <Transpond :data="data" :url="'/room/imageInfo/' + data.id"></Transpond>
            </div>
        </el-dialog>

        <!--		编辑知识仓库图片格式-->
        <div v-if="editOpenKw == true">
            <add-image-file-base-form :is-show="editOpenKw" :on-close="closeEdit" :menuId=this.kwImage.menuId  :edit-id="this.kwImage.content.kwId" ></add-image-file-base-form>
        </div>

        <!--		编辑菜单图片类型菜单-->
        <div v-if="editOpenMenu == true">
            <menu-image :is-show="editOpenMenu" :on-close="closeEdit" :menuId=this.kwImage.menuId  :edit-id="this.kwImage.id" ></menu-image>
        </div>

    </div>
</template>

<script>
    import Transpond from "./Transpond";
    import DynamicOperation from "./DynamicOperation";
    import menuImage from "./menuModule/menuImage";
    import addImageFileBaseForm from "../knowledge/components/addImageFileBaseForm";
    export default {
        name:"ImagesContent",
        components: {
            DynamicOperation,
            Transpond,
            menuImage,
            addImageFileBaseForm
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                showTran: false,
                //是否展示
                show:true,
                editOpenKw:false,
                editOpenMenu:false,
                kwImage:this.data
            }
        },
        props:{
            data:{
                type:Object,
                default(){
                    return {
                        videos:[],
                        content:"",
                        user: {},
                    }
                }
            },
        },
        updated() {
        },
        created:function(){

        },
        mounted: function () {
        },
        methods:{
            closeEdit(){
                this.editOpenKw = false;
                this.editOpenMenu = false;
            },
            // 关闭转发
            closeShow() {
                this.showTran = false;
            },

            // 跳转到Room
            toRoom() {
                this.$router.push({ path: '/room/info/' + this.data.roomId})
            },

            // 跳转链接
            toLink(url) {
                if (url.indexOf('http') >= 0) {
                    window.open(url, "_blank")
                } else {
                    window.open('//' + url, "_blank")
                }
            },

            // 点击打开用户信息
            openInfo(uuid, userType) {
              if (!this.utils.toAuth(1)) {
                return false;
              }
              if (userType === 1) {
                this.$router.push({ path: '/circle/mySpace/' + uuid})
              }
              if (userType === 2 || userType === 3) {
                this.$router.push({ path: '/circle/organSpace/' + uuid})
              }
            },

            //跳转至详情页
            content_info(){
                this.utils.b('/room/imageInfo/' + this.data.id)
            },
            //下拉菜单触发的方法
            more_action:function(e){
                if (this.is_login == 0) {
                    this.err("请先登录");
                    return;
                }
                var that = this;
                if (e == "delete") {
                    that.$confirm('删除后无法恢复，确认删除吗？').then(_ => {
                        console.log(_);
                        this.newApi.delRoomContent({id:that.data.id}).then(ret =>{
                            if(ret.isSuccess == 1){
                                that.utils.sus(ret.data);
                                that.show = false;
                                this.$EventBus.$emit("changeData");
                            }
                        })
                    })
                }

                if (e == "edit") {
                    if(this.kwImage.isKwFormat == 0){
                        that.editOpenMenu =true;

                    }else if(this.kwImage.isKwFormat == 1){
                        that.editOpenKw =true;
                    }
                }
                if (e == 'circle') {
                    that.showTran = true
                }
            }

        }
    }

</script>

<style scoped>
    .Dynamic{
        width: 100%;
        padding: 24px 38px 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        background-color: #ffffff;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
        cursor: pointer;
    }
    .Dynamic .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Dynamic .head .user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .Dynamic .head .user .userIco{
        width: 60px;
        height: 60px;
        background-color: #707070 ;
        border-radius: 50%;
        margin-right: 12px;
    }
    .Dynamic .head .user .major .userName{
        font-weight: 600;
        color: #444444;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items:flex-end;
    }
    .Dynamic .head .user .major .userMajor{
        font-size: 13px;
        color: #999999;
        margin-top: 5px;

    }
    .Dynamic .substance{
        margin-top: 6px;
        line-height: 26px;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 6;
    }
    .Dynamic .substance .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .Dynamic .substance .t{
        margin-right: 10px;
        white-space: pre-wrap;
        color: #555555;
    }
    .Dynamic .imgs{
        margin-top: 4px;
    }
    .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Dynamic .volume{
        height: 30px;
        line-height: 30px;
        color: #999999;
        font-size: 14px;
    }
    .Dynamic .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .Dynamic .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }

    .Dynamic .content {
        text-overflow:ellipsis;
        -webkit-line-clamp:4;
        -webkit-box-orient:vertical;
        overflow:hidden;
        display:-webkit-box;
        margin-bottom: 6px;
    }

    .mr5{
        margin-right: 5px;
    }
</style>
