<template>
	<div class="room-task">
        <div v-for="(item, index) in colorOption" :key="index">
            <div v-if="item.type === data.type" class="task-title" :style="{'background-color': item.color}">
                <div>
                    <span v-if="item.type === 1">任务序列：{{data.nickName}}</span>
                    <span v-if="item.type === 2">服务序列：{{data.nickName}}</span>
                    <span v-if="item.type === 3">产品序列：{{data.nickName}}</span>
                </div>
                <div style="font-size: 18px">
                    <i class="el-icon-edit hand op" v-if="user.uuid === data.uuid"  @click="editTask" style="margin-right: 10px"></i>
                    <i class="el-icon-delete hand op" v-if="user.uuid === data.uuid"  @click="deleteTask"></i>
                </div>
            </div>
        </div>

		<div class="task-top hand" @click="toTaskInfo(data.id)">
			<div style="color: #2970FF; margin-bottom: 6px; font-weight: 600">{{data.title}}</div>
			<div class="task-content">{{data.content}}</div>
		</div>
		<el-divider></el-divider>

		<div class="task-body">
			<div style="margin-bottom: 10px">任务下达数： {{data.taskCount}}</div>
			<div style="display: flex; justify-content: space-between">
				<span>完成数： <span style="color: #5BC130">{{data.taskFinishCount}}</span></span>
				<span>未完成数： <span style="color: #FF5E5E">{{data.taskUnFinishCount}}</span></span>
			</div>
		</div>
		<el-divider></el-divider>

		<div class="task-foot">
			<div style="margin-bottom: 10px">创建于{{data.creatTime}}</div>
			<div style="display: flex; justify-content: space-between">
				<div>参与人员： {{data.teskUserList.length}}人</div>
				<div>
					<el-popover placement="bottom-end" width="300" trigger="click">
						<div style="padding: 18px; height: 260px">
							<div v-for="(item, index) in data.teskUserList" :key="index">
								<div style="display: flex; margin-bottom: 10px;">
                                    <img :src="item.headIco" style="width: 40px; height: 40px; border-radius: 50%">
                                    <div style="font-size: 18px; font-weight: 600; margin-top: 6px; margin-left: 8px">{{item.nickName}}</div>
                                </div>
							</div>
						</div>
						<span @click="show = !show" class="hand op" slot="reference" style="color: #999999;" v-if="show === false">展开 <i style="font-size: 18px;" class="el-icon-caret-bottom"></i></span>
						<span @click="show = !show" class="hand op" slot="reference" style="color: #999999;" v-if="show === true">收起 <i style="font-size: 18px;" class="el-icon-caret-top"></i></span>
					</el-popover>
				</div>
			</div>
		</div>

        <!--编辑弹窗-->
        <el-dialog :visible.sync="showInfo" width="52%" :show-close="false" class="event-form">
            <div class="top">
                            <span class="contentType">
                                <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                                <span>{{roomName}}</span>
                            </span>编辑任务序列
                <img src="../../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showInfo = false">
            </div>
            <div style="padding: 20px 40px">
                <div style="margin-bottom: 40px">
                    <div class="title-form">序列名称</div>
                    <el-input
                        type="text"
                        v-model="taskForm.title"
                        :show-word-limit="true"
                        maxlength="30"
                        minlength="2"
                        placeholder="请输入序列名称">
                    </el-input>
                </div>
                <div style="margin-bottom: 40px">
                    <div class="title-form">序列说明</div>
                    <el-input
                        type="textarea"
                        v-model="taskForm.content"
                        :show-word-limit="true"
                        maxlength="500"
                        :rows="5"
                        placeholder="请输入序列说明">
                    </el-input>
                </div>
                <div style="display: flex; justify-content: center">
                    <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;" @click="editTasksList">确认修改</div>
                </div>
            </div>
        </el-dialog>
	</div>
</template>

<script>
	import User from "@/components/common/User.vue";
	export default {
		name:"RoomTask",
		components: {
			User
		},
        computed: { 
            is_login(){
                return this.$store.state.is_login 
            },
            user(){
				return this.$store.state.user
            }
        },
		data () {
			return {
				show: false,
				userList: [],

                colorOption: [
                    {type: 1, color: "#FF9800"},
                    {type: 2, color: "#2970FF"},
                    {type: 3, color: "#FF5E5E"},
                ],

                taskForm: {},
                showInfo: false,
			}
		},
		props:{
			data:{
				type:Object,
				default() {
                    return {
						title:"",
                    }
                }
			},

            roomName:{
                type:String,
                default() {
                    return ''
                }
            }
        },
		created:function(){

		},    
		mounted: function () {

		},
		methods:{
            // 删除任务序列
            deleteTask() {
                let that = this;
                this.utils.confirm('确认删除此序列？', function () {
                    that.newApi.delTaskSequence({sequenceId: that.data.id}).then(res => {
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data);
                            that.$emit('reTaskList')
                        }
                    })
                })
            },

            // 确认修改任务序列
            editTasksList() {
                let param = {};
                param.sequenceId = this.data.id;
                param.title = this.taskForm.title;
                param.content = this.taskForm.content;
                this.newApi.editTaskSequence(param).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.showInfo = false;
                        this.$emit('reTaskList')
                    }
                })
            },

            editTask() {
                this.showInfo = true;
                this.taskForm = {
                    title: this.data.title,
                    content: this.data.content,
                }
            },

			// 进入详情
			toTaskInfo(id) {
				this.$parent.toTaskInfo(id)
			},

			open(id) {
				var that=this;
				that.$confirm('确定要删除此任务序列吗?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					that.ajax("get_no_task_list",{
						fid:id
					},function(ret){
						if(ret.data.length>0){
							that.$message({
								type: 'info',
								message: '已有任务无法删除！'
							});
						}else{
							that.ajax('del_task',{
								id:id
							},function(){
								that.$message({
									type: 'success',
									message: '删除成功!'
								});
								that.$parent.refresh();
							})
						}
					})
				}).catch(() => {
				  that.$message({
					type: 'info',
					message: '已取消删除'
				  });          
				});
			},
			add_task(){
				this.$emit("add_task",this.data.id,this.data.room_id);
			},
			show_task(){
				this.$emit("show_task",this.data);
			}
		}

	}

</script>

<style scoped>
	.room-task {
		background: #FFFFFF;
		box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
	}

	.task-title {
		display: flex;
		justify-content: space-between;
		padding: 10px;
		color: #FFFFFF;
		font-size: 16px;
	}

	.task-content {
		text-overflow:ellipsis;
		-webkit-line-clamp:3;
		-webkit-box-orient:vertical;
		overflow:hidden;
		display:-webkit-box;
	}

	.task-body {
		height: 60px;
		padding: 0 16px;
		font-size: 16px;
		color: #999999;
	}

	.task-top {
		height: 94px;
		font-size: 16px;
		padding: 10px 16px 0 16px;
	}

	.task-foot {
		height: 60px;
		font-size: 16px;
		padding: 0 16px 16px 16px;
	}

	.room-task .el-divider--horizontal {
		margin: 16px 0;
	}
</style>