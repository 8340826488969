<template>
    <div>
        <div class="Dynamic">

            <div class="head">
                <div class="user" @click="openInfo(data.user.uuid, data.user.userType)">
                    <img :src="data.user.headIco + '-200x200'" class="userIco" >
                    <div class="major">
                        <div class="userName">
                            <div>{{data.user.nickName}}</div>
                            <img v-show="data.user.isVip == 1" src="../imgs/newVip.png" style="width: 24px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.v == 1" src="../imgs/newV.png" style="width: 20px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.authenticationStatus >= 2" src="../imgs/newP.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.expertAuthStatus === 2" src="../imgs/newS.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                        </div>
                        <div class="userMajor"><span v-if="data.user.address.cityName">{{data.user.address.cityName[1]}}</span><span v-if="data.user.major && data.user.address.cityName"> | </span>{{data.user.major}}</div>
                    </div>
                </div>

                <div class="action" style="display: flex">
                    <div style="width: 130px; height: 20px; text-align: center; margin-right: 10px; font-weight: 600; font-size: 18px; padding: 10px 0" :style="{'background-image': `url(${require('../imgs/template6.png')})`, 'color': color[data.menuType - 6]}">
                        {{data.data.config.formRef}}
                    </div>
                    <el-dropdown @command="more_action" v-if="me.uuid == data.user.uuid">
					<span class="el-dropdown-link">
						<i class="el-icon-caret-bottom" style="font-size: 24px"></i>
					</span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="edit">编辑</el-dropdown-item>
                            <el-dropdown-item command="delete" >删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>

            <!-- 内容 -->
            <div class="substance" @click="content_info" v-if="data.content.fd_1677729356192">
                <span v-if="data.content.fd_1677729356192.length > 200" :class="showAll === true ? 'contentAll' : 'content'" style="width: 100%;">
                    <span class="contentType" @click="toRoom">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
						<span>{{data.roomName}}</span>
                    </span>
                    <span v-if="showAll === false" class="hand op sc" @click="showAllContent(data.id)" style="float: right; clear: both; color: #2970FF">查看全部</span>
					<span  style="white-space: pre-wrap">{{data.content.fd_1677729356192}}</span>
                    <span v-if="showAll === true" class="hand op sc" @click="showAllContent" style="color: #2970FF;">收起</span>
                </span>
                <span v-else>
                    <div class="contentType" @click="toRoom" style="margin-bottom: 10px;" v-show="this.$parent.showRoomName">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
						<span>{{data.roomName}}</span>
                    </div>
                    <div  style="white-space: pre-wrap;">{{data.content.fd_1677729356192}} </div>
                    <div v-if="data.menuType == 85"  style="white-space: pre-wrap;">项目日期：{{data.content.fd_1703129747153}}</div>
                    <div v-if="data.menuType == 74"  style="white-space: pre-wrap;">出差时间：<span v-for="(item,index) in data.content.fd_1703498556296" :key="index">{{item}}<span style="padding: 0 10px;" v-if="index !== data.content.fd_1703498556296.length - 1">至</span></span></div>
                    <div v-if="data.menuType == 7" style="white-space: pre-wrap;">联系电话：{{data.content.fd_1702612766061}}</div>
                    <div v-if="data.menuType == 130"  style="white-space: pre-wrap;">截止日期：{{data.content.fd_1704346986417}}</div>
                    <div v-if="data.menuType == 137"  style="white-space: pre-wrap;">日期：{{data.content.fd_1704365009815}}</div>
                </span>
            </div>
            <!-- 图片 -->
<!--            <div class="imgs" v-if="data.content.fd_1677732494851 || JSON.parse(data.content.fd_1677732494851).length !== 0">-->
<!--                &lt;!&ndash; 单图 &ndash;&gt;-->
<!--                <div v-if="JSON.parse(data.content.fd_1677732494851).length == 1" style="width:100%;height:406px;overflow: hidden;">-->
<!--                    <img :src="JSON.parse(data.content.fd_1677732494851)[0].url" style="width:auto;height:100%;">-->
<!--                </div>-->

<!--                &lt;!&ndash; 二至四张图 &ndash;&gt;-->
<!--                <div v-if="JSON.parse(data.content.fd_1677732494851).length >= 2 && JSON.parse(data.content.fd_1677732494851).length <= 4" style="width:100%;height:200px;display: flex;justify-content: space-between;align-items: center;">-->
<!--                    <div style="width: calc((100% - 15px) / 4);height: 100%" v-for="item in 4" :key="item" >-->
<!--                        <img :src="JSON.parse(data.content.fd_1677732494851)[item - 1].url+'-200x200'" style="width: 100%;height: 100%;" v-if="JSON.parse(data.content.fd_1677732494851).length >= item">-->
<!--                    </div>-->
<!--                </div>-->

<!--                &lt;!&ndash; 大于四张图 &ndash;&gt;-->
<!--                <div v-if="JSON.parse(data.content.fd_1677732494851).length > 4"  style="width:100%;height:406px;">-->
<!--                    <div class="spacebetween">-->
<!--                        <div style="width: 406px; height: 406px">-->
<!--                            <img :src="JSON.parse(data.content.fd_1677732494851)[0].url+'-1024x1024'" style="width: 100%;height: 100%;">-->
<!--                        </div>-->
<!--                        <div style="width:calc(100% - 411px);height: 406px; display: flex;flex-direction: column;justify-content: space-between;">-->
<!--                            <div class="spacebetween" style="height: calc((100% - 5px) / 2)">-->
<!--                                <img :src="JSON.parse(data.content.fd_1677732494851)[1].url+'-200x200'" style="width:calc((100% - 5px) / 2);height:100%;">-->
<!--                                <img :src="JSON.parse(data.content.fd_1677732494851)[2].url+'-200x200'" style="width:calc((100% - 5px) / 2);height:100%;">-->
<!--                            </div>-->
<!--                            <div class="spacebetween"  style="height: calc((100% - 5px) / 2)">-->
<!--                                <img :src="JSON.parse(data.content.fd_1677732494851)[3].url+'-200x200'" style="width:calc((100% - 5px) / 2);height:100%;">-->
<!--                                <img :src="JSON.parse(data.content.fd_1677732494851)[4].url+'-200x200'" style="width:calc((100% - 5px) / 2);height:100%;">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

            <div style="margin-top: 6px" class="file-bag" v-if="data.content.fd_1677732544705">
                <div class="file-box hand op" @click="downloadFiles(item.url, item.name)" v-for="(item, index) in JSON.parse(data.content.fd_1677732544705)" :key="index">
                    <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                    <span style="font-size: 16px; vertical-align: middle">{{item.name}}</span>
                </div>
            </div>

            <div>
                <div style="display: flex">
                    <div class="volume" style="margin-right: 20px">{{data.creationTime}}</div>
<!--                    <div class="volume">阅读量:{{data.clickCount}}</div>-->
                </div>
                <div>
                    <DynamicOperation :data="data"></DynamicOperation>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import DynamicOperation from "./DynamicOperation";
    export default {
        name:"HotTemplates",
        components: {
            DynamicOperation
        },
        computed: {
            me() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                //是否展示
                show:true,
                edit_show:false,
                showAll: false,
                clickDynamicCount:0,
                color: ['#FF0000','#2970FF','#FFA500','#666666','#031F88']
            }
        },
        props:{
            data:{
                type:Object,
                default(){
                    return {
                        videos:[],
                        content:"",
                        user:'',
                    }
                }
            },
        },
        created:function(){

        },
        mounted: function () {
            this.data.content.fd_1702615483081 = '项目名称'
        },
        methods:{
            // 跳转到Room
            toRoom() {
                this.$router.push({ path: '/room/info/' + this.data.roomId})
            },

            // 下载附件
            downloadFiles(url, name) {
                this.utils.download(url, name)
            },

            //跳转至详情页
            content_info(){
                // menuType 6会议纪要  menuType7 甲方联系单
                if (this.data.menuType == 6){ //会议纪要
                    this.utils.b('/templates/conference/' + this.data.id);
                }else if (this.data.menuType == 7){ //联系单
                    this.utils.b('/templates/contactForm/' + this.data.id);
                }else if(this.data.menuType == 74) { //出差申请单
                    this.utils.b('/templates/businessTripApplication/' + this.data.id);
                }else if (this.data.menuType ==85) { //合同登记表
                    this.utils.b('/templates/contract/' + this.data.id);
                }else if(this.data.menuType == 97) { //请假申请表
                    this.utils.b('/templates/askForLeave/' + this.data.id);
                }else if (this.data.menuType == 106) { //投标登记表
                    this.utils.b('/templates/bidRegistration/' + this.data.id);
                }else if(this.data.menuType == 126) { //采购单
                    this.utils.b('/templates/purchaseOrder/' + this.data.id);
                }else if (this.data.menuType == 130) { //招聘信息
                    this.utils.b('/templates/recruit/' + this.data.id);
                }else if(this.data.menuType == 134) {  // 派车单
                    this.utils.b('/templates/truckingOrderAdmin/' + this.data.id);
                }else if(this.data.menuType == 137){ // 项目投标
                    this.utils.b('/templates/projectFiling/' + this.data.id);
                }else if(this.data.menuType == 139){ // 施工日志
                    this.utils.b('/templates/constructionLog/' + this.data.id);
                }

            },

            getForwardContent() {
                this.$emit('getForwardContent');
            },

            // 跳转链接
            toLink(url) {
                if (url.indexOf('http') >= 0) {
                    window.open(url, "_blank")
                } else {
                    window.open('//' + url, "_blank")
                }
            },


            // 点击打开用户信息
            openInfo(uuid, userType) {
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false;
              }
                if (userType === 1) {
                    this.$router.push({ path: '/circle/mySpace/' + uuid})
                }
                if (userType === 2 || userType === 3) {
                    this.$router.push({ path: '/circle/organSpace/' + uuid})
                }
            },

            // 点击展示全部内容
            showAllContent() {
                var that = this;
                this.showAll = ! this.showAll;
                if(that.showAll == true && that.clickDynamicCount == 0){
                    that.clickDynamicCount++;
                    //eslint-disable-next-line no-unused-vars
                    // that.newApi.setDynamicClickCount({id:id}).then((res) => {})
                }
            },

            //下拉菜单触发的方法
            more_action:function(e){
                if (this.is_login == 0) {
                    this.utils.err("请先登录");
                    return;
                }
                var that = this;
                if (e == "delete") {
                    that.$confirm('删除后无法恢复，确认删除吗？')
                        .then(_ => {
                            console.log(_);
                            this.newApi.delRoomContent({id:that.data.id}).then(ret =>{
                                if(ret.isSuccess == 1){
                                    that.utils.sus(ret.data);
                                    that.show = false;
                                    this.$EventBus.$emit("changeData");
                                }
                            })
                        })
                        .catch(_ => {
                            console.log(_);
                        });
                }

                if (e == "edit") {
                    that.$router.push({name: 'View', query: {
                            id: this.data.menuType,
                            contentId: this.data.id
                        }})
                }
            },
        }
    }

</script>

<style scoped>
    .Dynamic{
        width: 100%;
        padding: 24px 38px 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        background-color: #ffffff;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        cursor: pointer;
    }

    .Dynamic .file-bag{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
        font-size: 15px;
    }

    .Dynamic .file-bag::-webkit-scrollbar {
        display: none;
    }

    .Dynamic .file-box {
        height: 32px;
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }

    .Dynamic .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Dynamic .head .user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .Dynamic .head .user .userIco{
        width: 60px;
        height: 60px;
        background-color: #707070 ;
        border-radius: 50%;
        margin-right: 12px;
    }
    .Dynamic .head .user .major .userName{
        font-weight: 600;
        color: #444444;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items:flex-end;
    }
    .Dynamic .head .user .major .userMajor{
        font-size: 13px;
        color: #999999;
        margin-top: 5px;
        margin-right: 10px;

    }
    .Dynamic .substance{
        margin-top: 6px;
        line-height: 26px;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .Dynamic .substance .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .Dynamic .substance .t{
        color: #555555;
    }
    .Dynamic .imgs{
        margin-top: 6px;
    }
    .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Dynamic .volume{
        height: 30px;
        line-height: 30px;
        color: #999999;
        font-size: 14px;
    }
    .Dynamic .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .Dynamic .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }
    .Dynamic .content {
        text-overflow:ellipsis;
        -webkit-line-clamp:4;
        -webkit-box-orient:vertical;
        overflow:hidden;
        display:-webkit-box;
        margin-bottom: 12px;
    }
    .Dynamic .content::before{
        content: '';
        float: right;
        width: 0;
        height: 78px;
    }
    .Dynamic .contentAll {
        overflow:hidden;
        margin-bottom: 12px;
    }
    .mr5{
        margin-right: 5px;
    }
</style>
