<template>
    <div class="question">
        <div class="shadow" style="width: 100%;overflow: hidden;background-color: #FFFFFF;margin-top: 10px; margin-bottom: 2px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
            <div style="display: flex;justify-content: flex-start;align-items: center;">
                <div class="room-tab hand" :class="tab===0?'room-type-bottom':''"
                     @click="setTab(0)">最新
                </div>
                <div style="color: #707070; line-height: 50px">|</div>
                <div class="room-tab hand" :class="tab===1?'room-type-bottom':''"
                     @click="setTab(1)">红包问答
                </div>
                <div class="room-tab hand" :class="tab===2?'room-type-bottom':''"
                     @click="setTab(2)">悬赏资料
                </div>
                <div class="room-tab hand" :class="tab===3?'room-type-bottom':''"
                     @click="setTab(3)">悬赏讨论
                </div>
                <div class="room-tab hand" :class="tab===4?'room-type-bottom':''"
                     @click="setTab(4)">比选竞标
                </div>

                <div style="margin: 0 20px">
                    <el-switch
                            v-model="pay"
                            active-value="1"
                            inactive-value=""
                            active-color="#2970FF"
                            active-text="有偿"
                            inactive-color="#E4E6EB"
                            @change="refreshQuestion">
                    </el-switch>
                </div>

                <div style="width: 260px">
                    <el-input size="small" placeholder="请输入内容" v-model="searchContent" @input="getRoomQuestion">
                        <el-button slot="append" icon="el-icon-search" @click="getRoomQuestion()"></el-button>
                    </el-input>
                </div>
            </div>

            <div>
                <div v-for="(item, index) in questionList" :key="index">
                    <div class="box hand op" @click="toQuestionInfo(item.id)" style="padding-left: 30px;display: flex; justify-content:center;align-items: center;">
                        <div  style="width: 100%;">
                            <div style="display: flex; width: 90%">
                                <!--  标题   -->
                                <div style="margin-right: 10px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: 62%;font-size: 16px;font-weight: 900;">{{item.title}}</div>
                                <div style="margin-right: 10px;" v-if="item.isReward === 1">
                                    <img src="../imgs/paper.png" style="width: 16px; height: 18px;vertical-align: middle">
                                    <span style="color: #FF5E5E;font-weight: 900;font-size: 14px;position: relative;top: 1px">￥{{item.rewardMoney}}元</span>
                                </div>
                                <div>
                                    <span v-if="item.rewardType === 1" class="red-tag" style="color: #fff; border: 1px #67c23a solid;background-color: #67c23a;border-radius: 4px">红包问答</span>
                                    <span v-if="item.rewardType === 2" class="red-tag" style="color: #fff; border: 1px #909399 solid;background-color: #909399;border-radius: 4px">悬赏资料</span>
                                    <span v-if="item.rewardType === 3" class="red-tag" style="color: #fff; border: 1px #f56c6c solid;background-color: #f56c6c;border-radius: 4px">悬赏讨论</span>
                                    <span v-if="item.rewardType === 4" class="red-tag" style="color: #fff; border: 1px #e6a23c solid;background-color: #e6a23c;border-radius: 4px">比选竞标</span>
                                </div>
                            </div>
                            <div style="float: left;margin: 8px 0px;cursor: pointer;height: 20px;font-size: 15px;clear: both">{{item.content}}</div>
                            <div style="width: auto;font-size: 13px;clear: both;margin-top: 5px;color:#757575;">
                                <img :src="item.headIco" style="float: left;width: 20px;height: 20px;border-radius: 50%;">
                                <div style="height: 20px;line-height:20px;float: left;cursor: pointer;margin: 0 10px;color: #1a1a1a;font-size: 14px">{{item.nickName}}</div>
                                <span style="font-size: 14px;padding: 0px 4px; margin-right: 10px;float: left">{{item.typeArrName[0]}} > {{item.typeArrName[1]}} > {{item.typeArrName[2]}}</span>
                                <div v-if="item.endTime != null">
                                    <span style="font-size: 14px;color: #C1C1C1;margin-right: 5px;">有效期至:</span>
                                    <span style="font-size: 14px;color: #C1C1C1;margin-right: 20px;" class="el-icon-time">{{item.endTime}}</span>
                                </div>
                                <span style="color: #999999;font-size: 14px;float: right;margin-right: 20px;"><span style="position: relative;top: 1px">{{item.reviewCount.length}}</span>回答</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Question",
        data() {
            return {
                tab: 0,
                pay: '',
                page: 1,
                more: 1,
                pageSize: 10,
                searchContent: '',
                questionList: []
            }
        },

        mounted() {
            var that = this;
            this.getRoomQuestion();
            //接受自定义广播刷新
            this.$EventBus.$on("refreshQuestion", () => {
                this.refreshQuestion()
            });

            window.onscroll = function () {
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                //滚动条到底部的条件
                var ratio = 0;
                var screen = window.screen;
                var ua = navigator.userAgent.toLowerCase();
                if (typeof window.devicePixelRatio !== "undefined") {
                    ratio = window.devicePixelRatio;
                } else if (~ua.indexOf('msie')) {
                    if (screen.deviceXDPI && screen.logicalXDPI) { //IE
                        ratio = screen.deviceXDPI / screen.logicalXDPI;
                    }
                } else if (typeof window.outerWidth !== 'undefined' && typeof window.innerWidth !== 'undefined') {
                    ratio = window.outerWidth / window.innerWidth;
                }

                if (ratio <= 1) {
                    if (scrollTop + windowHeight >= scrollHeight) {
                        if (that.more == 1) {
                            that.getRoomQuestion();
                        } else {
                            that.utils.sus("已加载完");
                        }
                    }
                } else {
                    if (scrollHeight - scrollTop - windowHeight < 10) {
                        if (that.more == 1) {
                            that.getRoomQuestion();
                        } else {
                            that.utils.sus("已加载完");
                        }
                    }
                }
            }
        },

        methods: {
            // 跳转红包问答详情
            toQuestionInfo(id) {
                this.utils.b('/index/page/?id='+id)
            },

            // 切换标签
            setTab(e) {
                this.tab = e;
                this.refreshQuestion()
            },

            // 获取room红包问答
            getRoomQuestion() {
                var that = this;
                if (that.pay === true) {
                  that.pay = ''
                }
                this.newApi.getRoomQandA({
                    roomId: this.$route.params.id,
                    page: this.page, pageSize: this.pageSize,
                    isReward: that.pay,
                    rewardType: this.tab,
                    like:that.searchContent,
                    address:'room'
                }).then(res =>{
                    this.questionList = [];
                    this.questionList = res.data;
                    if (res.data.length < 10) {
                        this.more = 0;
                    }
                })
            },

            // 有偿切换刷新
            refreshQuestion() {
                this.page = 1;
                this.more = 1;
                this.questionList = [];
                this.getRoomQuestion()
            },
        }
    }
</script>

<style scoped>
    .question .room-tab {
        line-height: 50px;
        font-size: 15px;
        width: 90px;
        text-align: center;
    }

    .question .room-type-bottom {
        width: 90px;
        color: #2970FF;
        font-weight: bold;
    }

    .question .box {
        min-height: 100px;
        border-bottom: 1px #D9D9D9 solid
    }

    .question .box:hover {
        background-color: #F2F2F2;
    }

    .question .red-tag {
        font-size: 14px;
        padding: 2px 4px;
        margin-right: 10px;
        background-color: #FFFFFF
    }


</style>
