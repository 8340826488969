<template>
    <div>
        <div class="dialog-content" style="padding-top: 0px;padding-bottom: 0px;">
           <div v-if="isSet == 0 || isSet == 1">
               <h3>邀请成员加入团队</h3>
               <p>邀请你的全部团队成员加入团队，共同解锁高效协作。</p>
           </div>

            <div v-if="isSet == 0">
                <div class="content" style="display: flex;">
                    <span class="el-icon-warning" style="margin-top: 3px;margin-right: 5px;"> </span>
                    <p class="invitation">{{msg}}</p>
                </div>
                <div style="display: flex;">
                    <div style="width: 80%;">
                        <el-input v-model="roomInfo.password" placeholder="请先设置room口令"  class="input-field"></el-input>
                    </div>
                    <div style="width: 20%;margin-left: 10px;">
                        <el-button type="primary" style="height: 35px;line-height: 11px;" @click="setPassword">确认</el-button>
                    </div>
                </div>
            </div>

            <el-tabs v-if="isSet == 1" v-model="inviteMethod" class="invite-tabs">
                <el-tab-pane label="二维码邀请" name="qrInvite">
                    <div class="qr-code-container">
                        <p style="margin-bottom: 0px;">使用微信扫描二维码或者通过链接邀请更多的人加入协作。</p>
                        <vue-qr
                            :text="qr_imgUrl"
                            :size="250"
                            :logo-scale="0.2">
                        </vue-qr>
                        <div style="width: 200px;line-height: 30px;margin: 0 auto;display: flex;margin-bottom: 10px;font-size: 12px;">
                            <div style="width: 180px;line-height: 30px;">
                                {{roomInfo.endTime}} <span style="color: blue;">失效</span>
                            </div>
                            <div v-if="roomInfo.uuid == user.uuid" style="width: 20px;line-height: 30px;">
                                <span @click="refreshRoomCode" class="el-icon-refresh" style="margin-top: 3px;margin-right: 5px;cursor: pointer;"> </span>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div style="width: 80%;">
                                <el-input v-model="roomInfo.password" placeholder="password" readonly class="input-field"></el-input>
                            </div>
                            <div style="width: 20%;">
                                <el-button type="primary" style="height: 35px;line-height: 11px;" @click="CopyPassword">复制口令</el-button>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="链接分享邀请" name="wechatInvite">
                    <div class="wechat-share-container">
                        <div style="display: flex;">
                            <div style="width: 80%;">
                                <el-input v-model="roomInfo.password" placeholder="password" readonly class="input-field"></el-input>
                            </div>
                            <div style="width: 20%;">
                                <el-button type="primary" style="height: 35px;line-height: 11px;" @click="CopyPassword">复制口令</el-button>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div style="width: 80%;">
                                <el-input v-model="qr_imgUrl" placeholder="https://s.ckn.cn/2AhVlg" readonly class="input-field"></el-input>
                            </div>
                            <div style="width: 20%;">
                                <el-button type="primary" style="height: 35px;line-height: 11px;" @click="handleCopyLink">复制链接</el-button>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>

            <div v-if="isSet == -1">
                <div class="content" style="padding-bottom: 5px;display: flex;">
                    <span class="el-icon-warning" style="margin-top: 3px;margin-right: 5px;"> </span>
                    <p class="invitation">{{msg}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        onClose:{
            type:Function,
            default(){
                return function (){}
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },
    watch: {
        //监听相同路由下参数变化的时候，从而实现异步刷新
        '$route'() {
            this.roomId = this.$route.params.id;
            this.getRoomPassword();
        },
    },
    data() {
        return {
            roomId:this.$route.params.id,
            inviteMethod: 'qrInvite',
            qr_imgUrl: "",
            qr_logo: require('@/assets/imgs/other/bgb.png'),
            roomInfo:{
                password:""
            },
            isSet:-1,
            msg:"",
        };
    },
    mounted() {
        this.getRoomPassword();
    },
    methods: {
        getRoomPassword(){
            var that = this;
            that.newApi.getRoomPassword({roomId:that.roomId}).then((res =>{
                if (res.isSuccess === 1) {
                    if(res.data.code == -1){
                        that.isSet = res.data.code;
                        that.msg = res.data.msg;
                    }else if(res.data.code == 0){
                        that.isSet = res.data.code;
                        that.msg = res.data.msg;
                    }else if(res.data.code == 1){
                        that.roomInfo = res.data.roomInfo;
                        that.qr_imgUrl = that.roomInfo.codeUrl;
                        that.isSet = res.data.code;
                    }
                }
            }))
        },
        setPassword(){
            var that = this;
            if(that.isSet == 1){
                that.utils.err("已经设置过口令了");
                return;
            }
            that.newApi.setRoomPassword({roomId:that.roomId,password:that.roomInfo.password}).then((res =>{
                if (res.isSuccess === 1) {
                    that.getRoomPassword();
                    that.utils.sus(res.data);
                }
            }))
        },

        refreshRoomCode(){
            var that = this;
            that.newApi.refreshRoomCode({roomId:that.roomId}).then((res =>{
                if (res.isSuccess === 1) {
                    that.getRoomPassword();
                    that.utils.sus(res.data);
                }
            }))
        },

        CopyPassword() {
            const input = document.createElement('input');
            input.value = this.roomInfo.password;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            this.$message({
                message: '口令已复制到剪贴板！',
                type: 'success'
            });
        },
        handleCopyLink() {
            const input = document.createElement('input');
            input.value = this.qr_imgUrl;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            this.$message({
                message: '链接已复制到剪贴板！',
                type: 'success'
            });
        },
    }
};
</script>

<style scoped>
.dialog-content {
    text-align: left;
    padding: 20px;
}

.dialog-content h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}

.dialog-content p {
    margin-bottom: 20px;
    color: #666;
    font-size: 14px;
}

.invite-tabs {
}

.qr-code-container, .wechat-share-container {
    text-align: center;
    margin-bottom: 20px;
}

.qr-code {
    width: 150px;
    height: 150px;
}

.input-field {
    margin-bottom: 20px;
    width: 100%;
}

.dialog-footer {
    text-align: center;
}
</style>
