<template>
	<div class="user op sc hand" :class="{'mini':type=='mini'}">
    <div class="tag-type" :style="{borderRightColor: typeList.find(e => e.value == data.roomType).color, borderTopColor: typeList.find(e => e.value == data.roomType).color}"></div>
		<div class="head_ico"  @click="room_info(data.id)">
			<div class="pwd" v-if="data.pwd != '' && data.is_collection == 0"><i class="el-icon-lock"></i></div>
			<div class="pwd" v-if="data.pwd != '' && data.is_collection == 1"><i class="el-icon-key"></i></div>
			<img :src="data.cover+'-80x80'" />
		</div>
		<div class="center">
			<div class="name" @click="room_info(data.id)">{{data.name}}</div>
			<div class="detail" @click="room_info(data.id)">
				{{data.detail}}
			</div>
			<div class="detail2">
				<i class="el-icon-user" style="color: #031F88;margin:2px;float:left;padding: 4px 0;font-size: 14px; font-weight: 600"></i>
				<div style="float:left;color: #031F88; margin-left:2px;margin-right: 8px;-webkit-transform-origin-x: 0;-webkit-transform: scale(0.80);padding: 4px 0;">{{data.userCount}}人 </div>
				<i class="el-icon-tickets" style="color: #031F88;margin:2px;float:left;padding: 4px 0;font-size: 14px; font-weight: 600"></i>
				<div style="float:left;color: #031F88;margin-left:2px;-webkit-transform-origin-x: 0;-webkit-transform: scale(0.80);padding: 4px 0;">{{data.contentCount}}篇</div>
				<div style="float:right;color: #FFFFFF;padding:1px 12px; border-radius: 3px;font-size:10px;" :style="{backgroundColor: typeList.find(e => e.value == data.roomType).color}" @click="room_info(data.id)">进入Room</div>
				<div v-if="data.hide === 0" class="hand op" @click="checkoutShow(data.id)" style="width: 32px; height: 20px; color: #999999; padding: 0; float: right; border: 1px #999999 solid; text-align: center; margin-right: 6px; border-radius: 3px">隐藏</div>
				<div v-if="data.hide === 1" class="hand op" @click="checkoutShow(data.id)" style="width: 32px; height: 20px; color: #BDBFC1; padding: 0; float: right; border: 1px #BDBFC1 solid; text-align: center; margin-right: 6px; border-radius: 3px">显示</div>

				<!-- {{data.click_number}}阅读 -->
			</div>

			<!-- <div class="tags">
				<div class="tag" v-for="(item,index) in data.tags" v-bind:key="index">{{item}}</div>
			</div> -->
		</div>
	</div>
</template>

<script>

	export default {
		name:"RoomList",
		components: {

		},
		data () {
			return {
        typeList: [{
          value: 1,
          color: '#2970FF'
        },
        {
          value: 2,
          color: '#FF9800'
        },
        {
          value: 3,
          color: '#031F88'
        },
        {
          value: 4,
          color: '#69BF29'
        },]
			}
		},
		props:{
			data:{
				type:Object,
				default() {
                    return {
						pwd:""
                    }
                }
			},
			type:{
                type:String,
                default() {
                    return "normal"
                }
            }
		},
		computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            }
        },
		created:function(){

		},
		mounted: function () {


		},
		methods:{
			// need_collection:function(data){
			// 	if( this.is_login == 1){
            //         this.$parent.collection_room(data);
			// 		// this.$emit("collect_room",data);
			// 	}else{
			// 		this.err("需要登陆");
			// 	}
            // },
			checkoutShow(id) {
				this.newApi.setOpenRoomHide({roomId: id}).then(res => {
					if (res.isSuccess === 1) {
						this.utils.sus(res.data);
						this.$parent.getShowList()
					}
				})
			},

			room_info:function(id){
				this.utils.b('/room/info/' + id)
			}
		}
	}

</script>

<style scoped>
	.user{
    position: relative;
		background:#FFF;margin:5px 0;
		padding:5px;overflow: hidden;
		border-radius:5px; border:1px solid #e4e4e4;
	}
  .user .tag-type {
    position:absolute;
    top: 0;
    right: 0;
    border: 14px solid #FFFFFF;
  }

	.user .head_ico{
		width:80px;height:80px; overflow:hidden;
		float:left; border-radius:5px; position:relative;
		border:1px solid #a6a6a6;
	}
	.mini .head_ico{
		height:50px;width:50px;border-radius:5px;
	}
	.user .head_ico .pwd{
		position: absolute; color:#FFF;background:rgba(0,0,0,.6);width:30px;height:30px;line-height:30px;text-align:center;right:0;bottom:0; border-radius:3px;
	}
	.mini .head_ico .pwd{
		width:15px;height:15px;line-height:15px;
	}
	.user .head_ico img{
		width:100%;height:100%;
	}
	.user .center{
		float:left; height:82px; line-height:20px; margin-left:10px;
		width:calc( 100% - 100px );
		overflow:hidden;
	}
	.mini .center{
		width:calc( 100% - 70px );height:50px;
	}

	.user .center .name{
		font-weight:bold;font-size:15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
	}
	.mini .center .name{
		font-weight:100;font-size:12px; height:20px;line-height:20px;overflow:hidden;
		margin-top:5px;
	}

	.mini .center .detail{
		display:none;
	}

	.user .center .detail{
		color:#999999; height:32px;line-height:16px; font-size: 12px;margin: 3px 0;
		overflow:hidden;display:-webkit-box; -webkit-box-orient:vertical;-webkit-line-clamp:2; text-overflow:ellipsis;

	}
	.user .center .detail2{
		overflow: hidden;font-size: 14px;
	}

	.mini .center .detail2{
		color:#bcbcbc; font-size:10px;height:30px;line-height:30px;overflow:hidden;
	}

	.user .center .tags{
		overflow:hidden;
	}
	.mini .center .tags{
		display:none;
	}

	.user .center .tags .tag{
		float:left; margin-right:5px;
		background:#f0f0fb; color:#888888;
		opacity:.6;padding:0px 5px;border-radius:3px;
	}

	.user .right{
		float:right;
	}

	.user .right .btn {
		background: #FFF;
		height: 22px;
		color: #FFF;
		line-height: 22px;
		overflow: hidden;
		float: right;
		padding: 0 15px;
		border-radius: 11px;
		margin-right: 5px;
		margin-top: 20px;
		border: 1px solid #384a92;
		color: #384a92;
	}
</style>
