<template>
    <div style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;">
        <div style="width: 1500px;margin:0 auto;overflow: hidden;margin-top:108px;">
            <div class="page_content" style="width:1206px;height: auto;float: left;margin: 0 10px 0 294px;min-height: 1000px;">
                <div style="height:auto;margin: 0 auto;">
                    <div style="width: 100%;">
                      <div style="background:#FFF; margin-bottom: 10px">
                        <div class="v-title" style="display: flex; justify-content: space-between; padding: 20px">
                          <div>
                            <div style="font-weight: 600; font-size: 16px">添加专业领域将会为您精准推荐相关Room<span style="color: #999999; font-size: 14px">（没有选择专业将进行默认推荐）</span></div>
                            <div style="display: flex; flex-wrap: wrap; margin-top: 10px">
                              <el-tag v-for="(item, index) in selectList" :key="index" style="margin-right: 20px; color: #2970FF; border-color: #2970FF; font-size: 14px; margin-bottom: 10px" color="#fff" size="small">{{item.name}}</el-tag>
                            </div>
                          </div>
                          <el-button @click="toChangeMajor" style="color: #fff; background-color: #2970FF;width: 112px; height: 32px;line-height: 0" size="small" round>添加专业领域</el-button>
                        </div>
                      </div>
                        <div style="background:#FFF;padding:0 10px;">
                            <div style="background:#FFF;overflow:hidden; display: flex">
                                <div style="width: 80%; margin-top: 8px" class="all-tab-title">
                                    <el-tabs  @tab-click="handleClick" v-model='roomType'>
                                        <el-tab-pane name="0"><span slot="label">全部Room</span></el-tab-pane>
                                        <el-tab-pane name="1"><span slot="label"><span class="icon-type" style="background-color: #2970FF"></span> 项目Room</span></el-tab-pane>
                                        <el-tab-pane name="2"><span slot="label"><span class="icon-type" style="background-color: #FF9800"></span> 兴趣Room</span></el-tab-pane>
                                        <el-tab-pane name="3"><span slot="label"><span class="icon-type" style="background-color: #031F88"></span> 机构Room</span></el-tab-pane>
                                        <el-tab-pane name="4"><span slot="label"><span class="icon-type" style="background-color: #69BF29"></span> 经营Room</span></el-tab-pane>
                                    </el-tabs>
                                </div>
                                <div style="width: 30%; border-bottom: 2px #E4E7ED solid; height: 36px; margin: 10px 0">
                                    <el-input size="small" placeholder="请输入搜索内容" v-model="selectContent" class="input-with-select">
                                        <el-button @click="getShowList()" slot="append" icon="el-icon-search"></el-button>
                                    </el-input>
                                </div>
                                <div v-if="status === 1" style="width: 20%; height: 32px; margin-top: 8px; border-bottom: 2px #E4E7ED solid; margin-right: 30px; text-align: end; padding-top: 6px">
                                    <span class="hand op" @click="checkoutStatus(0)" style="width: 174px; height: 20px; color: #2970FF; border: 1px #2970FF solid; padding: 2px 4px"><i class="el-icon-set-up" style="margin-right: 10px"></i>显示隐藏Room</span>
                                </div>
                                <div v-if="status === 0" style="width: 20%; height: 32px; margin-top: 8px; border-bottom: 2px #E4E7ED solid; margin-right: 30px; text-align: end; padding-top: 6px">
                                    <span class="hand op" @click="checkoutStatus(1)" style="width: 174px; height: 20px; color: #2970FF; border: 1px #2970FF solid; padding: 2px 4px"><i class="el-icon-set-up" style="margin-right: 10px"></i>不显示隐藏Room</span>
                                </div>
                            </div>
                            <NoData detail="暂无推荐room" v-if="open_room_list.length == 0 "></NoData>
                            <div v-if="is_show_room_list == true" style="overflow: hidden;">
                                <div v-for="(item,index) in open_room_list" v-bind:key="index" style="float:left;width:31%;margin:2px 1%;">
                                    <template>
                                        <room type="small" :data="item"  @room_info="room_info"  @collect_room ="collection_room"></room>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <el-dialog :visible.sync="showTags" top="1vh" width="810px" :show-close="false" class="event-form">
        <div class="top">
          添加专业领域
          <img src="@/assets/imgs/circle/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showTags = false">
        </div>
        <div style="padding: 10px">
          <div>
            <div class="inner-container" style="height: 78vh; border: 1px #CED0D4 solid">
              <div class="sub_types">
                <div v-for="(type,index) in typeList" :key="index">
                  <div class="type_name" style="font-weight: 600; padding:10px 20px; background-color: #F5F7FA; font-size: 16px; display: flex; justify-content: space-between; border-bottom: 1px #CED0D4 solid">
                    <div>{{ type.label }}</div>
<!--                    <div style="font-size: 13px; font-weight: 400">当前行业有<span style="color:#FF0000">{{type.num}}个</span>选项</div>-->
                  </div>
                  <div style="margin: 12px 20px" v-for="(sub,subindex) in type.children" :key="subindex">
                    <div style="color: #2970FF; font-weight: 600">{{sub.label}}</div>
                    <div style="overflow:auto;">
                      <div v-for="(tag,tagIndex) in sub.children" :key="tagIndex">
                        <div class="sub_type hand op sc"  :class="{'active':tag.check}"
                             @click="set_type_id(tag.value,tag.label,index,subindex,tagIndex)">{{ tag.label }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
<!--            <div class="sub_types">-->
<!--              <div style="margin: 12px 20px" v-for="(sub,subindex) in typeList" :key="subindex">-->
<!--                <div style="color: #2970FF; font-weight: 600">{{sub.label}}</div>-->
<!--                <div style="overflow:auto;">-->
<!--                  <div v-for="(tag,tagIndex) in sub.children" :key="tagIndex">-->
<!--                    <div class="sub_type hand op sc" :class="{'active':tag.check}"-->
<!--                         @click="set_type_id(tag.value,tag.label,subindex,tagIndex)">-->
<!--                      {{ tag.label }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div style="margin-top: 20px;">
              <div style="width: 90%; display: flex; justify-content: center">
                <div style="margin: 10px 20px 0 0">当前已选择<span style="color: #2970FF">{{selectList.length}}</span>个标签</div>
                <el-button style="width: 188px; height: 38px; background: #2970FF; border-radius: 5px; color: #FFFFFF; line-height: 0;" @click="addTags">确认添加</el-button>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
 <!--        <Im v-if="is_login==1"></Im> -->
    </div>
</template>

<script>
import NoData from "../../components/common/NoData";
import RoomNav from "./components/RoomNav";
import Room from "./components/Room";

export default {
    components: {
        RoomNav,
        NoData,
        Room
    },
    computed: {
        user() {
        return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },
    data() {
        return {
            page:1,
            more:1,
            status: 1,
            roomType: 0,
            open_room_list:[],
            is_show_room_list:true,

            selectContent: '',  // 搜索

            showTags: false,
            typeList: [],
            selectList: [],
        }
    },
    mounted:function(){
        var that = this;
        this.backTop();
        if (this.is_login) {
          this.getRoomRecommendList();
        }
        this.get_open_room_list(0);
        window.onscroll = function() {
          //变量scrollTop是滚动条滚动时，距离顶部的距离
          var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

          //变量windowHeight是可视区的高度
          var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
          //变量scrollHeight是滚动条的总高度
          var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
          //滚动条到底部的条件
          if (scrollHeight - scrollTop - windowHeight <= 0) {
            if (that.more == 1) {
              that.get_open_room_list(that.roomType);
            } else {
              console.log("已加载完");
            }
          }
        }

    },
    methods:{
        backTop () {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
        },

        // 专业领域
        toChangeMajor() {
          if (!this.utils.toAuth(1)) {
            return false
          }
          this.showTags = true
        },

        // 获取推荐
        getRoomRecommendList() {
          this.newApi.getUserRecommendType({type: 'room'}).then(res => {
            this.selectList = res.data;
              this.getTypeList();
          })
        },

      set_type_id:function(id,name,index,subIndex,tagIndex){
        var type_options = this.typeList;
        if (type_options[index].children[subIndex].children[tagIndex].check == null) {
          type_options[index].children[subIndex].children[tagIndex].check = true;
          type_options[index].num += 1
        }else{
          type_options[index].children[subIndex].children[tagIndex].check = !type_options[index].children[subIndex].children[tagIndex].check;
          if (type_options[index].children[subIndex].children[tagIndex].check === true) {
            type_options[index].num += 1
          } else {
            type_options[index].num -= 1
          }
        }
        this.typeList = type_options;
        this.$set(this.typeList,index,type_options[index]);
        this.check_types_ok()
      },



        check_types_ok: function () {
          var typeOptions = this.typeList;
          var industry = [];
          for (const i of typeOptions) {
            for (const k of i.children) {
              for (const j of k.children) {
                if (j.check) {
                  var obj = {
                    id: j.value,
                    name: j.label
                  };
                  industry.push(obj);
                }
              }
            }
          }
          this.selectList = industry;
        },

        // 回显
        checkNow() {
          var typeOptions = this.typeList;
          for (const i of typeOptions) {
            for (const k of i.children) {
              for (const j of k.children) {
                if (this.selectList.findIndex(item => item.typeId == j.value) !== -1) {
                  j.check = true
                }
              }
            }
          }
        },

        // 获取通用标签列表
        getTypeList() {
          var that = this;
          this.newApi.getTypeList({
            lv: 3
          }).then(res => {
            // for (const item of res.data) {
            //   item.num = 0
            // }
            that.typeList = res.data;
            that.checkNow()
          })
        },

        addTags() {
          let params = {};
          params.type = 'room';
          params.filed = JSON.stringify(this.selectList);
          this.newApi.updateUserRecommendType(params).then(res => {
            if (res.isSuccess === 1) {
              this.showTags = false;
              this.utils.sus(res.data);
              this.open_room_list = [];
              this.page = 1;
              this.more = 1;
              this.get_open_room_list(this.roomType);
            }
          })
        },

        getShowList() {
            this.open_room_list = [];
            this.page = 1;
            this.more = 1;
            this.get_open_room_list();
        },

        checkoutStatus(e) {
            this.status = e;
            this.open_room_list = [];
            this.page = 1;
            this.more = 1;
            this.get_open_room_list();
        },

        get_open_room_list:function(){
            var that = this;
            var params = {};
            params.roomType = that.roomType;
            params.page = that.page;
            params.pagesize = 30;
            params.status = that.status;
            params.like = this.selectContent;

            this.newApi.getOpenRoomList(params).then(res => {
                for (var i = 0; i < res.data.length; i++) {
                    that.open_room_list.push(res.data[i]);
                }
                if(res.data.length < 30){
                    that.more = 0;
                }
                that.page ++;
            });
        },

        handleClick(e) {
            this.open_room_list=[];
            this.roomType = e.name;
            this.page = 1;
            this.more = 1;
            this.get_open_room_list();
        },
        collection_room:function(item){
            var that = this;
            that.is_show_room_list =false;
            this.collection(1,item.id,function(ret){
                console.log(ret);
                that.$refs.room_left.add_room_in_left_room(item);
                for (var i = 0; i < that.open_room_list.length; i++) {
                    if(that.open_room_list[i].id == item.id){
                    that.open_room_list.splice(i,1);
                    that.is_show_room_list =true;
                    }
                }
            });

        },
        room_info:function(id){
            this.utils.a('/room/info/' + id);
        }
    }
}
</script>

<style scoped>
.inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
}

    .all-tab-title .el-tabs__item {
        font-weight: 600;
    }

    .icon-type {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    .sub_type {
      float: left;
      margin: 10px 16px 0 0;
      padding: 4px 10px;
      border-radius: 5px;
      border: 1px solid #BCD2FF;
    }

    .sub_type.active {
      color: #FFFFFF;
      background: #FFA500;
      border: 1px solid #FFA500;
      margin: 10px 16px 0 0;
    }

    .event-form .top {
      text-align: center;
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #444444;
      border: 1px solid #CECFD0;
      height: 60px;
      box-sizing: border-box;
      line-height: 60px;
    }

    .el-divider--horizontal {
      margin: 20px 0;
    }

    /deep/ .event-form .el-dialog__header {
      padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
      padding: 0;
    }
</style>
