<template>
	<div id="live" :class="{'fullScreenMode':fullScreenMode}">
		<div class="videoBox">
			<div id="DMBox" class="DMBox "  v-show="DMOpen">
				<div class="DM" v-for="(item,index) in DMList">
					<img class="headIco" :src="item.user.headIco">
					<div class="nickName">{{ item.user.nickName }}</div>
					<div class="content">{{ item.content }}</div>
				</div>
			</div>
			<div class="bottomItem">
				<div style="display: flex;justify-content: left">
					<input type="text" placeholder="发送弹幕" v-model="DMText">
					<button class="op sc hand" @click="sendDM">发送</button>
				</div>

				<div style="display: flex;justify-content: right">
					<div class="title">{{ live.anchor.nickName }}正在直播中 </div>
					<div class="title">{{ live.number }}人在线 </div>
					<div class="networkQuality"><img :src="'https://oss.baigongbao.com/webImg/wifi'+networkQuality+'.png'" ></div>

					<div class="btn op sc hand" @click="fullScreen">
						<i class="el-icon-full-screen"></i>
					</div>

					<div class="btn op sc hand" @click="DMOpen = !DMOpen">
						{{ DMOpen ? "关闭" : "开启" }}弹幕
					</div>
				</div>
			</div>
		</div>
		<div id="close" class="op sc hand" @click="closeLive">
			<i class="el-icon-close"></i>
		</div>
	</div>
</template>

<script>
	import Bottom from "@/components/common/Bottom.vue";
	import TRTC from 'trtc-sdk-v5';
	import LibGenerateTestUserSig from "@/utils/lib-generate-test-usersig.min";
	import newApi from "@/utils/newApi";

	export default {
		name: "Live",
		components: {Bottom},
		data(){
			return{
				live:{
					anchor:{
						nickName:""
					},
					number:0
				},
				DMOpen:true,
				fullScreenMode:false,
				networkQuality:0,//网络状态
				useCamera:false,//启用摄像头
				useMicrophone:false,//启用麦克风
				cameraList:[],//麦克风列表
				microphoneList:[],//摄像头列表--
				trtc:null,
				inviteShow:false,//邀请
				setsShow:false,//设置
				setsActive:"1",//设置中默认打开的选项
				cameraId:"",//当前摄像机
				microphoneId:"",//当前麦克风
				roomList:[],//room列表
				roomUserList:[],//room成员列表
				DMText:"",
				DMList:[]
			}
		},
		props:{
			roomId:{
				type:Number,
				default() {
					return 1;
				}
			},
			role:{
				type:String,
				default(){
					return "audience"; //anchor:主播  audience:观众
				}
			},
			liveId:{
				type:String,
				default() {
					return this.utils.random_string();
				}
			},
			onCreateLive:{
				type:Function,
				default(){
					return function () {

					}
				}
			},
			onClose:{
				type:Function,
				default(){
					return function () {

					}
				}
			}
		},
		beforeDestroy() {
			this.newApi.leaveLiveRoom({
				liveId: this.liveId!="" ? this.liveId : liveId
			})
		},
		watch:{
			async cameraId(value, oldvalue) {
				await this.changeCamera(value);
			},
			async useCamera(value) {
				console.log("摄像头", value);
				if (value) {
					await this.startCamera(JSON.parse(JSON.stringify(this.cameraList)));
				} else {
					await this.trtc.stopLocalVideo();
				}
			},
			async useMicrophone(value) {
				console.log("麦克风", value);
				if (value) {
					await this.startMicrophone(JSON.parse(JSON.stringify(this.microphoneList)))
				} else {
					await this.trtc.stopLocalAudio();
				}
			}
		},
		mounted() {
			this.startTRTC();
			const that = this;
			this.$EventBus.$on('LiveUserNumber',function(data){
				that.live.number = data.number;
			})

			this.$EventBus.$on("LiveFinish",async function (res) {
				await that.trtc.stopLocalVideo();
				await that.trtc.exitRoom();
				// 退房成功后，若后续无需使用 trtc 实例，则可以调用 trtc.destroy 方法销毁实例，及时释放相关资源。销毁后的 trtc 实例无法继续使用，需要重新创建新的实例。
				that.trtc.destroy();
			})

			this.$EventBus.$on('LiveMsg',function(data){
				that.DMList.push(data);

				that.$nextTick(function (){
					// 获取包含内容的滚动容器元素
					const scrollContainer = document.getElementById('DMBox');

					// 将滚动位置设置为容器的滚动高度，将其滚动到底部
					scrollContainer.scrollTop = scrollContainer.scrollHeight;
				})


			})

			window.addEventListener('beforeunload', function (event) {
				this.newApi.leaveLiveRoom({
					liveId: this.liveId
				})
			});

		},
		methods:{
			closeLive(){
				const that = this;
				this.utils.confirm("确认关闭直播？",async function () {

					await that.trtc.stopLocalVideo();
					await that.trtc.exitRoom();
					// 退房成功后，若后续无需使用 trtc 实例，则可以调用 trtc.destroy 方法销毁实例，及时释放相关资源。销毁后的 trtc 实例无法继续使用，需要重新创建新的实例。
					that.trtc.destroy();

					that.trtc.destroy();
					that.onClose();
				})
			},
			getLiveRoomInfo(){
				const that = this;
				this.newApi.getLiveRoomInfo({
					liveId: this.liveId
				}).then(function (ret){
					that.live = ret.data;
				})
			},
			sendDM(){
				if (this.DMText === ""){
					return;
				}

				this.newApi.sendLiveMsg({
					liveId: this.liveId,
					content:this.DMText
				})


				this.DMText = "";
			},
			fullScreen(){
				this.fullScreenMode = !this.fullScreenMode;
				if (this.fullScreenMode){
					if(document.documentElement.requestFullscreen) {
						document.documentElement.requestFullscreen();
					} else if(document.documentElement.mozRequestFullScreen) {
						document.documentElement.mozRequestFullScreen();
					} else if(document.documentElement.webkitRequestFullscreen) {
						document.documentElement.webkitRequestFullscreen();
					} else if(document.documentElement.msRequestFullscreen) {
						document.documentElement.msRequestFullscreen();
					}
				}else{
					if(document.exitFullscreen) {
						document.exitFullscreen();
					} else if(document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if(document.webkitExitFullscreen) {
						document.webkitExitFullscreen();
					}
				}
			},
			async changeCamera(deviceId) {
				// 切换摄像头
				await this.trtc.updateLocalVideo({
					option: {
						cameraId: deviceId
					}
				});
			},
			async startMicrophone(microphoneList) {
				if (microphoneList.length === 0) {
					this.utils.err("没有麦克风");
					return false;
				}
				console.log("尝试开启麦克风>>>>>>" + microphoneList[0].label);
				try {
					//尝试开启第一个摄像头
					await this.trtc.startLocalAudio({
						view: document.getElementsByClassName('myCam')[0],
						option: {
							microphoneId: microphoneList[0].deviceId,
						}
					});
					console.log("麦克风:" +microphoneList[0].label + "开启成功")
					this.utils.sus("麦克风:" + microphoneList[0].label + "开启成功");
					this.useMicrophone = true;
					return true;
				} catch (e) {
					console.log("麦克风失败________",e);
					return false;
				}
			},
			async startCamera(cameraList) {
				console.log("here")
				if (cameraList.length === 0) {
					this.utils.err("没有摄像头");
					return false;
				}
				console.log("尝试开启摄像头>>>>>>" + cameraList[0].label);
				try {
					console.log("尝试开启摄像头>>>>>>" + cameraList[0].label);
					//尝试开启第一个摄像头
					await this.trtc.startLocalVideo({
						view: document.getElementsByClassName('videoBox')[0],
						option: {
							cameraId: cameraList[0].deviceId,
						}
					});
					console.log("摄像头:" + cameraList[0].label + "开启成功")
					this.utils.sus("摄像头:" + cameraList[0].label + "开启成功");
					this.cameraId = cameraList[0].deviceId;
					this.useCamera = true;
					return true;
				} catch (e) {
					console.log("错误-------------------------",e.toString())
					if (e.toString().indexOf("already started") > -1){//这个错误实际成功了
						console.log("摄像头:" + cameraList[0].label + "开启成功")
						this.useCamera = true;
						return true;
					}
					console.log("摄像头:" + cameraList[0].label + "开启失败")
					this.utils.err("摄像头:" + cameraList[0].label + "开启失败");
					//开启失败 删掉这个坏的摄像头
					cameraList.splice(0, 1);
					if (cameraList.length > 0) {
						//还有摄像头的话，再开启第一个摄像头
						await this.startCamera(JSON.parse(JSON.stringify(cameraList)));
					} else {
						return false;
					}
				}

			},
			async enterRoom() {

				let liveId;
				const that = this;
				const SDKAPPID = 1400629374;
				const EXPIRETIME = 604800;
				const SECRETKEY = '4a0b3152bd395ea70744d1fa4ecd343668f26ab33d41ec465d2b928d00c12751';
				const generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
				const userSig = generator.genTestUserSig(this.me.uuid);


				if (this.role === "anchor"){//主播
					//先创建liveId
					liveId = this.utils.random_string();
					this.liveId = liveId
					this.onCreateLive(liveId);

					//填写直播间信息
					this.live.anchor = this.me;

					// 先获取摄像头
					this.cameraList = await TRTC.getCameraList();

					// 先获取麦克风列表
					this.microphoneList = await TRTC.getMicrophoneList();
				}

				if (this.role === "audience") {//用户
					// 在进房前监听主播发布事件
					this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, event => {
						console.log("主播视频发布", event);

						const userId = event.userId;
						const streamType = event.streamType;
						const view = document.getElementsByClassName('videoBox')[0];


						this.trtc.startRemoteVideo({
							userId: userId,
							streamType: streamType,
							view: view
						});
					});

					//填写直播间信息
					this.getLiveRoomInfo();
				}



				// 在进房前监听网络事件
				this.trtc.on(TRTC.EVENT.NETWORK_QUALITY, event => {
					if (this.role === "audience"){
						//用户看下载速度
						this.networkQuality = event.downlinkNetworkQuality;
					}else {
						//主播看上传速度
						this.networkQuality = event.uplinkNetworkQuality;
					}

				})

				//进房
				console.log("进房:"+(this.liveId!="" ? this.liveId : liveId))

				this.trtc.enterRoom({
					strRoomId: this.liveId!="" ? this.liveId : liveId,
					userId: this.me.uuid,
					scene: 'live',
					role:this.role,
					sdkAppId: SDKAPPID,
					userSig: userSig
				})

				if (this.role === "audience"){
					//用户
					this.newApi.joinLiveRoom({
						liveId:  this.liveId!="" ? this.liveId : liveId
					})
				}else{
					//主播
					this.newApi.createLiveRoom({
						roomId:this.roomId,
						liveId:  this.liveId!="" ? this.liveId : liveId
					})
				}




				if (this.role === "anchor"){

					//主播，直接开启摄像头
					console.log('开启摄像头');

					console.log('摄像头列表',this.cameraList);
					console.log('麦克风列表',this.microphoneList);



					let res =  await this.startCamera(JSON.parse(JSON.stringify(this.cameraList)));
					if (!res){
						this.utils.err("摄像头全部开启失败");
					}
					res =  await this.startMicrophone(JSON.parse(JSON.stringify(this.microphoneList)));
					if (!res){
						this.utils.err("麦克风全部开启失败");
					}
				}
			},
			startTRTC(){
				TRTC.setLogLevel(5);
				this.trtc = TRTC.create();
				this.enterRoom();

			}
		},
		computed: {
			me() {
				return this.$store.state.user
			}
		}
	}
</script>

<style scoped lang="scss">


	#live{
		width: 100%;
		height: 100%;
		background: #202020;
		position: relative;
		#close{
			position: absolute;
			top:0;right: 0;
			width: 30px; height: 30px;
			z-index: 200; color: #FFFFFF; font-size: 30px;

		}
		.videoBox{
			width: 100%;
			height: 100%;
			.DMBox{
				z-index: 10;
				background: rgba(0,0,0,0);position: absolute;
				left: 0;top: 0;
				width: 350px;
				overflow-y: scroll;
				height: calc( 100% - 70px ); padding: 10px;
				.DM{
					display: flex; gap: 3px; align-items: center;
					background: rgba(0,0,0,.3); padding: 10px;
					border-radius: 10px; margin-bottom: 10px;
					.headIco{
						width: 30px; height:30px; border-radius: 50%;
					}
					.nickName{
						color: #b0d2ff; text-shadow: 0px 0px 2px #000;
					}
					.content{
						flex: 1;
						color: #FFF;text-shadow: 0px 0px 2px #000;
					}
				}
			}

			.DMBox::-webkit-scrollbar {
				width: 0; /* 隐藏滚动条 */
			}

			//.DMBox:hover::-webkit-scrollbar {
			//	width: 10px; /* 鼠标 hover 时显示滚动条 */
			//}
			//
			//.DMBox::-webkit-scrollbar-thumb {
			//	background: rgba(0,0,0,.3); /* 滚动条thumb颜色 */
			//	border-radius: 5px; /* 滚动条thumb圆角 */
			//}
			//
			//.DMBox::-webkit-scrollbar-track {
			//	background: #eee; /* 滚动条轨道颜色 */
			//	border-radius: 5px; /* 滚动条轨道圆角 */
			//}
			.bottomItem{
				opacity: 0.8;
				position: absolute; color: #FFF; font-size: 15px;
				bottom: 0; justify-content: space-between;
				left: 0; width: 100%; height: 50px; line-height: 30px;
				align-items: center;
				display: flex; z-index: 1; background: rgba(0,0,0,.6);
				div{
					margin: 10px;
				}
				.networkQuality{
					height: 20px;margin: 15px;
					img{
						width: 100%;height: 100%;
					}
				}
				input{
					height:35px; outline: 0;
					padding: 0 10px; width: 300px;
					border-top-left-radius: 17.5px;
					border-bottom-left-radius: 17.5px;
				}
				button{
					height:38px; width: 80px;
					border-top-right-radius: 17.5px;
					border-bottom-right-radius: 17.5px;
				}
			}
			.bottomItem:hover{
				opacity: 1;
			}
		}
	}

	.fullScreenMode{
		position: fixed !important;
		left: 0 !important;
		top: 0 !important;
		width: 100vw !important; height: 100vh !important;
		z-index: 999;
	}
</style>
