<template>
    <div class="active-card">
        <div class="event-body">
            <div class="event-title active">
                <span>活动事件</span>
                <span v-if="type" style="color: #FFFFFF; float: right; font-size: 14px; margin-right: 10px" class="hand op" @click="$parent.$parent.showMoreEvent('活动事件')">更多<i class="el-icon-arrow-right"></i></span>
            </div>
            <div class="event-content">
                <div>
                    <div style="font-size: 18px; font-weight: 600">{{data.title}}</div>
                    <div style="color: #2970FF">{{data.startTime}}</div>
                    <div><span>活动地点： </span> <span style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{data.location}}</span></div>
                    <div v-if="data.status == 2" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                        <span>热度： </span><span v-if="Date.now() > Date.parse(data.endTime)" style="color: red;">活动结束{{data.signNum}}人报名</span>
                        <span v-if="Date.now() < Date.parse(data.endTime)">正在报名 {{data.signNum}}人报名</span>
                    </div>
                    <div v-if="data.status == 3">
                        <span style="color: red;">活动已取消</span>
                    </div>
                </div>
                <div>
                    <img :src="data.cover" class="image-ico">
                    <div class="hand op image-btn" style="margin-bottom: 8px" @click="toEvent('sign', data.id)">查看详情</div>
                    <div class="hand op image-btn" v-if="data.uuid === user.uuid && data.signNum === 0 && data.status == 2" @click="showEdit = true">编辑</div>
                </div>
            </div>
        </div>

        <!--报名弹窗-->
        <el-dialog :visible.sync="showEdit" width="52%" :show-close="false" class="event-form" v-if="showEdit == true">
            <div class="top">
                {{data.name}}
                <img src="../../../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showEdit = false">
            </div>
            <div style="padding: 20px 40px">
                <SignForm :data="data" @closeEvent="closeEvent"></SignForm>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import SignForm from "@/components/common/signUp/SignForm.vue";
    export default {
        name: "ActiveCard",
        components: {SignForm},
        data() {
            return {
                showEdit: false,
            }
        },

        props: {
            data: {
                type: Object,
                default() {
                    return {}
                }
            },

            type: {
                type: String,
                default() {
                    return '';
                }
            },

            roomId: {
                type: Number,
                default() {
                    return 0;
                }
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },

        mounted() {
        },

        methods: {
            // 进入事件详情
            toEvent(type, id) {
                this.$router.push(
                    {
                        name: 'Event',
                        query: {
                            id: id,
                            type: type,
                            roomId: this.roomId,
                        }
                    }
                )
            },

            // 关闭事件弹窗
            closeEvent() {
                this.showEdit = false;
            },

        }
    }
</script>

<style scoped>
    .event-body {
        margin-bottom: 10px;
    }

    .event-body .event-title {
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
    }

    .event-body .active {
        background-image: url("../../imgs/title1.png");
    }

    .event-body .video {
        background-image: url("../../imgs/title2.png");
    }

    .event-body .make {
        background-image: url("../../imgs/title6.png");
    }

    .event-one .event-body .punch {
        background-image: url("../../imgs/title4.png");
    }

    .event-one .event-body .money {
        background-image: url("../../imgs/title5.png");
    }

    .event-one .event-body .pay {
        background-image: url("../../imgs/title3.png");
    }

    .event-body .event-content {
        min-height: 120px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        line-height: 30px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-body .event-info {
        min-height: 120px;
        padding: 20px;
        line-height: 20px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-body .event-content .image-ico {
        width: 84px;
        height: 66px;
        margin-top: 10px;
    }

    .event-body .event-content .image-btn {
        width: 88px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-weight: 600;
        border-radius: 5px;
        background-color: #E4E6EB;
        color: #2970FF;
    }

    .active-card .top {
        text-align: center;
        position: relative;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }
</style>
