<template>
	<div>
		<Release class="Dynamic" v-if="edit_show" :data="data"></Release>
		<div class="Dynamic">

			<div class="head">
				<div class="user" @click="openInfo(data.user.uuid, data.user.userType)">
					<img :src="data.user.headIco + '-200x200'" class="userIco" >
					<div class="major">
						<div class="userName">
							<div>{{data.user.nickName}}</div>
                            <img v-show="data.user.isVip == 1" src="../imgs/newVip.png" style="width: 24px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.v == 1" src="../imgs/newV.png" style="width: 20px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.authenticationStatus >= 2" src="../imgs/newP.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.expertAuthStatus === 2" src="../imgs/newS.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
						</div>
						<div class="userMajor"><span v-if="data.user.address.cityName">{{data.user.address.cityName[1]}}</span><span v-if="data.user.major && data.user.address.cityName"> | </span>{{data.user.major}}</div>
					</div>
				</div>

				<div class="action" v-if="me.uuid == data.user.uuid">
					<el-dropdown @command="more_action">
					<span class="el-dropdown-link">
						<i class="el-icon-caret-bottom" style="font-size: 24px"></i>
					</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-if="me.uuid == data.user.uuid" command="edit">编辑</el-dropdown-item>

<!--							<el-dropdown-item v-if="me.uuid == data.user.uuid" command="weChart">微信分享</el-dropdown-item>-->

<!--							<el-dropdown-item command="share">转移到其他Room</el-dropdown-item>-->

<!--							<el-dropdown-item command="share">IM给好友</el-dropdown-item>-->

<!--							<el-dropdown-item command="circle">转发朋友圈</el-dropdown-item>-->

							<el-dropdown-item v-if="me.uuid == data.user.uuid"  command="delete" >删除</el-dropdown-item>

						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>

			<!-- 内容 -->
			<div class="substance">
                <span v-if="data.content.content.length > 200" :class="showAll === true ? 'contentAll' : 'content'" style="width: 100%;">
                    <span class="contentType" @click="toRoom">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
						<span>{{data.roomName}}</span>
                    </span>
                    <span v-if="showAll === false" class="hand op sc" @click="showAllContent(data.id)" style="float: right; clear: both; color: #2970FF">查看全部</span>
					<div v-html="data.content.content" class="html" style="white-space: pre-wrap"></div>

                    <span v-if="data.content.link" @click="toLink(data.content.link)" class="t" style="color: #1C7EFF; margin-right: 4px"><i class="el-icon-paperclip"></i>{{data.content.link}}</span>
                    <span v-if="showAll === true" class="hand op sc" @click="showAllContent" style="color: #2970FF;">收起</span>
                </span>
				<span v-else>
                    <span class="contentType" @click="toRoom" v-show="this.$parent.showRoomName">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
						<span>{{data.roomName}}</span>
                    </span>
                    <div v-html="data.content.content" class="html" style="white-space: pre-wrap"></div>
                    <span v-if="data.content.link" @click="toLink(data.content.link)" class="t" style="color: #1C7EFF; margin-right: 4px"><i class="el-icon-paperclip"></i>{{data.content.link}}</span>
                </span>
			</div>

            <div>
                <div style="display: flex">
                    <div class="volume" style="margin-right: 20px">{{data.creationTime}}</div>
<!--                    <div class="volume">阅读量:{{data.clickCount}}</div>-->
                </div>
                <div>
                    <DynamicOperation :data="data"></DynamicOperation>
                </div>
            </div>
		</div>
		<!--转发-->
		<el-dialog :visible.sync="showTran" class="event-form">
			<div style="padding: 40px 40px;">
				<Transpond :data="data" :url="'/room/imageInfo/' + data.id"></Transpond>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Release from "./release";
	import Transpond from "./Transpond";
    import DynamicOperation from "./DynamicOperation";
	export default {
		name:"Dynamic",
		components: {
            DynamicOperation,
			Transpond,
			Release
		},
		computed: {
			me() {
				return this.$store.state.user
			},
			is_login(){
				return this.$store.state.is_login
			},
		},
		data () {
			return {
				showTran: false,
				//是否展示
				show:true,
				edit_show:false,
				showAll: false,
				clickDynamicCount:0
			}
		},
		props:{
			data:{
				type:Object,
				default(){
					return {
						videos:[],
						content:"",
						user:'',
					}
				}
			},
		},
		created:function(){

		},
		mounted: function () {
		},
		methods:{
			// 关闭转发
			closeShow() {
				this.showTran = false
			},

			// 跳转到Room
			toRoom() {
				this.$router.push({ path: '/room/info/' + this.data.roomId})
			},

			getForwardContent() {
				this.$emit('getForwardContent');
			},

			// 跳转链接
			toLink(url) {
				if (url.indexOf('http') >= 0) {
					window.open(url, "_blank")
				} else {
					window.open('//' + url, "_blank")
				}
			},


			// 点击打开用户信息
			openInfo(uuid, userType) {
				var that = this;
        if (!this.utils.toAuth(1)) {
          return false;
        }
				if (userType === 1) {
					this.$router.push({ path: '/circle/mySpace/' + uuid})
				}
				if (userType === 2 || userType === 3) {
					this.$router.push({ path: '/circle/organSpace/' + uuid})
				}
			},

			// 点击展示全部内容
			showAllContent() {
				var that = this;
				this.showAll = ! this.showAll;
				if(that.showAll == true && that.clickDynamicCount == 0){
					that.clickDynamicCount++;
					//eslint-disable-next-line no-unused-vars
					// that.newApi.setDynamicClickCount({id:id}).then((res) => {})
				}
			},

			//下拉菜单触发的方法
			more_action:function(e){
				if (this.is_login == 0) {
					this.utils.err("请先登录");
					return;
				}
				var that = this;
				if (e == "delete") {
					that.$confirm('删除后无法恢复，确认删除吗？')
							.then(_ => {
								console.log(_);
								this.newApi.delRoomContent({id:that.data.id}).then(ret =>{
									if(ret.isSuccess == 1){
										that.utils.sus(ret.data);
										that.show = false;
										this.$EventBus.$emit("changeData");
									}
								})
							})
							.catch(_ => {
								console.log(_);
							});
				}

				if (e == "edit") {
					that.show = false;
					that.edit_show = true;
				}
				if (e == 'circle') {
					that.showTran = true
				}
			}

		}
	}

</script>

<style scoped>
	.Dynamic{
		width: 100%;
		padding: 24px 38px 10px;
		box-sizing: border-box;
		margin-bottom: 10px;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;
		box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
		cursor: pointer;
	}
	.Dynamic .head{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.Dynamic .head .user{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.Dynamic .head .user .userIco{
		width: 60px;
		height: 60px;
		background-color: #707070 ;
		border-radius: 50%;
		margin-right: 12px;
	}
	.Dynamic .head .user .major .userName{
		font-weight: 600;
		color: #444444;
		font-size: 16px;
		display: flex;
		justify-content: flex-start;
		align-items:flex-end;
	}
	.Dynamic .head .user .major .userMajor{
		font-size: 13px;
		color: #999999;
		margin-top: 5px;
		margin-right: 10px;

	}
	.Dynamic .substance{
		margin-top: 12px;
		line-height: 26px;
		font-size: 15px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.Dynamic .substance .contentType{
		color: #031F88;
		height: 16px;
		font-weight: 600;
		margin-right: 10px;
	}
	.Dynamic .substance .t{
		color: #555555;
	}
	.Dynamic .imgs{
		margin-top: 14px;
	}
	.spacebetween{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.Dynamic .volume{
		height: 30px;
		line-height: 30px;
		color: #999999;
		font-size: 14px;
	}
	.Dynamic .bottom{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		line-height:50px;
		height: 50px;
		color: #444444;
		font-size: 16px;
		text-align: center;
	}
	.Dynamic .bottom .btn{
		display: flex;
		justify-content:center;
		align-items: center;
		flex: 1;
	}
	.Dynamic .content {
		text-overflow:ellipsis;
		-webkit-line-clamp:4;
		-webkit-box-orient:vertical;
		overflow:hidden;
		display:-webkit-box;
		margin-bottom: 12px;
	}
	.Dynamic .content::before{
		content: '';
		float: right;
		width: 0;
		height: 78px;
	}
	.Dynamic .contentAll {
		overflow:hidden;
		margin-bottom: 12px;
	}
	.mr5{
		margin-right: 5px;
	}

</style>

<style>
	.html img{
		width: 100% !important;
		height: auto !important;
	}
</style>
