import { render, staticRenderFns } from "./videoForm.vue?vue&type=template&id=836f96de&scoped=true&"
import script from "./videoForm.vue?vue&type=script&lang=js&"
export * from "./videoForm.vue?vue&type=script&lang=js&"
import style0 from "./videoForm.vue?vue&type=style&index=0&id=836f96de&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "836f96de",
  null
  
)

export default component.exports