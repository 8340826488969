<template>
	<div>
		<div class="Dynamic">

			<div class="head">
				<div class="user" @click="openInfo(data.user.uuid, data.user.userType)">
					<img :src="data.user.headIco + '-200x200'" class="userIco" >
					<div class="major">
						<div class="userName">
							<div>{{data.user.nickName}}</div>
                            <img v-show="data.user.isVip == 1" src="../imgs/newVip.png" style="width: 24px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.v == 1" src="../imgs/newV.png" style="width: 20px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.authenticationStatus >= 2" src="../imgs/newP.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.expertAuthStatus === 2" src="../imgs/newS.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
						</div>
						<div class="userMajor"><span v-if="data.user.address.cityName">{{data.user.address.cityName[1]}}</span><span v-if="data.user.major && data.user.address.cityName"> | </span>{{data.user.major}}</div>
					</div>
				</div>

				<div class="action" v-if="user.uuid == data.user.uuid">
					<el-dropdown @command="more_action">
					<span class="el-dropdown-link">
						<i class="el-icon-caret-bottom" style="font-size: 24px"></i>
					</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-if="user.uuid == data.user.uuid" command="edit">编辑</el-dropdown-item>
							<el-dropdown-item v-if="user.uuid == data.user.uuid"  command="delete" >删除</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>

			<!-- 内容 -->
			<div class="substance" @click="content_info">
                <span>
                    <span class="contentType" @click="toRoom" v-show="this.$parent.showRoomName">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
						<span>{{data.roomName}}</span>
                    </span>
                    <span style="white-space: pre-wrap">{{data.user.nickName}}的{{data.data.config.formRef}}</span>
                </span>


			</div>

			<div :style="{height:formViewerHeight}" style="overflow: hidden; position: relative;">
				<form-viewer ref="formViewer" v-model="content" :buildData="label"></form-viewer>

				<div class="hide-preCode-box"  v-show="formViewerHeight!='auto'">
					<span class="hide-preCode-bt op sc hand" @click="formViewerHeight = 'auto'" >
						<img class="look-more-preCode contentImg-no-view" src="../../../assets/imgs/other/newCodeMoreWhite.png" alt="" title="" >
					</span>
				</div>

				<div class="hide-preCode-box-show"  v-show="formViewerHeight=='auto'">
					<span class="hide-preCode-bt op sc hand" @click="formViewerHeight = '200px'" >
						<img class="look-more-preCode contentImg-no-view" src="../../../assets/imgs/other/newCodeMoreWhite.png" alt="" title="" style="transform: rotate(180deg);">
					</span>
				</div>
			</div>
            <div>
                <div style="display: flex">
                    <div class="volume" style="margin-right: 20px">{{data.creationTime}}</div>
<!--                    <div class="volume">阅读量:{{data.clickCount}}</div>-->
                </div>
                <div>
                    <DynamicOperation :data="data"></DynamicOperation>
                </div>
            </div>
		</div>
	</div>


</template>

<script>

import formViewer from '../../../components/common/formDesigner/formViewer'
import DynamicOperation from "./DynamicOperation";

export default {
	name:"DiyTemplates",
	components: {
        DynamicOperation,
		formViewer
	},
	computed: {
		user() {
			return this.$store.state.user
		},
		is_login(){
			return this.$store.state.is_login
		},
	},
	data () {
		return {
			//是否展示
			formViewerHeight:"200px",
			content:"",
			label:"",
			show:true,
			edit_show:false,
			showAll: false,
			clickDynamicCount:0
		}
	},
	props:{
		data:{
			type:Object,
			default(){
				return {
					videos:[],
					content:"",
					user:''
				}
			}
		},
	},
	created:function(){

	},
	mounted: function () {
		this.label = JSON.stringify(this.data.data);
		this.content = JSON.stringify(this.data.content);
	},
	methods:{
		// 跳转到Room
		toRoom() {
			this.$router.push({ path: '/room/info/' + this.data.roomId})
		},

		//跳转至详情页
		content_info(){
			this.$router.push({name: 'View', query: {
					id: this.data.menuType,
					type: this.data.type,
					contentId: this.data.id
				}})
		},

		getForwardContent() {
			this.$emit('getForwardContent');
		},

		// 跳转链接
		toLink(url) {
			if (url.indexOf('http') >= 0) {
				window.open(url, "_blank")
			} else {
				window.open('//' + url, "_blank")
			}
		},


		// 点击打开用户信息
		openInfo(uuid, userType) {
      if (!this.utils.toAuth(1)) {
        return false;
      }
      if (userType === 1) {
        this.$router.push({ path: '/circle/mySpace/' + uuid})
      }
      if (userType === 2 || userType === 3) {
        this.$router.push({ path: '/circle/organSpace/' + uuid})
      }
		},

		// 点击展示全部内容
		showAllContent() {
			var that = this;
			this.showAll = ! this.showAll;
			if(that.showAll == true && that.clickDynamicCount == 0){
				that.clickDynamicCount++;
				//eslint-disable-next-line no-unused-vars
				// that.newApi.setDynamicClickCount({id:id}).then((res) => {})
			}
		},

		//下拉菜单触发的方法
		more_action:function(e){
			if (this.is_login == 0) {
				this.utils.err("请先登录");
				return;
			}
			var that = this;
			if (e == "delete") {
				that.$confirm('删除后无法恢复，确认删除吗？')
					.then(_ => {
						console.log(_);
						this.newApi.delRoomContent({id:that.data.id}).then(ret =>{
							if(ret.isSuccess == 1){
								that.utils.sus(ret.data);
								that.show = false;
								this.$EventBus.$emit("changeData");
							}
						})
					})
					.catch(_ => {
						console.log(_);
					});
			}

			if (e == "edit") {
				that.$router.push({name: 'View', query: {
						id: this.data.menuType,
						contentId: this.data.id
					}})
			}
		}

	}
}

</script>

<style scoped>
.Dynamic{
	width: 100%;
	padding: 24px 38px 10px;
	box-sizing: border-box;
	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 5px 5px 5px 5px;
	box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
}
.Dynamic .head{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.Dynamic .head .user{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.Dynamic .head .user .userIco{
	width: 60px;
	height: 60px;
	background-color: #707070 ;
	border-radius: 50%;
	margin-right: 12px;
}
.Dynamic .head .user .major .userName{
	font-weight: 600;
	color: #444444;
	font-size: 16px;
	display: flex;
	justify-content: flex-start;
	align-items:flex-end;
}
.Dynamic .head .user .major .userMajor{
	font-size: 13px;
	color: #999999;
	margin-top: 5px;
	margin-right: 10px;

}
.Dynamic .substance{
	margin-top: 12px;
	line-height: 26px;
	font-size: 15px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.Dynamic .substance .contentType{
	color: #031F88;
	height: 16px;
	font-weight: 600;
	margin-right: 10px;
}
.Dynamic .substance .t{
	color: #555555;
}
.Dynamic .imgs{
	margin-top: 14px;
}
.spacebetween{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.Dynamic .volume{
	height: 30px;
	line-height: 30px;
	color: #999999;
	font-size: 14px;
}
.Dynamic .bottom{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	line-height:50px;
	height: 50px;
	color: #444444;
	font-size: 16px;
	text-align: center;
}
.Dynamic .bottom .btn{
	display: flex;
	justify-content:center;
	align-items: center;
	flex: 1;
}
.Dynamic .content {
	text-overflow:ellipsis;
	-webkit-line-clamp:4;
	-webkit-box-orient:vertical;
	overflow:hidden;
	display:-webkit-box;
	margin-bottom: 12px;
}
.Dynamic .content::before{
	content: '';
	float: right;
	width: 0;
	height: 78px;
}
.Dynamic .contentAll {
	overflow:hidden;
	margin-bottom: 12px;
}
.mr5{
	margin-right: 5px;
}
.hide-preCode-box {
	width: 100%;
	padding-top: 78px;
	background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(255,255,255,0)),to(#fff));
	background-image: linear-gradient(-180deg,rgba(255,255,255,0) 0%,#fff 100%);
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}

.hide-preCode-box-show{
	width: 100%;
	padding-top: 78px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}

.hide-preCode-bt {
	display: block;
	margin: auto;
	width: 44px;
	height: 22px;
	background: #f0f0f5;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	padding-top: 6px;
	cursor: pointer;
}

.look-more-preCode {
	cursor: pointer !important;
	display: block;
	margin: auto;
	width: 22px;
	height: 16px;
}
</style>
