<template>
    <div class="recommend-event">
        <div class="triangle"></div>
        <div class="head">
            <div class="title">Room热门事件</div>
        </div>
        <div style="padding: 20px 24px 24px 24px">
            <div>
                <el-select size="small" v-model="type" @change="getEventList" placeholder="请选择">
                    <el-option
                        v-for="item in eventOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div v-for="(item, index) in eventList" :key="index">
                <div @click="a('/room/info/' + item.roomId)" class="hand op" style="font-size: 14px; color: #2970FF; margin: 20px 0 10px 0">
                    <i class="el-icon-s-home" style="margin-right: 4px"></i>
                    <span>{{item.roomName}}</span>
                </div>
                <div style="display: flex;" class="hand op" @click="toEvent(item)">
                    <div style="width: 70%; margin-right: 10px">
                        <div style="font-weight: 600; text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;">
                            {{item.title}}
                        </div>
                        <div style="font-size: 14px; margin-top: 10px; color: #999999">{{item.endTime}}</div>
                    </div>
                    <div style="width: 28%">
                        <img :src="item.cover" style="width: 74px; height: 44px;">
                        <div style="font-size: 14px; color: #2970FF">查看详情 ></div>
                    </div>
                </div>
                <hr style="border:1px solid #999999;margin-top: 20px;"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"RecommendEvent",
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        }
    },
    data () {
        return {
            page: 1,
            pageSize: 5,
            type: 1,
            eventList: [],
            eventOption: [
                {label: '报名事件', value: 1},
                {label: '付费文档', value: 2},
                {label: '视频直播', value: 3},
                {label: '打卡事件', value: 4}
            ],
        }
    },
    props:{
    },
    created:function(){

    },
    mounted: function () {
      if (this.is_login) {
        this.getEventList(this.type)
      }
    },
    methods:{
      // 进入事件详情
      showDetail(id) {
        this.$router.push({ path: '/room/docDetail/' + id})
      },
        // 进入事件详情
        toEvent(item) {
            if (this.type === 1) {
                this.$router.push({name: 'Event', query: {id: item.id, type: 'sign', roomId: item.roomId,}})
            }
            if (this.type === 2) {
                this.$router.push({path: '/room/docDetail/' + item.id})
            }
        },

        // 更多商务信息
        moreBusiness() {
            this.getBusiness()
        },

        // 跳转详情
        toBusiness(id) {
            this.utils.b('/index/details/' + id)
        },

        // 获取事件
        getEventList(type) {
            this.newApi.getRoomEventList({type: type}).then(res => {
                this.eventList = res.data;
            })
        },
    }
}

</script>

<style scoped>
.recommend-event{
    width: 100%;
    height:auto;
    background-color: #FFFFFF;
    position: relative;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    box-sizing: border-box;
}
.recommend-event .triangle{
    position:absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 14px solid #fff;
    border-top-color: #031F88;
    border-left-color: #031F88;
    border-bottom-color:transparent ;
    border-right-color: transparent;
}
.recommend-event .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px 0 24px;
}
.recommend-event .head .title{
    height: 26px;
    font-size: 21px;
    font-weight: 600;
    color: #031F88;
}
</style>
