<template>
	<div class="ask">
		<div class="triangle"></div>
		<div class="head">
			<div class="title">红包问答</div>
			<div class="more hand op" @click="moreHot">NEXT</div>
		</div>
		<div v-for="(item, index) in hotList" :key="index" class="main hand op" @click="toAskInfo(item.id)">
			<div class="title">{{item.title}}</div>
			<div class="btns">
				<div class="btn">浏览({{item.clickCount}})</div>
				<div class="btn">回答({{item.reviewCount.length}})</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:"AskRight",
		components: {

		},
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            }
        },
		data () {
			return {
				data:{
				},
				page: 1,
				more: 1,
				pageSize: 10,
				hotList: [],
			}
		},
		props:{

        },
		created:function(){

		},
		mounted: function () {
      if (this.is_login) {
        this.getHotQuestion()
      }
		},
		methods:{
			// 跳转红包问答详情
			toAskInfo(id) {
				this.utils.b('/index/page/?id='+id)
			},

			// 更多红包问答
			moreHot() {
				this.getHotQuestion()
			},

			// 获取热门红包问答
			getHotQuestion() {
              let params = {
                  isReward: '',
                  page: this.page,
                  pageSize: this.pageSize,
                  rewardType: 0,
                  roomId: 0,
                  address:'room'
              }
            this.newApi.getRoomQandA(params).then(res => {
                if (res.data.length < 10) {
                    this.hotList = res.data;
                    this.page = 1;
                }
                else {
                    this.hotList = res.data;
                    this.page ++;
                }
            })
			},
		}
	}

</script>

<style scoped>
	.ask{
		width: 100%;
		height:auto;
		background-color: #FFFFFF;
		position: relative;
		margin-bottom: 10px;
		box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
		padding:10px 24px;
		box-sizing: border-box;
	}
	.triangle{
		position:absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		border: 14px solid #fff;
		border-top-color: #031F88;
		border-left-color: #031F88;
		border-bottom-color:transparent ;
		border-right-color: transparent;
	}
	.ask .head{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.ask .head .title{
		height: 26px;
		font-size: 21px;
		font-weight: 600;
		color: #031F88;
	}
	.ask .head .more{
		height: 19px;
		font-size: 16px;
		font-weight: 600;
		color: #FFA500;
	}
	.ask .main{
		margin-top:18px;
	}
	.ask .main .title{
		height: 18px;
		line-height: 18px;
		font-size: 15px;
		font-weight: 600;
		color: #393939;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 6px;
	}
	.ask .main .btns{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.ask .main .btns .btn{
		height: 16px;
		font-size: 13px;
		font-weight: 600;
		color: #2970FF;
		margin-right: 14px;
	}
</style>
