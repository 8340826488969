<template>
    <div class="event-list">
        <ActiveCard v-if="enrollList.length > 0" :data="enrollList[0]" :roomId="parseInt(roomId)" type="home"></ActiveCard>
        <div class="event-body" v-if="videoList.length > 0">
            <div class="event-title video">
                <span style="margin-left: 30px">视频直播</span>
                <span style="color: #FFFFFF; float: right; font-size: 14px; margin-right: 10px">更多<i class="el-icon-arrow-right"></i></span>
            </div>
            <div class="event-content">
                <div>
                    <div style="font-size: 18px; font-weight: 600">线上报名参加活动</div>
                    <div style="color: #2970FF">2022/6/18  PM18:30</div>
                    <div><span>活动地点： </span> <span style="font-weight: 600">线上</span></div>
                    <div><span>热度： </span> <span style="font-weight: 600">889人将参加</span></div>
                </div>
                <div>
                    <img src="" class="image-ico">
                    <div class="hand op image-btn">点击报名</div>
                </div>
            </div>
        </div>

        <div class="event-body" v-if="clockList.length > 0">
            <div class="event-title punch">
                <span style="margin-left: 30px">打卡事件</span>
                <span style="color: #FFFFFF; float: right; font-size: 14px; margin-right: 10px">更多<i class="el-icon-arrow-right"></i></span>
            </div>
            <div class="event-content">
                <div>
                    <div style="font-size: 18px; font-weight: 600">线上报名参加活动</div>
                    <div style="color: #2970FF">2022/6/18  PM18:30</div>
                    <div><span>打卡地点： </span> <span style="font-weight: 600">线上</span></div>
                </div>
                <div>
                    <img src="" class="image-ico">
                    <div class="hand op image-btn">点击报名</div>
                </div>
            </div>
        </div>

        <DocCard v-if="docList.length>0" :data="docList[0]"></DocCard>

        <div class="event-body" v-if="dailyList.length > 0">
            <div class="event-title" style="background-color: #FF9800">
                <i class="el-icon-message-solid" style="margin-right: 6px; line-height: 40px"></i>
                日程提醒
            </div>
            <div class="event-info">
                <div style="margin-bottom: 10px; color: #F3425F">{{dailyList[0].date}}</div>
                <div>
                    {{dailyList[0].content}}
                </div>
            </div>
        </div>

        <div class="event-body" v-if="manyList.length > 0">
            <div class="event-title" style="background-color: #FD764C">
                <i class="el-icon-message-solid" style="margin-right: 6px; line-height: 40px"></i>
                多人预约日程
            </div>
            <div class="event-info">
                <div style="margin-bottom: 10px; color: #F3425F">2022-02-21  AM10：30</div>
                <div style="margin-bottom: 10px">
                    备注：xxxxxxxxxxxxxxxxxxx
                    xxxxxxxxxxxxxxxxxxxxxxxx
                    xxxxxxxxxxxxxxxxxxxxxxxx
                    xxxxxxxxxxxxxxxxxxxxxxxx
                    xxxxxxxxxxxx
                </div>
                <div>
                    <div style="color: #999999; margin-bottom: 10px">好友：</div>
                    <div style="display: flex; flex: content">
                        <img src="" style="width: 38px; height: 38px; border-radius: 50%; margin-right: 8px" v-for="item in 6" :key="item">
                    </div>
                </div>
            </div>
        </div>

        <div class="event-body" v-if="payList.length > 0">
            <div class="event-title pay">
                <span style="margin-left: 30px">支付事件</span>
                <span style="color: #FFFFFF; float: right; font-size: 14px; margin-right: 10px">更多<i class="el-icon-arrow-right"></i></span>
            </div>
            <div class="event-content">
                <div>
                    <div style="font-size: 18px; font-weight: 600">线上审批支付</div>
                    <div style="color: #2970FF">2022/6/18  PM18:30</div>
                    <div><span>支付金额： </span> <span style="font-weight: 600">999元</span></div>
                </div>
                <div>
                    <div class="hand op image-btn">点击查看</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import ActiveCard from "./event/ActiveCard";
    import DocCard from "./event/DocCard";
    export default {
        name: "EventList",
        components: {ActiveCard,DocCard},
        data() {
            return {
                enrollList: [],
                docList: [],
                videoList: [],
                clockList: [],
                dailyList: [],
                manyList: [],
                payList: [],
            }
        },
        props: {
            roomId: {
                type: Number,
                default() {
                    return 0
                }
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },

        watch: {
            roomId: {
                handler() {
                    this.getEnrollList();
                    this.getDocList()
                }
            }
        },

        mounted() {
            var that = this;
            this.getEnrollList();
            this.getDocList();
            this.getRoomCalendar();
            this.$EventBus.$EventBus.$off("reshEvent");
            this.$EventBus.$on("reshEvent", (data) => {
                this.$nextTick(() => {
                    that.getEnrollList();
                });
            });
        },

        methods: {
            // 获取报名事件列表
            getEnrollList() {
                this.newApi.getEnrollEventList({roomId: this.roomId, status: 2, type: 0}).then(res =>{
                    this.enrollList = res.data
                })
            },

            // 付费文档
            getDocList(){
              this.newApi.getDocList({
                roomId:this.roomId,
                pageSize: 1
              }).then(res =>{
                this.docList = res.data
              })
            },

          // 日程提醒
          getRoomCalendar(){
            let date = new Date();
            this.newApi.getCalendarEventMoreList({type: 2, date: date.getFullYear()+'-'+(date.getMonth() + 1)+'-'+ '01'+ ' 00:00:00', roomId: this.roomId}).then(res => {
              this.dailyList = res.data;
            })
          },

            // 进入事件详情
            toEvent(type, id) {
                this.$router.push(
                    {
                        name: 'Event',
                        query: {
                            id: id,
                            type: type,
                            roomId: this.roomId,
                        }
                    }
                )
            }
        }
    }
</script>

<style scoped>
    .event-list {
        width: 100%;
    }

    .event-list .event-body {
        margin-bottom: 10px;
    }

    .event-list .event-body .event-title {
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px 5px 0 0;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
    }

    .event-list .event-body .active {
        background-image: url("../imgs/title1.png");
        background-color: #FFFFFF;
    }

    .event-list .event-body .video {
        background-image: url("../imgs/title2.png");
    }

    .event-list .event-body .make {
        background-image: url("../imgs/title6.png");
    }

    .event-list .event-body .punch {
        background-image: url("../imgs/title4.png");
    }

    .event-list .event-body .money {
        background-image: url("../imgs/title5.png");
    }

    .event-list .event-body .pay {
        background-image: url("../imgs/title3.png");
    }

    .event-list .event-body .event-content {
        min-height: 120px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-list .event-body .event-info {
        height: 120px;
        padding: 20px;
        line-height: 20px;
        overflow: auto;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-list .event-body .event-info::-webkit-scrollbar {
      width: 0;
    }

    .event-list .event-body .event-content .image-ico {
        width: 84px;
        height: 66px;
        margin-top: 10px;
    }

    .event-list .event-body .event-content .image-btn {
        width: 88px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-weight: 600;
        border-radius: 5px;
        background-color: #E4E6EB;
        color: #2970FF;
    }
</style>
