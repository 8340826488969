<template>
    <div style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;" class="work">
        <div style="width: 1500px;margin:0 auto;overflow: hidden;margin-top:108px;">
            <div class="page-content" style="width:1206px;height: auto;float: left;margin: 0 10px 0 294px;min-height: 1000px;">
                <div style="width: 100%; overflow: hidden; background-color: white; border-radius: 5px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
                    <div class="tab border_bottom up" v-for="(item, index) in personalMenu" :key="index" @click="activeName = item.key">
                        <label>
                            <div class="head_white hand">
                                <div class="head_white_word" @click="clickNavigation(item.key)">{{item.value}}
                                    <el-badge :value="messageNum.myExamineStartNum" style="float: right; width: 20px; height: 20px" v-if="messageNum.myExamineStartNum > 0 && item.key === 0"></el-badge>
                                    <el-badge :value="messageNum.joinExamineStartNum" style="float: right; width: 20px; height: 20px" v-if="messageNum.joinExamineStartNum > 0 && item.key === 1"></el-badge>
                                </div>
                                <div class="head_white_line"  v-if="activeName === item.key"></div>
                            </div>
                        </label>
                    </div>
                    <div class="tab-search">
                        <el-input placeholder="请输入搜索内容" size="small" v-model="input3">
                            <el-button slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </div>
                </div>
                <div v-if="contentList.length > 0">
                    <div style="max-width: 1296px;margin: 10px auto; background: #FFFFFF;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);" v-for="(item, index) in contentList" :key="index">
                        <div style="display: flex; justify-content: space-between; border: 1px #D9DADB solid; padding: 12px 10px 0 10px">
                          <div style="width: 78%;">
                            <div style="width: 100%;text-align: center; font-weight: 600; font-size: 20px; margin-bottom: 10px">
                              {{item.title}}
                            </div>
                          </div>
                          <div style="width: 40%; display: flex; justify-content: end">
                            <div v-if="item.roomId" @click="a('/room/info/' + item.roomId)" class="hand op" style="color: #031F88; margin-right: 10px; font-size: 14px">来自：{{item.roomName}}</div>
                            <div class="timeline-btn hand op" @click="editWork(item)" style="background-color: #FF9800; margin-right: 10px" v-if="item.step === 0 && item.status === 0 && user.uuid === item.userInfo.uuid">编辑</div>
                            <div class="timeline-btn hand op" @click="deleteWork(item)" style="background-color: red; margin-right: 10px" v-if="item.step === 0 && item.status === 0 && user.uuid === item.userInfo.uuid">删除</div>
                            <div class="timeline-btn hand op" style="background-color: #2970FF" @click="item.show = !item.show" v-if="item.show === false"><i class="el-icon-caret-right"></i>展开</div>
                            <div class="timeline-btn hand op" style="background-color: #2970FF" @click="item.show = !item.show" v-if="item.show === true"><i class="el-icon-caret-bottom"></i>收起</div>
                          </div>
                        </div>
                        <div style="width: 100%; overflow: hidden; position: relative" :style="item.show === false ? 'max-height: 200px' : ''">
                          <div style="width: 100%; display: flex;">
                            <div style="width: 78%; border-right: 1px #D9DADB solid">
                              <div class="work-title">
                                <div class="work-icon"></div>
                                <div>审批详情</div>
                              </div>
                              <div style="padding: 27px;">
                                <div v-if="item.type == 1" v-html="item.content" style="white-space: pre-wrap;" class="page-img"></div>
                                <div v-if="item.type == 2">
                                  <form-viewer ref="formViewer" v-model="JSON.parse(item.content).content" :buildData="JSON.parse(item.content).json"></form-viewer>
                                </div>
                              </div>
                            </div>

                            <div style="width: 22%; border-left: 1px #D9DADB solid">
                              <div>
                                <div class="work-title">
                                  <div class="work-icon"></div>
                                  <div>审批进程</div>
                                </div>

                                <div>
                                  <el-timeline style="padding: 10px 12px 0 12px; margin-top: 10px" >
                                    <el-timeline-item color="#2970FF" size="large">
                                      <div>
                                        <span class="timeline-title">发起审批</span>
                                        <!--                                                <span class="timeline-btn" style="background-color: #2970FF">成功</span>-->
                                      </div>

                                      <div style="font-size: 13px;">
                                        <div style="color: #808080;line-height: 22px;">{{item.creatTime}}</div>
                                        <div style="color: #808080;line-height: 20px;">
                                          发起人：{{item.userInfo.nickName}}
                                        </div>
                                      </div>
                                    </el-timeline-item>
                                    <el-timeline-item v-for="(items, indexs) in item.reviewer" :key="indexs" color="#2970FF" size="large">
                                      <div>
                                        <span class="timeline-title">{{indexs + 1}}级审批人</span>
                                        <span class="timeline-btn" style="background-color: #C8C8C8" v-if="items.status === 0">未开始</span>
                                        <span class="timeline-btn" style="background-color: #FF9800" v-if="items.status === 1">处理中</span>
                                        <span class="timeline-btn" style="background-color: #69BF29" v-if="items.status === 2">通过</span>
                                        <span class="timeline-btn" style="background-color: #DD0D04" v-if="items.status === 3">驳回</span>
                                      </div>

                                      <div style="font-size: 13px;">
                                        <div style="color: #808080;line-height: 20px;">
                                          审批人： {{items.userInfo.nickName}}
                                        </div>
                                        <div style="color: #808080;line-height: 20px; margin-bottom: 10px" v-for="(i, k) in items.content" :key="k">
                                          <div>处理时间：{{utils.changeTimeType(i.time)}}</div>
                                          <div>审批意见： {{i.content}}</div>
                                        </div>
                                      </div>
                                    </el-timeline-item>
                                  </el-timeline>
                                </div>
                              </div>

                              <div style="border-top: 2px #D9DADB solid" v-if="activeName === 0 || activeName === 3">
                                <div style="padding: 20px 20px 10px 20px; display: flex; justify-content: center">
                                  <div v-if="item.step === 0 && item.status === 0" @click="nextClick(item.id)" class="opt-btn hand op" style="background-color: #69BF29; color: #FFFFFF"><i class="el-icon-check" style="font-weight: 600"></i> 下一步</div>
                                  <div v-else-if="item.step === 0 && item.status === 1" @click="nextClick(item.id)" class="opt-btn hand op" style="background-color: #69BF29; color: #FFFFFF">归档</div>
                                  <div v-else-if="item.step === 1 && item.status === 1" class="opt-btn" style="background-color: #69BF29; color: #FFFFFF">已完成</div>
                                  <div v-else class="opt-btn hand op" style="background-color: #C8C8C8; color: #FFFFFF; cursor:not-allowed"><i class="el-icon-check" style="font-weight: 600"></i> 下一步</div>

                                </div>
                              </div>

                              <div v-for="(items, indexs) in item.reviewer" :key="indexs">
                                <div v-if="activeName === 1 && user.uuid === items.userInfo.uuid">
                                  <div style="border-bottom: 2px #D9DADB solid; border-top: 2px #D9DADB solid">
                                    <div class="work-title">
                                      <div class="work-icon"></div>
                                      <div>审批意见</div>
                                    </div>

                                    <div style="padding: 20px 20px 10px 20px">
                                      <el-input
                                          type="textarea"
                                          :rows="13"
                                          maxlength="300"
                                          show-word-limit
                                          placeholder="添加审批意见"
                                          v-model="items.newContent">
                                      </el-input>
                                      <!--                                        <div style="display: flex; justify-content: end; margin-top: 10px">-->
                                      <!--                                            <span class="timeline-btn hand op" style="background-color: #2970FF; height: 26px; line-height: 26px">添加</span>-->
                                      <!--                                        </div>-->
                                    </div>
                                  </div>

                                  <div style="padding: 20px 20px 10px 20px; display: flex" v-if="activeName === 1 && user.uuid === items.userInfo.uuid">
                                    <div class="opt-btn hand op" @click="operationWork(item.id, '3', items.newContent)" style="border: 2px #DD0D04 solid; color: #DD0D04; margin-right: 20px; height: 26px; line-height: 24px"><i class="el-icon-close" style="font-weight: 600"></i> 驳回</div>
                                    <div class="opt-btn hand op" @click="operationWork(item.id, '2', items.newContent)" style="background-color: #69BF29; color: #FFFFFF"><i class="el-icon-check" style="font-weight: 600"></i> 通过</div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div :class="item.show === false ? 'hide-preCode-box' : ''"></div>

                        </div>
                    </div>
                </div>
                <div style="max-width: 1296px; min-height: 100px; margin: 10px auto; background: #FFFFFF;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);" v-else>暂无审批流...</div>
            </div>
        </div>

        <div class="work-dialog">
            <el-dialog :visible.sync="showEdit" width="52%" :show-close="false">
                <div class="top">
                    编辑文档
                </div>
                <div style="padding: 20px 40px">
                    <div style="margin-bottom: 40px">
                        <div class="work-title" style="color: #444444; margin-bottom: 10px">文档详情</div>
                        <div style="margin-bottom: 20px">
                            <Editor v-if="showEdit && reserve.type !== 2" :tinymce_height="'600'" id="1" :innerDrawer="innerDrawer = true" :value="reserve.content" ref="child"></Editor>
                            <form-builder v-if="reserve.type === 2" ref="formBuilder" v-model="JSON.parse(reserve.content).content" :buildData="JSON.parse(reserve.content).json"></form-builder>
                        </div>
                    </div>

                    <div slot="footer" style="text-align: end">
                        <el-button @click="unEditWork" size="small">取 消</el-button>
                        <el-button type="primary" @click="editWorkInfo" size="small">确定</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Head from "../../components/common/Head";
import RoomNav from './components/RoomNav'
import FormViewer from "../../components/common/formDesigner/formViewer";
import FormBuilder from "../../components/common/formDesigner/formBuilder";
import Editor from "@/components/common/Editor.vue";

export default {
    name: 'Work',
    components: {
        Editor,
        FormBuilder,
        FormViewer,
        Head,
        RoomNav,
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },
    data() {
        return {
            page:1,
            more:1,
            pageSize: 10,
            input3:"",
            loading: false,

            personalMenu: [
                {
                    key: 0,
                    value: '我的审批流'
                },
                {
                    key: 1,
                    value: '待审核'
                },
                {
                    key: 2,
                    value: '已处理'
                },
                {
                    key: 3,
                    value: '已完成'
                }
            ],

            activeName: 0,
            contentList: [],
            content: '',
            showEdit: false,
            reserve: {
                id: '',
                content: '',
            },

            messageNum: {},
        }
    },
    mounted:function(){
        var that = this;
        // this.id = this.$route.query.id;
        this.backTop();
        that.getWorkFlowList();
        that.getUnReadMessage();

        window.onscroll = function(){
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;

            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
             if(scrollHeight - scrollTop - windowHeight <= 0){
                if (that.more == 1 && that.loading === false) {
                    if (that.activeName === 1) {
                        that.getWorkFlowList();
                    }
                    if (that.activeName === 2) {
                        that.getWorkJoinList();
                    }
                }else{
                    console.log("已加载完");
                }
            }
        }

    },
    directives: {
        drag(el, bindings) {
            el.onmousedown = function(e) {
                var disx = e.pageX - el.offsetLeft;
                var disy = e.pageY - el.offsetTop;
                document.onmousemove = function (e) {
                    el.style.left = e.pageX - disx + 'px';
                    el.style.top = e.pageY - disy + 'px'
                };
                document.onmouseup = function() {
                    document.onmousemove = document.onmouseup = null
                }
            }
        }
    },
    methods:{

        backTop () {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
        },

        // 获取未读消息
        getUnReadMessage() {
            this.newApi.getRoomNotice().then(res => {
                this.messageNum = res.data
            })
        },

        // 编辑审批流
        editWork(item) {
            this.showEdit = true;
            this.reserve.type = item.type;
            this.reserve.content = item.content;
            this.reserve.id = item.id
        },

        // 删除审批流
        deleteWork(item) {
          let that = this;
          this.utils.confirm('确定删除此审批流？', function () {
              that.newApi.delExamine({id: item.id}).then((res) => {
                  if (res.isSuccess === 1) {
                    that.page = 1;
                    that.more = 1;
                    that.contentList = [];
                    that.utils.sus(res.data);
                    that.getWorkFlowList();
                    that.getUnReadMessage();
                    that.$EventBus.$emit("changeMessage")
                  }
              })
          })
        },

        // 取消修改审批流
        unEditWork() {
            this.showEdit = false;
            this.reserve = {
                id: '',
                content: '',
            }
        },

        // 确定修改审批流
        editWorkInfo() {
            var params = {};
            params.id = this.reserve.id;
            if (this.reserve.type == 1) {
                params.content = this.$refs.child.get_content();
            }
            if (this.reserve.type == 2) {
                params.content = JSON.stringify({content: this.$refs.formBuilder.getContent(), json: JSON.parse(this.reserve.content).json})
            }
            this.newApi.editExamine(params).then(res => {
                if (res.isSuccess === 1) {
                    this.page = 1;
                    this.more = 1;
                    this.contentList = [];
                    this.showEdit = false;
                    this.reserve = {
                        id: '',
                        content: '',
                    }
                    this.utils.sus(res.data);
                    this.getWorkFlowList();
                    this.getUnReadMessage();
                    this.$EventBus.$emit("changeMessage")
                }
            })
        },

        // 提交审批操作
        operationWork(id, type, content) {
            if (!content) {
                this.utils.err('请填写审批意见');
                return
            }
            this.newApi.examineSubmitResults({id: id, content: content, status: type}).then(res => {
                if (res.isSuccess === 1) {
                    this.page = 1;
                    this.more = 1;
                    this.contentList = [];
                    this.utils.sus(res.data);
                    this.getWorkFlowList();
                    this.getUnReadMessage();
                    this.$EventBus.$emit("changeMessage")
                }
            })
        },

        // 点击下一步
        nextClick(id) {
            this.newApi.examineNext({id: id}).then(res => {
                if (res.isSuccess === 1) {
                    this.page = 1;
                    this.more = 1;
                    this.contentList = [];
                    this.utils.sus(res.data);
                    this.getWorkFlowList();
                    this.getUnReadMessage();
                    this.$EventBus.$emit("changeMessage")
                }
            })
        },

        clickNavigation(item) {
            this.page = 1;
            this.more = 1;
            this.contentList = [];
            this.activeName = item;
            this.getWorkFlowList();
        },

        // 获取工作流
        getWorkFlowList() {
            this.loading = true
            this.newApi.getMyExamine({status: this.activeName, page: this.page, pageSize: this.pageSize}).then(res => {
                for (var i = 0; i < res.data.length; i++) {
                    res.data[i].show = false;
                    this.contentList.push(res.data[i]);
                }
                if (res.data.length < 10) {
                    this.more = 0;
                }
                this.page++;
                this.loading = false
            })
        },

        // // 获取我参与的工作流
        // getWorkJoinList() {
        //     this.newApi.getMyExamine({status: 1, page: this.page, pageSize: this.pageSize}).then(res => {
        //         for (var i = 0; i < res.data.length; i++) {
        //             for (const item of res.data[i].reviewer) {
        //                 item.newContent = ''
        //             }
        //             this.contentList.push(res.data[i]);
        //         }
        //         if (res.data.length < 10) {
        //             this.more = 0;
        //         }
        //         this.page++;
        //     })
        // },
    }
}
</script>

<style scoped>
    .work-title {
        display: flex;
        line-height: 20px;
        padding-top: 20px;
        font-size: 18px;
        font-weight: 600;
        color: #031F88;
    }

    .work-title .work-icon {
        width: 6px;
        height: 20px;
        background-color: #031F88;
        margin-right: 14px;
    }

    .timeline-title {
        font-size: 14px;
        font-weight: 600;
        margin-right: 14px;
        margin-bottom: 14px
    }

    .timeline-btn {
        display: inline-block;
        width: 42px;
        height: 18px;
        font-size: 13px;
        text-align: center;
        border-radius: 3px;
        margin-bottom: 10px;
        color: #FFFFFF;
        padding: 1px 2px;
    }

    .opt-btn {
        width: 94px;
        height: 30px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        border-radius: 5px;
        margin-bottom: 10px;
        color: #DD0D04;
        line-height: 28px;
    }

    .page-img >>> img{
        width: 100%;
    }

    .work .tab{
        text-align: center;
        margin: auto 0;
        float: left;
        cursor:pointer;
    }

    .work .tab-search {
        width: 30%;
        float: right;
        margin: 5px 2%;
        cursor:pointer;
    }

    .work .up{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.1s;
    }

    .work .head_white{
        height: 22px;
        padding: 8px 0;
        width: 160px
    }
    .work .head_white_word{
        width: 114px;
        height: 22px;
        text-align: center;
        line-height:20px;
        color: black;
        font-size: 16px;
        font-weight: 600;
        margin-left: 43px;
        float: left;
    }
    .work .head_white_line{
        width: 80px;
        margin-left: 60px;
        height: 6px;
        background-color: #031F88;
        border-radius: 5px;
        position: relative;
        margin-top: 8px;
        float: left;
    }

    .work .top {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 50px;
        box-sizing: border-box;
        line-height: 50px;
    }

    .work .hide-preCode-box {
      width: 100%;
      padding-top: 200px;
      background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(255,255,255,0)),to(#fff));
      background-image: linear-gradient(-180deg,rgba(255,255,255,0) 0%,#fff 100%);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .work>>>img {
        max-width: 100%;
    }

    /deep/ .work-dialog .el-dialog__header {
        padding: 0;
    }

    /deep/ .work-dialog .el-dialog__body {
        padding: 0;
    }

    .el-input-group__append{
        background-color: #2970FF;
        color: #ffffff;
    }
    .el-input__inner{
        height: 45px;
    }
</style>
