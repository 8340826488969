<template>
	<div class="Article" v-if="show">

		<div class="head">
			<div class="user" @click="openInfo(data.user.uuid, data.user.userType)">
				<img :src="data.user.headIco + '-200x200'" class="userIco" >
				<div class="major">
					<div class="userName">
						<div>{{data.user.nickName}}</div>
                        <img v-show="data.user.isVip == 1" src="../imgs/newVip.png" style="width: 24px;height: 20px;margin-left: 10px;"/>
                        <img v-show="data.user.v == 1" src="../imgs/newV.png" style="width: 20px;height: 20px;margin-left: 10px;"/>
                        <img v-show="data.user.authenticationStatus >= 2" src="../imgs/newP.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                        <img v-show="data.user.expertAuthStatus === 2" src="../imgs/newS.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
					</div>
					<div class="userMajor"><span v-if="data.user.address.cityName">{{data.user.address.cityName[1]}}</span><span v-if="data.user.major && data.user.address.cityName"> | </span>{{data.user.major}}</div>
				</div>
			</div>

			<div class="action" v-if="me.uuid == data.user.uuid">
				<el-dropdown @command="more_action">
					<span class="el-dropdown-link">
						<i class="el-icon-caret-bottom" style="font-size: 24px"></i>
					</span>
					<el-dropdown-menu slot="dropdown">

						<el-dropdown-item v-if="me.uuid == data.user.uuid" command="edit">编辑</el-dropdown-item>

<!--						<el-dropdown-item v-if="me.uuid == data.user.uuid" command="weChart">微信分享</el-dropdown-item>-->

<!--						<el-dropdown-item command="share">转移到其他Room</el-dropdown-item>-->

<!--						<el-dropdown-item command="share">IM给好友</el-dropdown-item>-->

<!--						<el-dropdown-item command="circle">转发朋友圈</el-dropdown-item>-->

						<el-dropdown-item v-if="me.uuid == data.user.uuid"  command="delete" >删除</el-dropdown-item>

					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>

		<!-- 标题 -->
		<div class="title" @click="content_info">

			<span class="contentType" @click="toRoom" v-show="this.$parent.showRoomName">
				<i class="el-icon-s-home" style="margin-right: 5px;"></i>{{data.roomName}}
			</span>
			<span class="t" v-html="data.content.title"></span>
		</div>

		<!-- 内容 -->
		<div class="main" @click="content_info">
			<img :src="data.content.cover +'-200x200'" class="thumb">
			<div class="content" style="overflow: hidden">
				<span>{{data.content.content}}</span>
			</div>
		</div>

        <div>
            <div style="display: flex">
                <div class="volume" style="margin-right: 20px">{{data.creationTime}}</div>
<!--                <div class="volume">阅读量:{{data.clickCount}}</div>-->
            </div>
            <div>
                <DynamicOperation :data="data"></DynamicOperation>
            </div>
        </div>

		<!--转发-->
		<el-dialog :visible.sync="showTran" class="event-form">
			<div style="padding: 40px 40px;">
				<Transpond :data="data" :url="'/room/articleInfo/' + data.id"></Transpond>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Transpond from "./Transpond";
    import DynamicOperation from "./DynamicOperation";
	export default {
		name:"Article",
		components: {
            DynamicOperation,
			Transpond
		},
        computed: {

            me() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
		data () {
			return {
				//是否展示
				show:true,

				showTran: false,
			}
		},
		props:{
			data:{
				type:Object,
				default(){
                    return {
                        videos:[],
                        content:"",
                    }
                }
			},

			//是否展示来自那个room
			showRoom:{
				type:Boolean,
				default(){
                    return true
                }
			}
        },
		created:function(){

		},
		mounted: function () {

		},
		methods:{
			// 关闭转发
			closeShow() {
				this.showTran = false
			},

			// 跳转到Room
			toRoom() {
				this.$router.push({ path: '/room/info/' + this.data.roomId})
			},

			getForwardContent() {
				this.$emit('getForwardContent');
			},

      // 点击打开用户信息
      openInfo(uuid, userType) {
        var that = this;
        if (!this.utils.toAuth(1)) {
          return false;
        }
        if (userType === 1) {
          this.$router.push({ path: '/circle/mySpace/' + uuid})
        }
        if (userType === 2 || userType === 3) {
          this.$router.push({ path: '/circle/organSpace/' + uuid})
        }
      },

			//跳转至详情页
			content_info(){
				this.utils.b('/room/articleInfo/' + this.data.id)
			},
			//下拉菜单触发的方法
			more_action:function(e){
				if (this.is_login == 0) {
					this.err("请先登录");
					return;
				}
				var that = this;
				if (e == "collection_user") {
					this.collection_user();
				}
				if (e == "delete") {
					that.$confirm('删除后无法恢复，确认删除吗？')
					.then(_ => {
						console.log(_);
				this.newApi.delRoomContent({id:that.data.id}).then(ret =>{
				  if(ret.isSuccess == 1){
					that.utils.sus(ret.data);
					that.show = false;
					this.$EventBus.$emit("changeData");
				  }
				})
					})
					.catch(_ => {
						console.log(_);
					});
				}

				if (e == "edit") {
					// 68为知识仓库菜单
					if(this.data.menuType === 68){
						this.utils.a('/knowledge/KwArticle/'+ this.data.content.kwId);
					}else{
						this.$router.push({name: 'Acticle', query: {id: this.data.id, menuId: this.menuId}})
					}

				}

				if (e == 'circle') {
					that.showTran = true
				}
			}

		}
	}

</script>

<style scoped>
	.Article{
		width: 100%;
		padding: 24px 38px 10px;
		box-sizing: border-box;
		margin-bottom: 10px;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;
		box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
		cursor: pointer;
	}
	.Article .head{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.Article .head .user{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.Article .head .user .userIco{
		width: 60px;
		height: 60px;
		background-color: #707070 ;
		border-radius: 50%;
		margin-right: 12px;
	}
	.Article .head .user .major .userName{
		font-weight: 600;
		color: #444444;
		font-size: 16px;
		display: flex;
		justify-content: flex-start;
		align-items:flex-end;
	}
	.Article .head .user .major .userMajor{
		font-size: 13px;
		color: #999999;
		margin-top: 5px;

	}
	.Article .title{
		margin-top: 6px;
		line-height: 26px;
		font-size: 15px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		-webkit-line-clamp: 6;
	}
	.Article .title .contentType{
		color: #031F88;
		height: 16px;
		font-weight: 600;
		margin-right: 10px;
	}
	.Article .title .t{
		color: #555555;
	}
	
	.Article .main{
		margin-top: 6px;
		height: 200px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.Article .main .thumb{
		width: 200px;
		height: 200px;
	}
	.Article .main .content{
		flex: 1;
		height: 200px;
		box-sizing: border-box;
		padding: 16px 22px;
		border: 1px solid #BDBFC1;
		border-left: none;
		display: flex;
		align-items: center;
		color: #444444;
		font-size: 16px;
		letter-spacing: 1px;
	}
	.spacebetween{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.Article .volume{
		height: 30px;
		line-height: 30px;
		color: #999999;
		font-size: 14px;
	}
	.Article .bottom{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		line-height:50px;
		height: 50px;
		color: #444444;
		font-size: 16px;
		text-align: center;
	}
	.Article .bottom .btn{
		display: flex;
		justify-content:center;
		align-items: center;
		flex: 1;
	}
	.mr5{
		margin-right: 5px;
	}
</style>
