<template>
	<div>
		<div class="VideoContent" v-if="show">
			<div class="head">
				<div class="user hand op" @click="openInfo(data.user.uuid, data.user.userType)">
					<img :src="data.user.headIco + '-200x200'" class="userIco" >
					<div class="major">
						<div class="userName">
							<div>{{data.user.nickName}}</div>
                            <img v-show="data.user.isVip == 1" src="../imgs/newVip.png" style="width: 24px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.v == 1" src="../imgs/newV.png" style="width: 20px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.authenticationStatus >= 2" src="../imgs/newP.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.expertAuthStatus === 2" src="../imgs/newS.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
						</div>
						<div class="userMajor"><span v-if="data.user.address.cityName">{{data.user.address.cityName[1]}}</span><span v-if="data.user.major && data.user.address.cityName"> | </span>{{data.user.major}}</div>
					</div>
				</div>

				<div class="action hand" v-if="user.uuid == data.user.uuid">
					<el-dropdown @command="more_action">
					<span class="el-dropdown-link">
						<i class="el-icon-caret-bottom" style="font-size: 24px"></i>
					</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-if="user.uuid == data.user.uuid" command="edit">编辑</el-dropdown-item>

<!--							<el-dropdown-item v-if="user.uuid == data.user.uuid" command="weChart">微信分享</el-dropdown-item>-->

<!--							<el-dropdown-item command="share">转移到其他Room</el-dropdown-item>-->

<!--							<el-dropdown-item command="share">IM给好友</el-dropdown-item>-->

<!--							<el-dropdown-item command="circle">转发朋友圈</el-dropdown-item>-->

							<el-dropdown-item v-if="user.uuid == data.user.uuid"  command="delete" >删除</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>

			<!-- 内容 -->
			<div class="substance">
                <span v-if="data.content.content.length > 200" :class="showAll === true ? 'contentAll' : 'content'" style="width: 100%;">
                    <span class="contentType" @click="toRoom" v-show="this.$parent.showRoomName">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
						<span>{{data.roomName}}</span>
                    </span>
                    <span v-if="showAll === false" class="hand op sc" @click="showAllContent" style="float: right; clear: both; color: #2970FF">查看全部</span>
                        <div @click="content_info" v-html="data.content.content" class="html hand op"></div>
                    <span v-if="showAll === true" class="hand op sc" @click="showAllContent" style="color: #2970FF;">收起</span>
                </span>
				<span v-else>
                    <span class="contentType" @click="toRoom" v-show="this.$parent.showRoomName">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
						<span>{{data.roomName}}</span>
                    </span>

                    <div @click="content_info" v-html="data.content.content" class="html hand op"></div>
                </span>
			</div>
			<div class="video" @click="setClickCount(data.id)">
				<video-player  class="video-player vjs-custom-skin" id="videoPlayer" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
			</div>

			<!-- 按钮 -->
			<div>
                <div style="display: flex">
                    <div class="volume" style="margin-right: 20px">{{data.creationTime}}</div>
<!--                    <div class="volume">阅读量:{{data.clickCount}}</div>-->
                </div>
                <div>
                    <DynamicOperation :data="data"></DynamicOperation>
                </div>
			</div>
		</div>

		<!--转发-->
		<el-dialog :visible.sync="showTran" class="event-form">
			<div style="padding: 40px 40px;">
				<Transpond :data="data" :url="'/room/imageInfo/' + data.id"></Transpond>
			</div>
		</el-dialog>

		<!--编辑知识仓库视频格式-->
		<el-dialog  width="482px" :visible.sync="editOpenKw" :show-close=false class="upload_att">
			<addUploadVideo @custom-event="closeViodeModule" :menuId=this.kwVideo.menuId :edit-id="this.kwVideo.content.kwId"></addUploadVideo>
		</el-dialog>

		<!--编辑菜单类型视频菜单-->
		<el-dialog  width="482px" :visible.sync="editOpenMenu" :show-close=false class="upload_att">
			<menuVideo @custom-event="closeViodeModule" :menuId=this.kwVideo.menuId :edit-id="this.kwVideo.id"></menuVideo>
		</el-dialog>

	</div>
</template>

<script>
	import Transpond from "./Transpond";
    import DynamicOperation from "./DynamicOperation";
    import menuVideo from "./menuModule/menuVideo";
    import addUploadVideo from "../knowledge/components/addUploadVideo";
	export default {
		name:"VideoContent",
		components: {
            DynamicOperation,
			Transpond,
			menuVideo,
			addUploadVideo
		},
		computed: {
			user() {
				return this.$store.state.user
			},
			is_login(){
				return this.$store.state.is_login
			},
		},
		data () {
			return {
				showTran: false,
				//是否展示
				show:true,
				showAll: false,
				playerOptions: {
					playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
					autoplay: false, // 如果为true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 是否视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "video/mp4", // 类型
						src: this.data.content.videos[0].url // url地址
					}],
					poster: this.data.content.videos[0].img_url, // 封面地址
					notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true, // 当前时间和持续时间的分隔符
						durationDisplay: true, // 显示持续时间
						remainingTimeDisplay: false, // 是否显示剩余时间功能
						fullscreenToggle: true // 是否显示全屏按钮
					}
				},
				clickViodeCount:0,
				editOpenKw:false,
				editOpenMenu:false,
				kwVideo:this.data
			}
		},
		props:{
			data:{
				type:Object,
				default(){
					return {
						videos:[],
						content:"",
					}
				}
			},
			//是否展示来自那个room
			showRoom:{
				type:Boolean,
				default(){
					return true
				}
			}
		},
		created:function(){

		},
		mounted: function () {
		},
		methods:{
			//关闭编辑视频组件
			closeViodeModule() {
				// 在父组件中监听自定义事件
				this.editOpenKw = false;
				this.editOpenMenu = false;
			},
			// 关闭转发
			closeShow() {
				this.showTran = false
			},

			// 跳转到Room
			toRoom() {
				this.$router.push({ path: '/room/info/' + this.data.roomId})
			},

			//跳转至详情页
			content_info(){
				this.utils.b('/room/imageInfo/' + this.data.id)
			},

			setClickCount(id){
				var that = this;
				if(id > 0 && this.clickViodeCount == 0){
					that.clickViodeCount++;
					// eslint-disable-next-line no-unused-vars
					// that.newApi.setVideoClickCount({id:id}).then((res => {
					// }))
				}
				console.log(this.clickViodeCount);
			},
			// 点击打开用户信息
			openInfo(uuid, userType) {
        if (!this.utils.toAuth(1)) {
          return false;
        }
        if (userType === 1) {
          this.$router.push({ path: '/circle/mySpace/' + uuid})
        }
        if (userType === 2 || userType === 3) {
          this.$router.push({ path: '/circle/organSpace/' + uuid})
        }
			},

			// 点击展示全部内容
			showAllContent() {
				this.showAll = !this.showAll
			},

			//下拉菜单触发的方法
			more_action:function(e){
				if (this.is_login == 0) {
					this.utils.err("请先登录");
					return;
				}
				var that = this;
				if (e == "collection_user") {
					this.collection_user();
				}
				if (e == "delete") {
					that.$confirm('删除后无法恢复，确认删除吗？').then(_ => {
						console.log(_);
						this.newApi.delRoomContent({id:that.data.id}).then(ret =>{
							if(ret.isSuccess == 1){
								that.utils.sus(ret.data);
								that.show = false;
								this.$EventBus.$emit("changeData");
							}
						})
					})
				}
				if (e == "edit") {
					if(this.kwVideo.isKwFormat == 0){
						that.editOpenMenu =true;

					}else if(this.kwVideo.isKwFormat == 1){
						that.editOpenKw =true;
					}

				}
				if (e == 'circle') {
					that.showTran = true
				}
			}

		}
	}

</script>

<style scoped>
	.VideoContent{
		width: 100%;
		padding: 24px 38px 10px;
		box-sizing: border-box;
		margin-bottom: 10px;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;
		box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
	}
	.VideoContent .head{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.VideoContent .head .user{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.VideoContent .head .user .userIco{
		width: 60px;
		height: 60px;
		background-color: #707070 ;
		border-radius: 50%;
		margin-right: 12px;
	}
	.VideoContent .head .user .major .userName{
		font-weight: 600;
		color: #444444;
		font-size: 16px;
		display: flex;
		justify-content: flex-start;
		align-items:flex-end;
	}
	.VideoContent .head .user .major .userMajor{
		font-size: 13px;
		color: #999999;
		margin-top: 5px;

	}
	.VideoContent .substance{
		margin-top: 6px;
		line-height: 26px;
		font-size: 15px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.VideoContent .substance .contentType{
		color: #031F88;
		height: 16px;
		font-weight: 600;
		margin-right: 10px;
	}
	.VideoContent .substance .t{
		color: #555555;
	}
	.VideoContent .video{
		margin-top: 4px;
	}
	.spacebetween{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.VideoContent .volume{
		height: 30px;
		line-height: 30px;
		color: #999999;
		font-size: 14px;
	}
	.VideoContent .bottom{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		line-height:50px;
		height: 50px;
		color: #444444;
		font-size: 16px;
		text-align: center;
	}
	.VideoContent .bottom .btn{
		display: flex;
		justify-content:center;
		align-items: center;
		flex: 1;
	}

	.VideoContent .content {
		text-overflow:ellipsis;
		-webkit-line-clamp:4;
		-webkit-box-orient:vertical;
		overflow:hidden;
		display:-webkit-box;
		margin-bottom: 12px;
	}
	.VideoContent .content::before{
		content: '';
		float: right;
		width: 0;
		height: 78px;
	}
	.VideoContent .contentAll {
		overflow:hidden;
		margin-bottom: 12px;
	}

	.mr5{
		margin-right: 5px;
	}

</style>

<style>
.html img{
	width: 100% !important;
	height: auto !important;
}
</style>
