<template>
	<div class="information">
		<div class="triangle"></div>
		<div class="head">
			<div class="title">商务信息</div>
			<div class="more hand op" @click="moreBusiness">NEXT</div>
		</div>
		<div class="main hand op" v-for="(item, index) in businessList" :key="index" @click="toBusiness(item.id)">
			<div class="top">
        <div class="type" v-if="item.type === 1" style="background: #3C56CF">招标</div>
        <div class="type" v-if="item.type === 2" style="background: #FE9544">采购</div>
        <div class="type" v-if="item.type === 3" style="background: #40AC97">招募</div>
        <div class="type" v-if="item.type === 4" style="background: #9079C1">商务</div>
        <div class="type" v-if="item.type === 5" style="background: #E24F48">招商</div>
        <div class="type" v-if="item.type === 6" style="background: #59C3D8">需求</div>
				<div class="project">{{item.title}}</div>
			</div>
			<div class="info">
				<div style="flex: 1;overflow: hidden;">
					<span class="infoType">发布时间：</span><span class="infoContent">{{item.createTime}}</span>
				</div>
			</div>
			<div class="info">
				<div style="flex: 1;overflow: hidden;">
					<span class="infoType">地域：</span><span class="infoContent">{{item.address[1]}}</span>
				</div>
				<div style="flex: 1;overflow: hidden;">
					<span class="infoType">采购人：</span><span class="infoContent">{{item.contacts}}</span>
				</div>
			</div>
			<div class="info">
				<div style="flex: 1;overflow: hidden;">
					<span class="infoType">预算资金：</span><span class="infoContent">{{item.other.budget}}元</span>
				</div>
			</div>
			<div class="info">
				<div style="flex: 1;overflow: hidden;">
					<span class="infoType">工作方式：</span><span class="infoContent">云服务</span>
				</div>
			</div>

			<hr v-if="index != 4" style="border:1px dashed #999999;margin-top: 20px;" />
			<hr v-if="index == 4" style="border:0px dashed #999999;margin-top: 20px;" />
		</div>

	</div>
</template>

<script>
	export default {
		name:"BusinessInformation",
		components: {

		},
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            }
        },
		data () {
			return {
				page: 1,
				pageSize: 5,
				businessList: []
			}
		},
		props:{
			// list:{
			// 	type:Array,
			// 	default(){
			// 		return [];
			// 	}
			// }

        },
		created:function(){

		},
		mounted: function () {
      if (this.is_login) {
        this.getBusiness()
      }
		},
		methods:{
			// 更多商务信息
			moreBusiness() {
				this.getBusiness()
			},

			// 跳转详情
			toBusiness(id) {
				this.utils.b('/index/details/' + id)
			},

			// 获取推荐的商务信息
			getBusiness() {
				this.newApi.getBusinessInfoList({type: 0, page: this.page, pageSize: this.pageSize, status: 1}).then(res => {
					if (res.data.data.length < 5) {
						this.businessList = res.data.data;
						this.page = 1;
					}
					else {
						this.businessList = res.data.data;
						this.page ++;
					}
				})
			},
		}
	}

</script>

<style scoped>
	.information{
		width: 100%;
		height:auto;
		background-color: #FFFFFF;
		position: relative;
		margin-bottom: 10px;
		box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
		box-sizing: border-box;
	}
	.triangle{
		position:absolute;
		top: 0;
		left: 0;
		width: 0px;
		height: 0px;
		border: 14px solid #fff;
		border-top-color: #031F88;
		border-left-color: #031F88;
		border-bottom-color:transparent ;
		border-right-color: transparent;
	}
	.information .head{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 24px 0 24px;
	}
	.information .head .title{
		height: 26px;
		font-size: 21px;
		font-weight: 600;
		color: #031F88;
	}
	.information .head .more{
		height: 19px;
		font-size: 16px;
		font-weight: 600;
		color: #FFA500;
	}
	.information .main{
		margin-top: 20px;
	}
	.information .main .top{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding:0 24px;
	}
	.information .main .top .type{
		width: 30px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		padding: 0 8px;
		font-size: 12px;
		font-weight: 600;
		color: #ffffff;
		background: #FFA500;
		border-radius: 20px 20px 20px 20px;
		margin-right: 10px;
	}
	.information .main .top .project{
		font-size: 18px;
		width: 215px;
		font-weight: 600;
		color: #393939;
	}
	.information .main .info{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 14px;
		font-weight: 600;
		margin-top: 12px;
		padding:0 24px;
	}
	.information .main .info .infoType{
		color: #999999;
	}
	.information .main .info .infoContent{
		color: #2970FF;
	}
</style>
