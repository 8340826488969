<template>
    <div class="notice">
        <div style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;">
            <div style="width: 1500px;margin:0 auto;overflow: hidden;margin-top:98px;">
                <div class="page_content" style="width:1202px;height: auto;float: left;margin: 10px 0 0 294px;min-height: 1000px;">
                    <div style="width: 100%; overflow: hidden; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-bottom: 10px" v-if="inviteList.length > 0">
                        <div style="font-size: 18px; font-weight: 600; margin: 20px 0 18px 30px">Room邀请</div>
                        <div>
                            <el-carousel height="122px" arrow="always" indicator-position="none" :autoplay="false" style="margin-bottom: 20px">
                                <el-carousel-item v-for="(items, indexs) in  inviteList" :key="indexs" style="padding: 0 43px">
                                    <div style="display: flex">
                                        <div v-for="(item, index) in items" :key="index" style="width: 242px; height: 122px; background-color: #F3F3F3; margin-left: 10px; border-radius: 5px; padding: 12px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
                                            <div style="display: flex; margin-bottom: 12px">
                                                <div>
                                                    <div style="width: 86%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                                        <span style="color: #2970FF; font-weight: 600; margin-right: 2px">{{item.roomName}}的管理员</span>
                                                        <span style="color: #666666">邀请您加入</span>
                                                    </div>
                                                    <div style="width: 86%;text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                                        <img src="./imgs/roomIco.png" style="width: 14px; height: 14px;margin-right: 6px; vertical-align: middle">
                                                        <span style="color: #031F88; font-size: 15px; font-weight: 600; vertical-align: middle">{{item.roomName}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display: flex;">
                                                <el-button @click="disposeApply(item.roomId, item.id, 0)" style="width: 96px;height: 29px;background: #D8DADF;border-radius: 5px 5px 5px 5px; line-height: 2px; margin: 0 20px 0 10px">忽略邀请</el-button>
                                                <el-button @click="disposeApply(item.roomId, item.id, 1)" style="width: 96px;height: 29px;background: #FF7E3E;border-radius: 5px 5px 5px 5px; color: #FFFFFF; line-height: 2px">接收邀请</el-button>
                                            </div>
                                        </div>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>

                    <div style="width: 100%; overflow: hidden; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-bottom: 10px" v-if="applyList.length > 0">
                        <div style="font-size: 18px; font-weight: 600; margin: 20px 0 18px 30px">Room内部成员申请</div>
                        <div style="padding: 0 20px 20px 30px; display: flex; flex-wrap: wrap">
                            <div class="card-content" v-for="(item, index) in applyList" :key="index">
                                <div style="display: flex; justify-content: space-between; margin-bottom: 6px">
                                    <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                        <img src="./imgs/roomIco.png" style="width: 14px; height: 14px;margin-right: 6px; vertical-align: middle">
                                        <span style="font-size: 15px; color: #031F88; font-weight: 600; vertical-align: middle">{{item.roomName}}</span>
                                    </div>
                                    <el-button @click="disposeApply(item.roomId, item.id, 0)" style="width: 68px;height: 28px;background: #D8DADF;color: #666666;border-radius: 5px 5px 5px 5px; line-height: 2px">拒绝</el-button>
                                </div>

                                <div style="display: flex; justify-content: space-between">
                                    <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                        <span style="font-weight: 600; color: #2970FF; margin-right: 4px">{{item.user.nickName}}</span>
                                        <span style="color: #999999">申请加入成员</span>
                                    </div>
                                    <el-button @click="disposeApply(item.roomId, item.id, 1)" style="width: 68px;height: 28px;background: #2970FF;border-radius: 5px 5px 5px 5px; color: #FFFFFF; line-height: 2px">确认</el-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="width: 100%; overflow: hidden; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-bottom: 10px" v-if="templateInfoList.length > 0">
                        <div style="font-size: 18px; font-weight: 600; margin: 20px 0 18px 30px">Room模板文档分享申请</div>
                        <div style="padding: 0 20px 20px 30px; display: flex; flex-wrap: wrap">
                            <div class="card-content" v-for="(item, index) in templateInfoList" :key="index">
                                <div style="display: flex; justify-content: space-between; margin-bottom: 6px">
                                    <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                        <span style="font-weight: 600; color: #2970FF; margin-right: 4px">{{item.title}}</span>
                                        <span style="color: #999999">被分享到</span>
                                    </div>
                                    <el-button @click="agreeApply(item.id, 2)" style="width: 68px;height: 28px;background: #D8DADF;color: #666666;border-radius: 5px 5px 5px 5px; line-height: 2px">拒绝</el-button>
                                </div>

                                <div style="display: flex; justify-content: space-between">
                                    <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                        <img src="./imgs/roomIco.png" style="margin-right: 6px; vertical-align: middle">
                                        <span style="color: #031F88; font-weight: 600; vertical-align: middle">{{item.roomName}}</span>
                                    </div>
                                    <el-button @click="agreeApply(item.id, 1)" style="width: 68px;height: 28px;background: #2970FF;border-radius: 5px 5px 5px 5px; color: #FFFFFF; line-height: 2px">确认</el-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="width: 100%; overflow: hidden; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-bottom: 10px" v-if="roomRelevanceList.length > 0">
                        <div style="font-size: 18px; font-weight: 600; margin: 20px 0 18px 30px">Room关联申请</div>
                        <div style="padding: 0 20px 20px 30px; display: flex; flex-wrap: wrap">
                            <div class="card-content" v-for="(item, index) in roomRelevanceList" :key="index">
                                <div style="display: flex; justify-content: space-between; margin-bottom: 6px">
                                    <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                        <span style="font-weight: 600; color: #2970FF; margin-right: 4px">{{item.relevanceName}}</span>
                                        <span style="color: #999999">请求关联</span>
                                    </div>
                                    <el-button @click="agreeProcess(item.id, 0)" style="width: 68px;height: 28px;background: #D8DADF;color: #666666;border-radius: 5px 5px 5px 5px; line-height: 2px">拒绝</el-button>
                                </div>

                                <div style="display: flex; justify-content: space-between">
                                    <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                        <img src="./imgs/roomIco.png" style="margin-right: 6px; vertical-align: middle">
                                        <span style="color: #031F88; font-weight: 600; vertical-align: middle">{{item.isRelevanceName}}</span>
                                    </div>
                                    <el-button @click="agreeProcess(item.id, 1)" style="width: 68px;height: 28px;background: #2970FF;border-radius: 5px 5px 5px 5px; color: #FFFFFF; line-height: 2px">确认</el-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="width: 100%">
                        <div class="notice-body">
                            <div class="notice-content">
                                <div class="notice-title">
                                    <div>我的Room消息</div>
                                </div>
                                <div class="notice-message" style="font-weight: 600" v-if="myRoomInfoList.length === 0">
                                    暂无消息...
                                </div>
                                <div class="notice-message" v-if="myRoomInfoList.length > 0">
                                    <div class="notice-info" v-for="(item, index) in myRoomInfoList" :key="index">
                                        <div style="display: flex; justify-content: space-between; font-size: 16px">
                                            <div style="width: 72%; align-self:center" :style="{color: item.isRead === 1 ? '#AAAAAA' : ''}">{{item.content}} <span v-if="item.type === 2 || item.type === 4" @click="toLookInfo(item)" class="hand op" style="color: #2970FF; font-weight: 600">查看详情</span></div>
                                            <div style="font-size: 13px; color: #999999; line-height: 54px">{{item.createTime}}</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; justify-content: center; width: 100%; margin: 20px 0">
                                      <div class="notice-btn hand op" @click="lastChange" style="margin-right: 20px">上一页</div>
                                      <div class="notice-btn hand op" @click="nextChange">下一页</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RoomNav from "./components/RoomNav";
    export default {
        name: "Notice",
        components: {RoomNav},

        data() {
            return {
                applyList: [],
                inviteList: [],
                templateList: [],

                myRoomInfoList: [],
                myInfoList: [],
                templateInfoList: [],
                roomRelevanceList: [],

                page: 1
            }
        },

        mounted() {
            this.getApplyRoom();
            this.getInviteRoom();
            this.getRoomNoticeInfo();
            this.getTemplateNoticeList();
            this.getRoomRelevance()
        },

        methods: {
          // 上一页
          lastChange() {
            if (this.page === 1) {
              this.utils.msg('已是第一页')
            } else {
              this.page --;
              this.getRoomNoticeInfo()
            }
          },

          // 下一页
          nextChange() {
            if (this.myRoomInfoList.length < 10) {
              this.utils.msg('已是最后一页')
            } else {
              this.page ++;
              this.getRoomNoticeInfo()
            }
          },

            // 报名查看详情 / 已读
            toLookInfo(item) {
                if (item.type === 2) {
                    this.$router.push({name: 'Event',query: {id: item.info.enrollId, type: 'sign',roomId: item.info.roomId,}})
                    this.newApi.setRoomIsRead({id: item.id}).then(res =>{
                        this.utils.sus(res.data)
                    })
                }
                if (item.type === 4) {
                    this.utils.a('/room/serviceInfo/' + item.info.taskId)
                    this.newApi.setRoomIsRead({id: item.id}).then(res =>{
                        this.utils.sus(res.data)
                    })
                }

            },

            // 获取我管理的room中申请关联room的请求
            getRoomRelevance() {
                this.newApi.getRoomRelevanceList().then(res =>{
                    this.roomRelevanceList = res.data
                })
            },

            // 处理room申请关联的请求
            agreeProcess(id, type) {
                this.newApi.processRoomRelevanceRequests({id: id, status: type}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data);
                        this.roomRelevanceList = [];
                        this.getRoomRelevance()
                    }
                })
            },

            // 处理模板分享申请
            agreeApply(id, type) {
                this.newApi.agreeRoomTemplate({id: id, status: type}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data);
                        this.templateInfoList = [];
                        this.getTemplateNoticeList()
                    }
                })
            },

            // 获取room模板分享通知
            getTemplateNoticeList() {
                this.newApi.getSharingRoomTemplateList().then(res =>{
                    this.templateInfoList = res.data
                })
            },

            // 获取未读通知列表
            getRoomNoticeInfo () {
                this.newApi.getMyRoomNoticeList({page: this.page}).then(res =>{
                    if (res.isSuccess === 1) {
                      if (res.data.length === 0 && this.page !== 1) {
                        this.utils.msg('已是最后一页了')
                        this.page --
                      } else {
                        this.myRoomInfoList = res.data;
                      }
                    }

                })
            },

            // 处理申请
            disposeApply(roomId, id, isAgree) {
                this.newApi.agreeOrRefuseToRoomUser({roomId: roomId, id: id, isAgree: isAgree}).then(res =>{
                    this.utils.sus(res.data);
                    this.inviteList = [];
                    this.getApplyRoom();
                    this.getInviteRoom();
                })
            },


            // 获取申请加入room列表
            getApplyRoom() {
                this.newApi.getRoomUsertApplyList().then(res =>{
                    this.applyList = res.data
                })

            },

            // 获取邀请加入room列表
            getInviteRoom() {
                this.newApi.getRoomtInviteList().then(res =>{
                    for (var i = 0; i < res.data.length; i+=4) {
                        this.inviteList.push(res.data.slice(i, i+4))
                    }
                })
            }
        },
    }
</script>

<style scoped>
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }

    .notice-body {
        width: 100%;
        display: flex;
    }

    .notice-btn {
      font-weight: 600;
    }

    .notice-content {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin-right: 10px
    }

    .notice-title {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        height: 24px;
        padding: 18px 20px;
        background-color: #fff;
        margin-bottom: 2px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .notice-message {
        padding: 20px 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: white;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)
    }

    .notice-info {
        width: 540px;
        height: 54px;
        padding: 6px 10px;
        margin-bottom: 20px;
        background-color: #F3F3F3;
        border-radius: 5px 5px 5px 5px;
    }

    .card-content {
        width: 240px;
        height: 64px;
        background-color: #F3F3F3;
        padding: 15px 18px;
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 10px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }


    /deep/ .el-carousel__arrow {
        width: 34px;
        min-height: 122px;
        border-radius: 3px;
        color: #1a1a1a;
        font-size: 20px;
        background-color: #E8E8E8;
    }

    /deep/ .el-carousel__arrow {
        z-index: 3;
    }

    .show-more .inner-container {
        max-height: 800px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .show-more .inner-container::-webkit-scrollbar {
        display: none;
    }

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }

</style>
