<template>
    <div style="padding:20px 40px; min-height: 800px">
        <div style="display: flex">
          <div class="hand op title-box" @click="checkoutList('now')" :style="checkTag === 'now' ? 'color: #2970FF': 'color: #444444'" style="margin-top: 10px; margin-right: 40px">
            <div>正在报名</div>
            <div style="font-size: 14px">{{newList.length}}个活动</div>
          </div>
          <div class="hand op title-box" style="margin-top: 10px" @click="checkoutList('his')" :style="checkTag === 'his' ? 'color: #2970FF': 'color: #444444'">
            <div>历史活动</div>
            <div style="font-size: 14px">{{oldList.length}}个活动</div>
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap" class="event-box" v-if="nowOldList.length > 0">
          <div class="event-body" v-for="(item, index) in nowOldList" :key="index">
            <div class="event-title active">
              <span>{{item.name}}</span>
            </div>
            <div style="width: 100%; height: 112px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
              <img :src="item.cover" class="image-ico" style="width: 100%; height: 112px;">
            </div>
            <div class="event-content">
              <div>
                <div style="font-size: 18px; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.title}}</div>
                <div style="color: #2970FF">{{item.startTime}}</div>
                <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"><span>活动地点： </span> <span>{{item.location}}</span></div>
                <div v-if="item.status == 2">
                  <span>热度： </span>
                  <span v-if="Date.now() > Date.parse(item.endTime)" style="color:red;">活动结束{{item.signNum}}人报名</span>
                  <span v-if="Date.now() < Date.parse(item.endTime)">正在报名 {{item.signNum}}人报名</span>
                </div>
                <div v-if="item.status == 3">
                  <span>热度： </span>
                  <span style="color: red;">活动已取消 </span>
                </div>
              </div>
              <div style="display: flex; justify-content: center">
                <div class="hand op image-btn" @click="toEvent(item.id, 'sign')">查看详情</div>
                <!--                        <div class="hand op send-btn">-->
                <!--                            <img src="../../imgs/send.png" style="width: 14px; height: 14px">-->
                <!--                        </div>-->
              </div>
            </div>
          </div>
        </div>

        <div class="title-box">
            <div>由你组织</div>
            <div style="font-size: 14px">{{myEventList.myCreat.length}}个活动</div>
        </div>
        <div style="display: flex; flex-wrap: wrap" class="event-box" v-if="myEventList.myCreat.length > 0">
            <div class="event-body" v-for="(item, index) in myEventList.myCreat" :key="index">
                <div class="event-title active">
                    <span>{{item.name}}</span>
                </div>
                <div style="width: 100%; height: 112px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
                    <img :src="item.cover" class="image-ico" style="width: 100%; height: 112px;">
                </div>
                <div class="event-content">
                    <div>
                        <div style="font-size: 18px; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.title}}</div>
                        <div style="color: #2970FF">{{item.startTime}}</div>
                        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"><span>活动地点： </span> <span>{{item.location}}</span></div>
                      <div v-if="item.status == 2">
                        <span>热度： </span>
                        <span v-if="Date.now() > Date.parse(item.endTime)" style="color:red;">活动结束{{item.signNum}}人报名</span>
                        <span v-if="Date.now() < Date.parse(item.endTime)">正在报名 {{item.signNum}}人报名</span>
                      </div>
                      <div v-if="item.status == 3">
                        <span>热度： </span>
                        <span style="color: red;">活动已取消 </span>
                      </div>
                    </div>
                    <div style="display: flex; justify-content: center">
                        <div class="hand op image-btn" @click="toEvent(item.id, 'sign')">查看详情</div>
<!--                        <div class="hand op send-btn">-->
<!--                            <img src="../../imgs/send.png" style="width: 14px; height: 14px">-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>

        <div class="title-box" style="margin-top: 10px">
            <div>参与报名</div>
            <div style="font-size: 14px">{{myEventList.mySign.length}}个活动</div>
        </div>
        <div style="display: flex; flex-wrap: wrap" class="event-box" v-if="myEventList.mySign.length > 0">
            <div class="event-body" v-for="(item, index) in myEventList.mySign" :key="index">
                <div class="event-title active">
                    <span>{{item.name}}</span>
                </div>
                <div style="width: 100%; height: 112px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
                    <img :src="item.cover" class="image-ico" style="width: 100%; height: 112px;">
                </div>
                <div class="event-content">
                    <div>
                        <div style="font-size: 18px; font-weight: 600; text-overflow: ellipsis; white-space: nowrap;
                        overflow: hidden">{{item.title}}</div>
                        <div style="color: #2970FF">{{item.startTime}}</div>
                        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"><span>活动地点： </span> <span>{{item.location}}</span></div>
                        <div v-if="item.status == 2">
                          <span>热度： </span>
                          <span v-if="Date.now() > Date.parse(item.endTime)" style="color:red;">活动结束{{item.signNum}}人报名</span>
                          <span v-if="Date.now() < Date.parse(item.endTime)">正在报名 {{item.signNum}}人报名</span>
                        </div>
                        <div v-if="item.status == 3">
                          <span>热度： </span>
                          <span style="color: red;">活动已取消 </span>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center">
                        <div class="hand op image-btn" @click="toEvent(item.id, 'sign')">查看详情</div>
<!--                        <div class="hand op send-btn">-->
<!--                            <img src="../../imgs/send.png" style="width: 14px; height: 14px">-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "moreView",
        data() {
            return {
                checkTag: 'now',

                myEventList: {
                    myCreat: [],
                    mySign: []
                },

                newList: [],
                oldList: [],
                nowOldList: [],
            }
        },

        props: {
            roomId: {
                type: String,
                default() {
                    return ''
                }
            }
        },

        mounted() {
            this.getEnrollList();
            this.getMyEventList();
            this.checkoutList('now')
        },

        methods: {
            // 获取我的报名事件
            getMyEventList() {
                this.newApi.getMyEnrollEventList().then(res => {
                    this.myEventList = res.data
                })
            },

            // 切换正在和历史
            checkoutList(e) {
                this.checkTag = e;
                if (this.checkTag === 'now') {
                    this.nowOldList = this.newList;
                }
                if (this.checkTag === 'his') {
                    this.nowOldList = this.oldList;
                }

            },


            // 获取报名事件列表
            getEnrollList() {
                this.newApi.getEnrollEventList({roomId: this.$route.params.id, status: 2}).then(res =>{
                    for (const item of res.data) {
                        if (Date.now() < Date.parse(item.endTime)) {
                            this.newList.push(item)
                        }
                        if (Date.now() > Date.parse(item.endTime)) {
                            this.oldList.push(item)
                        }
                    }
                })
            },

            // 进入事件详情
            toEvent(id, type) {
                this.$router.push(
                    {
                        name: 'Event',
                        query: {
                            id: id,
                            type: type,
                            roomId: this.roomId,
                        }
                    }
                )
            },
        }
    }
</script>

<style scoped>
    .title-box {
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .event-body {
        width: 210px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .event-body .event-title {
        height: 40px;
        border-radius: 5px 5px 0 0;
        text-align: center;
        line-height: 40px;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
    }

    .event-body .active {
        width: 210px;
        background-image: url("../../imgs/title1.png");
    }

    .event-body .event-content {
        min-height: 120px;
        padding: 10px 20px 10px 20px;
        line-height: 30px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-body .event-info {
        min-height: 120px;
        padding: 20px;
        line-height: 20px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-body .event-content .image-ico {
        width: 100%;
        height: 112px;
    }

    .event-body .event-content .image-btn {
        width: 110px;
        height: 28px;
        margin-top: 6px;
        margin-right: 14px;
        text-align: center;
        line-height: 28px;
        font-weight: 600;
        border-radius: 5px;
        background-color: #E4E6EB;
    }

    .event-body .event-content .send-btn {
        width: 44px;
        height: 24px;
        margin-top: 6px;
        line-height: 28px;
        padding: 2px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #E4E6EB;
        font-weight: 600;
        text-align: center;
    }
</style>
