<template>
    <div class="transpond">
            <div style="margin-bottom: 20px">
                <div style=" display: flex;">
                    <img :src="user.headIco" style="width: 36px; height: 36px; border-radius: 50%; margin-right: 12px; vertical-align: middle">
                    <el-input style="height: 32px; margin-right: 12px" v-model="sendContent"></el-input>
                </div>
            </div>
            <div>
                <!--艾特好友-->
                <div class="input_box_textarea" style="margin-top:10px; width: 96%;"  v-if="at_open">
                    <div class="at" style="height: 50px;background:  #FAFAFA;padding: 10px;">
                        <el-tag type="info" style="color:#031F88;margin:2.5px 5px;" v-for="(item,index) in at_user_list" :key="index" size="mini" closable @close="removeAt(index)"> @{{item.friendInfo.nickName}}</el-tag>
                    </div>
                    <div style="display:flex; justify-content: space-between; overflow: hidden;height: 40px;line-height: 40px;background:  #FAFAFA;padding:  0 10px; border-top:1px dashed #ccc;">
                        <div style="float: left;">您可以@以下好友</div>
                        <i @click="set_at_open" class="el-icon-caret-top hand" style="font-size: 18px; font-weight: 600; margin-top: 10px"></i>
                    </div>
                    <div style="overflow:auto;margin-top: 10px;">
                        <div v-for="(item,index) in all_user_list" :key="index">
                            <div class="at_user hand op sc " :class="{'active':item.check}" @click="set_at_user(item.id,item.friendInfo.nickName,index)" >
                                <div style="float: left; width: 30px;height:30px;margin-top: 5px; ">
                                    <img :src="item.friendInfo.headIco + '-80x80'" style="width:100%;height:100%;" />
                                </div>
                                <div style="float: left;line-height: 40px;margin: 0 5px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width:50px;">{{item.friendInfo.nickName}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 发布选项展示 -->
                <div class="input_box_btn_list" style="font-size: 16px">
                    <div class="input_box_btn op sc hand" @click="set_at_open" style=" margin-right: 8px">
                        <div class="input_box_btn_icon" style="margin-top: 4px"><img src="../imgs/at.png"/></div>
                        <div class="input_box_btn_name">AT</div>
                    </div>

                    <div class="input_box_btn op sc hand" style="position: relative; margin-right: 8px">
                        <el-dropdown trigger="click" @command="handleCommand" style="height:40px;line-height: 40px;">
                            <div>
                                <div class="input_box_btn_icon" style="margin-top: 2px;"><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 448 512" width="20" height="18" style="fill: rgb(90, 111, 230);"><path d="M440.667 182.109l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l14.623-81.891C377.123 38.754 371.468 32 363.997 32h-40.632a12 12 0 0 0-11.813 9.891L296.175 128H197.54l14.623-81.891C213.477 38.754 207.822 32 200.35 32h-40.632a12 12 0 0 0-11.813 9.891L132.528 128H53.432a12 12 0 0 0-11.813 9.891l-7.143 40C33.163 185.246 38.818 192 46.289 192h74.81L98.242 320H19.146a12 12 0 0 0-11.813 9.891l-7.143 40C-1.123 377.246 4.532 384 12.003 384h74.81L72.19 465.891C70.877 473.246 76.532 480 84.003 480h40.632a12 12 0 0 0 11.813-9.891L151.826 384h98.634l-14.623 81.891C234.523 473.246 240.178 480 247.65 480h40.632a12 12 0 0 0 11.813-9.891L315.472 384h79.096a12 12 0 0 0 11.813-9.891l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l22.857-128h79.096a12 12 0 0 0 11.813-9.891zM261.889 320h-98.634l22.857-128h98.634l-22.857 128z"></path></svg></div>
                                <div class="input_box_btn_name" style="font-size: 16px;color: #000000;">话题</div>
                            </div>
                            <el-dropdown-menu slot="dropdown" >
                                <div style="overflow: hidden; width: 270px; ">
                                    <div style="overflow-y: scroll;width: 280px;height:400px;">
                                        <el-input v-model="topic_like" style="width:90%; padding: 0 5%;" placeholder="请选择或搜索所属话题" @input="search_topic"></el-input>
                                        <el-dropdown-item  v-for="item in top_topic_list" :command="item" :key= "item.id" >
                                            <div style="overflow: hidden;">
                                                <div style="float: left;">#{{item.name}}#</div>
                                                <div style="float: right;">{{item.clickcount}}</div>
                                            </div>
                                        </el-dropdown-item>
                                    </div>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>

                    <div class="input_box_btn op sc hand" style="margin-right: 8px">
                        <el-dropdown @command="change_reply_type" style="margin-right: 10px;  font-size: 15px; font-weight: 600">
                            <div>{{reply_type_name[reply_type]}}<i class="el-icon-arrow-down" style="font-weight: 600"></i></div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="1"><i class="el-icon-view" style="color: #5A6FE6;"></i>允许评论</el-dropdown-item>
                                <el-dropdown-item command="2"><i class="el-icon-lock" style="color: #6654D9" ></i>禁止评论</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>

                    <div class="input_box_btn op sc hand" style="margin-right: 8px">
                        <el-checkbox v-model="isReprinted" :true-label="1" :false-label="2">允许转发</el-checkbox>
                    </div>

                    <div style="display: flex; margin-bottom: 10px; justify-content: end">
                        <el-dropdown @command="change_common_type" style="margin-right: 20px; font-size: 15px; font-weight: 600">
                            <div>{{common_type_name[common_type]}}<i v-if="at_open == false && at_user_list.length <= 0" class="el-icon-arrow-down" style="font-weight: 600; margin-left: 10px"></i></div>
                            <el-dropdown-menu slot="dropdown" v-if="at_open == false && at_user_list.length <= 0">
                                <el-dropdown-item command="1"><i class="el-icon-view" style="color: #5A6FE6;"></i>仅自己</el-dropdown-item>
                                <el-dropdown-item command="2"><i class="el-icon-user" style="color: #FF9800;" ></i>仅好友</el-dropdown-item>
                                <el-dropdown-item command="3"><i class="el-icon-bangzhu" style="color:#E51C23;"></i>仅粉丝</el-dropdown-item>
                                <el-dropdown-item command="4"><i class="el-icon-lock" style="color: #6654D9;"></i>全部</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <div class="hand op sc" style="float: left;">
                            <el-button type="primary" @click="add" style="width: 80px; height: 30px; line-height: 2px; border-color:#FFC180; border-radius: 20px; background-color: #FFC180">转发{{status}}</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: "Transpond",
        props: {
            data: {
                type: Object,
                default() {
                    return {}
                }
            },

            url: {
                type: String,
                default() {
                    return '';
                }
            }
        },

        data() {
            return {
                sendContent: '',

                at_open: false,
                at_user_list: [],
                all_user_list:[],

                topic_like: "",
                top_topic_list:[],
                topicName:"",
                topicId: 0,

                common_type_name:{
                    "1":"仅自己",
                    "2":"仅好友",
                    "3":"仅粉丝",
                    "4":"全部"
                },
                common_type:"4",
                reply_type_name:{
                    "1":"允许评论",
                    "2":"禁止评论",
                },
                isReprinted: 1,
                reply_type:"1",
                status:"",
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        mounted() {
            this.getFriendGroups();
            this.getTopTopicList();  //话题列表
        },

        methods: {
            // 发布
            add() {
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false;
              }
                    var params = {};
                    params.url = that.url;
                    params.uuid = that.data.roomUser;
                    if (that.data.content.title) {
                        params.content = that.data.content.title;
                    } else {
                        params.content = '';
                    }
                    params.title = that.sendContent;
                    let friends = [];
                    for (const at of that.at_user_list) {
                        friends.push(at.friendInfo.uuid)
                    }
                    params.AT = JSON.stringify(friends);
                    params.auth = that.common_type;
                    params.topicId = that.topicId;
                    params.isComment = that.reply_type;
                    params.isReprinted = that. isReprinted;
                    that.newApi.forward(params).then(res =>{
                        if (res.isSuccess == 1) {
                            that.$parent.$parent.closeShow();
                            that.utils.sus(res.data)
                        }
                    })
            },

            // 发布权限
            change_common_type(command) {
                this.common_type = command;
                //只有全部才有话题
                if(this.common_type != 4){
                    this.topicName = "";
                    this.topicId = 0
                }

            },

            //评论权限
            change_reply_type(command){
                this.reply_type = command;
            },

            //获取话题列表
            getTopTopicList:function(){
                var that = this;
                that.newApi.getTopTopicList({}).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.top_topic_list = ret.data;
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },

            //话题搜索及显示
            search_topic(){
                var that = this;
                if (that.topic_like !="") {
                    let param = {};
                    param.like = that.topic_like;
                    that.newApi.getTopTopicList(param).then((ret)=>{
                        if(ret.isSuccess == 1){
                            that.top_topic_list = ret.data;
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }else{
                    that.getTopTopicList();
                }
            },
            handleCommand(obj) {
                this.topicName = "#" + obj.name + "#";
                this.topicId = obj.id;
            },

            // 获取好友
            getFriendGroups() {
                this.all_user_list = [];
                this.newApi.getMyFriendGroupList({}).then(res =>{
                    for (const item of res.data) {
                        for (const friends of item.friendArr) {
                            this.all_user_list.push(friends)
                        }
                    }
                    for (var i = 0; i < this.all_user_list.length; i++) {
                        this.all_user_list[i]['num'] = i
                    }
                })
            },

            // 艾特好友展示
            set_at_open() {
                this.at_open = !this.at_open;
                this.common_type = "4";

            },

            // 移除好友
            removeAt(index) {
                var aindex = this.at_user_list[index].num ;
                this.all_user_list[aindex].check = false;
                this.at_user_list.splice(index,1);
            },

            // 配置用户
            set_at_user(id,nickName,index){
                if (this.all_user_list[index].check) {
                    this.all_user_list[index].check = false;
                    for (var i = 0; i < this.at_user_list.length; i++) {
                        if (this.at_user_list[i].num ==index) {
                            this.at_user_list.splice(i,1);
                        }
                    }
                }else{
                    this.all_user_list[index].check = true;
                    this.at_user_list.push(this.all_user_list[index]);
                }
            },
        },
    }
</script>

<style scoped>
    .transpond {
        width: 100%;
    }
    .input_box_btn{
        float:left; overflow:hidden;padding-right: 18px;
    }
    .input_box_btn_icon{
        float:left; font-size:20px;line-height:38px; margin-right:5px;
    }
    .input_box_btn_name{
        float:left;
    }
    .at_user{
        float: left;margin: 2.5px 5px;width: 90px;height: 40px;overflow: hidden;padding: 0px 5px;
    }
    .at_user.active{
        float: left;margin: 2.5px 5px;width: 90px;height: 40px;background: #C7E0F4;overflow: hidden;padding: 0px 5px;
    }
    .input_box_textarea{
        width:100%;
    }
    .input_box_btn_list{
        overflow:hidden; height:40px;line-height:40px;
    }
</style>
