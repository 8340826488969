<template>
    <div class="reserve">
        <div>
            <div style="margin-bottom: 40px">
                <div class="title-form">文档类型</div>
                <div>
                    <el-radio v-model='reserve.type' label="1" style="margin-bottom: 10px; margin-top: 10px; font-weight: 600;">
                        新建文档
                    </el-radio>
                    <el-radio v-model='reserve.type' label="2" style="font-weight: 600">模板文档
                    </el-radio>
                </div>
            </div>
            <div>
                <div style="margin-bottom: 40px">
                    <div class="title-form">文档名称</div>
                    <div>
                        <el-input
                                type="textarea"
                                v-model="reserve.title"
                                :show-word-limit="true"
                                maxlength="100"
                                :rows="2"
                                minlength="4"
                                placeholder="审批流名称">
                        </el-input>
                    </div>
                </div>

                <div v-if="reserve.type == 1">
                    <div style="margin-bottom: 40px">
                        <div class="title-form">文档详情</div>
                        <div style="margin-bottom: 20px">
                            <Editor :tinymce_height="'600'" id="1" :innerDrawer="innerDrawer = true" :value="reserve.content" ref="child"></Editor>
                        </div>
                    </div>
                </div>

                <div v-if="reserve.type == 2">
                    <div style="margin-bottom: 40px">
                        <div class="title-form">选择模板文档</div>
                        <el-select placeholder="选择填写审批模板" style="margin-bottom: 30px; width: 216px" v-model="templateId" @change="getTemplateInfo">
                            <el-option
                                    v-for="item in templateList"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>

                    <div style="margin-bottom: 40px" v-if="templateId && renderComponent">
                        <form-builder ref="formBuilder" v-model="formVal" :buildData="formCode"></form-builder>
                    </div>
                </div>

                <div style="margin-bottom: 40px">
                    <div class="title-form">审批流程</div>
                    <el-select v-for="(item, index) in reserve.userList" @visible-change="changeOption" clearable @clear="clearOption(index)" :key="index" v-model="reserve.userList[index]" :placeholder="'选择' + (index + 1) + '级审批人'" style="margin-right: 10px; margin-bottom: 30px; width: 216px">
                        <el-option
                                v-for="items in roomUserList"
                                :key="items.uuid"
                                :label="items.nickName"
                                :value="items.uuid">
                        </el-option>
                    </el-select>
                    <span class="hand op" style="width: 190px; height: 60px; background-color: #F1F1F1; text-align: center; padding: 10px 14px 10px 10px; border-radius: 5px" @click="reserve.userList.push('')">+ 添加审批层级</span>
                </div>

                <div style="margin-bottom: 30px; display: flex; justify-content: center">
                    <div class="hand op foot-btn" style="background-color: #2970FF; color: #FFFFFF" @click="addEvent">确认创建事件</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import FormBuilder from "@/components/common/formDesigner/formBuilder";
    import Editor from "@/components/common/Editor.vue";
    export default {
        name: "reserveForm",
        components: {Editor, FormBuilder},
        data() {
            return {
                type: '0',
                reserve: {
                    type: '1',
                    title: '',
                    content: '',
                    userList: [''],
                },

                draftBoxList: [],

                roomUserList: [],

                options: [
                    {
                        value: '0',
                        label: '24小时',
                    },
                    {
                        value: '1',
                        label: '48小时',
                    }
                ],

                page: 1,
                pageSize: 10,
                templateList: [],
                templateId: '',
                formVal: '',
                formCode: '',
                renderComponent: true
            }
        },
        props: {
          roomId: {
            type: String,
            default() {
              return ''
            }
          }
        },
        mounted() {
            this.getRoomUser();
            this.getRoomTemplates();
        },

        methods: {
            // 删除审批人操作
            clearOption(index) {
                this.reserve.userList.splice(index, 1)
                this.getRoomUser()
            },

            // 改变审批人选项
            changeOption(e) {
                if (e === true) {
                    for (const item of this.reserve.userList) {
                        if (item) {
                          this.roomUserList = this.roomUserList.filter(user => user.uuid !== item)
                        }
                    }
                }
            },

            // 获取模板文档信息
            getTemplateInfo(e) {
                this.renderComponent = false;
                if (e) {
                    this.newApi.getRoomTemplateInfo({id: e}).then(res =>{
                        this.formVal = JSON.stringify(res.data.json.list);
                        this.formCode = JSON.stringify(res.data.json);
                        this.$nextTick(() => {
                            this.renderComponent = true;
                        });
                    })
                }
            },


            // 获取Room审批模板列表
            getRoomTemplates() {
                this.newApi.getRoomTemplateList({roomId: this.roomId, type: JSON.stringify([0]), page: this.page, pageSize: this.pageSize}).then(res =>{
                    for (var i = 0; i < res.data.TAndD.length; i++) {
                        this.templateList.push(res.data.TAndD[i]);
                    }

                    if (res.data.length < 10) {
                        this.more = 0;
                    }
                    this.page++;
                })
            },


            // 下载附件
            downloadFiles(url, name) {
                this.utils.download(url, name)
            },

            // 创建事件
            addEvent() {
                var params = {};
                params.type = this.reserve.type;
                params.title = this.reserve.title;
                params.roomId = this.roomId;
                if (this.reserve.type == 1) {
                    params.content = this.$refs.child.get_content();
                }
                if (this.reserve.type == 2 && this.templateId) {
                    params.content = JSON.stringify({content: this.$refs.formBuilder.getContent(), json: this.formCode})
                }
                params.userList = this.reserve.userList.filter(item => item);
                console.log(params)
                if (!params.content) {
                    this.utils.err('请填写文档内容！');
                    return
                }
                if (params.userList.length === 0) {
                  this.utils.err('请选择审批人！');
                  return
                }
                params.userList = JSON.stringify(params.userList)
                this.newApi.addExamine(params).then(res =>{
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data);
                        this.reserve = {
                            title: '',
                            content: '',
                            userList: [''],
                        };
                        this.$parent.$parent.closeEvent()
                    }
                })
            },

            // 获取room成员
            getRoomUser(){
                this.newApi.getRoomUserList({
                    roomId: this.roomId
                }).then(ret =>{
                    for (const item of ret.data.userList) {
                        ret.data.adminList.push(item)
                    }
                    this.roomUserList = ret.data.adminList;
                })
            },
        }
    }
</script>

<style scoped>
    .reserve {
        width: 100%;
    }

    .reserve .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .reserve .title{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
        font-size: 15px;
    }

    .reserve .title::-webkit-scrollbar {
        display: none;
    }

    .reserve .file-box {
        height: 32px;
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }

    .reserve .upload-demo {
        vertical-align: middle;
    }

    /deep/ .upload-demo .el-upload-dragger {
        width: 906px;
        height: 418px;
        background-color: #FBFBFB;
    }

    .reserve .icon-plus {
        color: #2970FF;
        font-size: 44px;
        font-weight: 600;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        margin: 160px 0 14px 0;
    }

    .reserve .foot-btn {
        width: 128px;
        height: 20px;
        font-size: 16px;
        border-radius: 5px;
        text-align: center;
        padding: 10px;
    }


</style>