<template>
  <div class="Navigation">
    <keep-alive :include="cachedComponents">
      <RoomNav @changeType="onChange" name="RoomNav"></RoomNav>
    </keep-alive>

      <New v-if="type === 'new'"></New>
      <Work v-if="type === 'work'"></Work>
      <All v-if="type === 'all'"></All>
      <Task v-if="type === 'task'"></Task>
      <Notice v-if="type === 'notice'"></Notice>
      <MyRoom v-if="type === 'myRoom'"></MyRoom>
      <Manage v-if="type === 'folder'"></Manage>
  </div>
</template>

<script>
import RoomNav from "@/pages/room/components/RoomNav.vue";
import New from "@/pages/room/new.vue";
import Notice from "@/pages/room/notice.vue";
import All from "@/pages/room/all.vue";
import Task from "@/pages/room/task.vue";
import MyRoom from "@/pages/room/myroom.vue";
import RoomInfo from "@/pages/room/info.vue";
import Work from "@/pages/room/index.vue";
import Manage from "@/pages/room/manage.vue";

export default {
  name: "Navigation",
  components: {
    Manage,
    Work,
    RoomInfo,
    MyRoom,
    Task,
    All,
    Notice,
    New,
    RoomNav
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    is_login(){
      return this.$store.state.is_login
    },
  },
  props: {
    types: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      cachedComponents: ['RoomNav'],
      type: 'new',
      roomId: '',
    }
  },
  watch: {
  },
  mounted:function(){
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    if (!this.is_login) {
      this.type = 'all'
    }
  },
  methods:{
    // 接受导航指令
    onChange(type) {
      this.type = type;
    },
  }
}
</script>

<style scoped>

</style>
