import { render, staticRenderFns } from "./TaskCart.vue?vue&type=template&id=070b1e08&scoped=true&"
import script from "./TaskCart.vue?vue&type=script&lang=js&"
export * from "./TaskCart.vue?vue&type=script&lang=js&"
import style0 from "./TaskCart.vue?vue&type=style&index=0&id=070b1e08&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "070b1e08",
  null
  
)

export default component.exports