<template>
    <div class="collect">
        <div>

                <div style="margin-bottom: 40px">
                    <div>
                        <div class="title-form"><span style="color: red; font-size: 16px">* </span>文档名称</div>
                        <div>
                            <el-input
                                    v-model="collectForm.title"
                                    :show-word-limit="true"
                                    maxlength="30"
                                    placeholder="输入名称"
                                    style="width: 90%">
                            </el-input>
                        </div>
                    </div>
                </div>

                <div style="display: flex; margin-bottom: 40px">
                    <div style="flex: 1">
                        <div class="title-form"><span style="color: red; font-size: 16px">* </span>付费金额</div>
                        <div>
                            <el-input
                                    v-model="collectForm.money"
                                    :show-word-limit="true"
                                    placeholder="付费金额"
                                    type="number"
                                    @change="rulesToMoney"
                                    style="width: 180px">
                            </el-input>
                        </div>
                    </div>

                </div>

                <div style="margin-bottom: 40px">
<!--                    <div class="title-form" v-if="collectForm.type === '0'">收款描述</div>-->
                    <div class="title-form"><span style="color: red; font-size: 16px">* </span>文档描述</div>
                    <div style="margin-bottom: 20px">
                      <Editor :tinymce_height="'600'" :innerDrawer="innerDrawer = true" :value="collectForm.content" ref="child"></Editor>
                    </div>

                    <div style="margin-bottom: 20px">

                      <el-upload
                          class="upload-demo"
                          action="https://jsonplaceholder.typicode.com/posts/"
                          multiple
                          :http-request="upload_file" :show-file-list="false">
                        <span class="hand op sc" style="padding: 6px 24px;color: #FF9800; width: 218px; height: 46px; border-radius: 30px; font-size: 18px;border: 2px solid #FF9800"><i class="el-icon-plus" style="font-weight: 600; margin-right: 18px"></i>上传附件</span>

                      </el-upload>

                    </div>
                    <div class="title">
                        <div class="file-box hand op"  v-for="(item, index) in collectForm.enclosure" :key="index">
                            <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                            <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                            <span style="color: red;float: right;font-size: 12px;" @click="removeFile(index)">移除</span>
                        </div>
                    </div>
                </div>

                <div style="margin-bottom: 40px">
                    <div class="title-form"><span style="color: red; font-size: 16px">* </span>事件封面</div>
                    <div>
                      <el-upload class="upload-demo" drag multiple
                                 action=""
                                 :show-file-list="false" :http-request="uploadBack"
                                 :before-upload="beforeBackUpload">
                        <img v-if="collectForm.cover" :src="collectForm.cover" width="894px" height="420px">
                        <i class="el-icon-plus icon-plus"></i>
                        <div style="font-size: 20px">将图片拖到此处，或<em>点击上传</em></div>
                      </el-upload>
                    </div>
                </div>

                <div style="margin-bottom: 30px; display: flex; justify-content: center">
<!--                    <div class="foot-btn" style="background-color: #F0F2F5; margin-right: 20px"><i class="el-icon-message"></i>  草稿箱</div>-->
                    <div class="foot-btn hand op"  style="background-color: #2970FF; color: #FFFFFF" @click="release">确认创建事件</div>
                </div>
        </div>
    </div>
</template>

<script>
    import Editor from "@/components/common/Editor.vue";

    export default {
        name: "collectForm",
      components: {Editor},
        data() {
            return {
              collectForm: {
                enclosure:[],
                title:"",
                content:"",
                money:1
              },
              fmList:[]
            }
        },
        props:{
          roomId:{
            type:String,
            default(){
              return "0";
            }
          }
        },
        mounted() {
        },

        methods: {

            //上传附件
            upload_file:function(params){
              var that = this;
              var file = params.file;
              that.fmList.push(file);
              console.log(file)
              var obj = {
                img_url :"",
                name:file.name,
                size:file.size,
                type:file.type,
                url:''
              };

              that.collectForm.enclosure.push(obj);
            },
            //移除附件
            removeFile:function(index){
              var that = this;
              that.fmList.splice(index, 1);
              that.collectForm.enclosure.splice(index,1);
            },

          rulesToMoney(e) {
              if (e <= 0) {
                this.utils.msg('付费金额必须大于0')
                this.collectForm.money = 1
              }
          },

          release:function(){
            var that =this;
            var params = {};

            params.title = that.collectForm.title;
            params.content = this.$refs.child.get_content();
            params.money = that.collectForm.money;
            params.cover = that.collectForm.cover;
            params.roomId = that.roomId;
            if(params.title == ""){
              that.utils.err("请填写标题");
              return false;
            }

            if(params.content == ""){
              that.utils.err("请填写简介");
              return false;
            }

            if(that.collectForm.enclosure.length == 0 ){
              that.utils.err("请上传文档");
              return false;
            }
            if(params.money == ""){
              that.utils.err("请填写付费金额");
              return false;
            }
            if(params.money <= 0){
              that.utils.err("付费金额必须大于0");
              return false;
            }
            if(params.cover == ""){
              that.utils.err("请上传封面");
              return false;
            }

            let filesPromise = [];
            for (let i = 0; i < that.fmList.length; i++) {
              filesPromise.push(new Promise(function (resolve) {
                that.collectForm.enclosure = [];
                that.utils.upload(that.fmList[i],function(url){
				if (!url){
					that.loading = false;return false
				}
                  let obj = {};
                  obj.img_url = "https://oss.baigongbao.com/img/file.png";
                  obj.name = that.fmList[i].name.substring(0, that.fmList[i].name.lastIndexOf("."));
                  obj.size = that.fmList[i].size;
                  obj.type = that.fmList[i].name.substring(that.fmList[i].name.lastIndexOf("."));
                  obj.url = url;

                  that.collectForm.enclosure.push(obj);

                  params.enclosure = that.collectForm.enclosure;
                  params.enclosure = JSON.stringify(params.enclosure);
                  resolve(obj);
                })
              }))
            }
            Promise.all(filesPromise).then(_=>{
              console.log(_);
              that.addDoc(params);
            })

          },
          addDoc(params){
            var that = this;
            this.newApi.addDoc(params).then((ret)=>{
              if(ret.isSuccess == 1){
                that.collectForm = {};
                that.fmList = [];
                that.enclosure = [];
                that.utils.sus(ret.data);
                that.$emit('closeEvent', {eventType:'j'});
              }
            }).catch((err)=>{
              console.log(err)
            })
          },

          beforeBackUpload(file) {
            const isJPG = file.type;
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
              this.$message.error('上传头像图片大小不能超过 20MB!');
            }
            return isJPG && isLt2M;
          },

            // 上传图片
            uploadBack: function (params) {
                var file = params.file;
                var that = this;

                this.utils.upload(file, function (url) {
	                if (!url){
		                return false;
	                }
                  that.$set(that.collectForm,"cover",url.url);
                })
            },
        }
    }
</script>

<style scoped>
    .collect {
        width: 100%;
    }

    .collect .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .collect .title{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
        font-size: 15px;
    }

    .collect .title::-webkit-scrollbar {
        display: none;
    }

    .collect .file-box {
        height: 32px;
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }

    .collect .upload-demo {
        vertical-align: middle;
    }


    /deep/ .upload-demo .el-upload-dragger {
      width: 894px;
      height: 420px;
      background-color: #FBFBFB;
    }


    .collect .icon-plus {
        color: #2970FF;
        font-size: 44px;
        font-weight: 600;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        margin: 160px 0 14px 0;
    }

    .collect .foot-btn {
        width: 128px;
        height: 20px;
        font-size: 16px;
        border-radius: 5px;
        text-align: center;
        padding: 10px;
    }


</style>
