<template>
    <div class="information">
        <div class="shadow" style="width: 100%;overflow: hidden;background-color: #FFFFFF;margin-top: 10px; margin-bottom: 2px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
            <div style="display: flex;justify-content: flex-start;align-items: center;">
                <div class="room-tab hand" :class="tab==0?'room-type-bottom':''"
                     @click="setTab(0)">全部
                </div>
                <div style="color: #707070; line-height: 50px">|</div>
                <div class="room-tab hand" :class="tab == 1?'room-type-bottom':''"
                     @click="setTab(1)">招标信息
                </div>
                <div style="color: #707070; line-height: 50px">|</div>
                <div class="room-tab hand" :class="tab == 2?'room-type-bottom':''"
                     @click="setTab(2)">采购信息
                </div>
                <div style="color: #707070; line-height: 50px">|</div>
                <div class="room-tab hand" :class="tab == 3?'room-type-bottom':''"
                     @click="setTab(3)">招募信息
                </div>
                <div style="color: #707070; line-height: 50px">|</div>
                <div class="room-tab hand" :class="tab == 4?'room-type-bottom':''"
                     @click="setTab(4)">商务信息
                </div>
                <div style="color: #707070; line-height: 50px">|</div>
                <div class="room-tab hand" :class="tab == 5?'room-type-bottom':''"
                     @click="setTab(5)">招商信息
                </div>
                <div style="color: #707070; line-height: 50px">|</div>
                <div class="room-tab hand" :class="tab == 6?'room-type-bottom':''"
                     @click="setTab(6)">需求信息
                </div>
                <div style="width: 260px">
                    <el-input size="small" placeholder="请输入内容" v-model="searchContent" @input="getRoomQuestion">
                        <el-button slot="append" icon="el-icon-search" @click="getRoomQuestion()"></el-button>
                    </el-input>
                </div>
            </div>

            <div>
                <div style="display: flex; background-color: #EFEFEF; padding: 10px 20px">
                    <div style="width: 15%; text-align: center">
                        信息类型
                    </div>
                    <div style="width: 40%; text-align: center">
                        项目标题
                    </div>
                    <div style="width: 15%; text-align: center">
                        项目预算
                    </div>
                    <div style="width: 15%; text-align: center">
                        地点
                    </div>
                    <div style="width: 15%; text-align: center">
                        服务方式
                    </div>
                    <div style="width: 15%; text-align: center">
                        报名截至时间
                    </div>
                </div>
                <div v-for="(item, index) in questionList" :key="index">
                    <div class="box hand op" @click="utils.b('/index/details/' + item.id)">
                        <div style="padding:10px 20px; display: flex;">
                            <div style="width: 15%; text-align: center; margin: auto 0">
                                {{item.typeName}}
                            </div>
                            <div style="width: 40%; text-align: center; color: #031F88; margin: auto 0">
                                {{item.title}}
                            </div>
                            <div style="width: 15%; text-align: center; margin: auto 0">
                                ¥{{item.other.budget}}
                            </div>
                            <div style="width: 15%; text-align: center; margin: auto 0">
                                {{item.address[0]}} <span v-if="item.address[0] !== item.address[1]">{{item.address[1]}}</span>
                            </div>
                            <div style="width: 15%; text-align: center; margin: auto 0" v-if="item.other.serviceMode == '0'">
                                云端服务
                            </div>
                            <div style="width: 15%; text-align: center; margin: auto 0" v-if="item.other.serviceMode == '1'">
                                驻场服务
                            </div>
                            <div style="width: 15%; text-align: center; margin: auto 0" v-if="item.other.serviceMode == null && item.other.serviceMode == undefined">
                                其它服务
                            </div>
                            <div style="width: 15%; text-align: center; color: #FF0019; margin: auto 0">
                                {{item.endTime}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Information",
        data() {
            return {
                tab:0,
                pay: false,
                page: 1,
                more: 1,
                pageSize: 10,
                searchContent: '',
                questionList: []
            }
        },
        props: {
        },

        mounted() {
            this.getRoomQuestion();
            //监听发布模版信息刷新
            this.$EventBus.$on("refreshQuestion", () => {
                this.tab = 0;
                this.refreshQuestion()
            });
            var that = this;
            window.onscroll = function () {
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                //滚动条到底部的条件
                var ratio = 0;
                var screen = window.screen;
                var ua = navigator.userAgent.toLowerCase();
                if (typeof window.devicePixelRatio !== "undefined") {
                    ratio = window.devicePixelRatio;
                } else if (~ua.indexOf('msie')) {
                    if (screen.deviceXDPI && screen.logicalXDPI) { //IE
                        ratio = screen.deviceXDPI / screen.logicalXDPI;
                    }
                } else if (typeof window.outerWidth !== 'undefined' && typeof window.innerWidth !== 'undefined') {
                    ratio = window.outerWidth / window.innerWidth;
                }

                if (ratio <= 1) {
                    if (scrollTop + windowHeight >= scrollHeight) {
                        if (that.more == 1) {
                            that.getRoomQuestion();
                        } else {
                            that.utils.sus("已加载完");
                        }
                    }
                } else {
                    if (scrollHeight - scrollTop - windowHeight < 10) {
                        if (that.more == 1) {
                            that.getRoomQuestion();
                        } else {
                            that.utils.sus("已加载完");
                        }
                    }
                }
            }
        },

        methods: {
            // 切换标签
            setTab(e) {
                this.tab = e;
                this.page = 1;
                this.more = 1;
                this.questionList = [];
                this.getRoomQuestion()
            },

            // 获取商务信息
            getRoomQuestion() {
                let params = {
                    roomId: this.$route.params.id,
                    page: this.page,
                    pageSize: this.pageSize,
                    status: 1,
                    type: this.tab,
                    like: this.searchContent
                };
                this.newApi.getBusinessInfoList(params).then(res =>{
                    this.questionList = [];
                    for (var i = 0; i < res.data.data.length; i++) {
                        this.questionList.push(res.data.data[i]);
                    }
                    if (res.data.data.length < 10) {
                        this.more = 0;
                    }
                })
            },

            // 发布刷新
            refreshQuestion() {
                this.page = 1;
                this.more = 1;
                this.questionList = [];
                this.getRoomQuestion()
            }
        }
    }
</script>

<style scoped>
    .information .room-tab {
        margin-left: 10px;
        line-height: 50px;
        font-size: 15px;
        width: 90px;
        text-align: center;
    }

    .information .room-type-bottom {
        width: 90px;
        color: #2970FF;
        font-weight: bold;
    }

    .information .box {
        border-bottom: 1px #D9D9D9 solid
    }

    .information .box:hover {
        background-color: #F2F2F2;
    }


</style>
