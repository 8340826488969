<template>
	<div class="input" v-loading="loading">
		<el-input v-if="textShow == true" v-model="content" type="textarea" :rows="3" :maxlength="5000" :autosize="{ minRows: 3, maxRows: 6}" :show-word-limit="true" minlength="10" placeholder="请输入内容" @input="content_input"></el-input>
		<div  class="bottom">
			<div  class="btns">
				<div class="btn op sc hand"   @click="set_upload_open(1)" slot="popover" v-if="menuType === '3' || menuType === ''">
					<i v-if="resourceShow == false" class="el-icon-remove" style="margin-top: 3px;margin-right: 3px;"></i>
					<img v-if="resourceShow == true" src="../imgs/img.png" style="margin-right:10px;width: 16px;height: 16px;">
					图片
				</div>
				<div class="btn op sc hand" @click="set_upload_open(2)" v-if="menuType === '4' || menuType === ''">
					<i v-if="resourceShow == false" class="el-icon-remove" style="margin-top: 3px;margin-right: 3px;"></i>
					<img v-if="resourceShow == true" src="../imgs/video.png" style="margin-right:10px;width: 16px;height: 16px;">
					视频
				</div>
				<div class="btn op sc hand" @click="set_upload_open(3)" v-if="menuType === '5' || menuType === ''">
					<i v-if="resourceShow == false" class="el-icon-remove" style="margin-top: 3px;margin-right: 3px;"></i>
					<img v-if="resourceShow == true" src="../imgs/file.png" style="margin-right:10px;width: 16px;height: 16px;">
					文件
				</div>

				<el-dropdown trigger="click">
					<div class="btn">
						<img src="../imgs/link.png" style="margin-right:10px;width: 16px;height: 16px;">
						链接
					</div>
					<el-dropdown-menu slot="dropdown">
						<div style="overflow: hidden; width: 320px;">
							<el-input v-model="linkContent" placeholder="请输入链接地址" style="width: 260px; margin: 0 10px"></el-input>
						</div>
					</el-dropdown-menu>
				</el-dropdown>

				<div class="btn" @click="set_upload_open(4)" v-if="menuType === '1' || menuType === ''">
          <i v-if="resourceShow == false" class="el-icon-remove" style="margin-top: 3px;margin-right: 3px;"></i>
					<img v-if="resourceShow == true" src="../imgs/content.png" style="margin-right:10px;width: 16px;height: 16px;">
					文章
				</div>
			</div>
			<div class="send hand op"  @click="add">发布</div>
		</div>


		<!--发布知识仓库格式图片-->
		<div v-if="menuImagesShow == true">
			<menu-image :is-show="menuImagesShow" :roomId = parseInt(this.$route.params.id) :menuId = parseInt(this.menuId) :editId="0" :on-close="closeImageModule"></menu-image>
		</div>

		<!--发布知识仓库格式视频-->
		<el-dialog :close-on-click-modal="false" width="482px" :visible.sync="menuVideoShow" :show-close=false class="upload_att">
			<menu-video :roomId = parseInt(this.$route.params.id) :menuId = parseInt(this.menuId) :editId="0" @custom-event="closeViodeModule"></menu-video>
		</el-dialog>

		<!--发布知识仓库格式附件-->
		<el-dialog :visible.sync="menuEnclosureShow" width="730px" :close-on-click-modal="false" :show-close=false>
			<menu-enclosure :roomId = parseInt(this.$route.params.id) :menuId = parseInt(this.menuId) :editId="0" @custom-event="closeEnclosureModule"></menu-enclosure>
		</el-dialog>
	</div>

</template>

<script>
	import menuImage from "./menuModule/menuImage";
	import menuVideo from "./menuModule/menuVideo";
	import menuEnclosure from "./menuModule/menuEnclosure";
	export default {
		name:"Input",
		components: {
			menuImage,
			menuVideo,
			menuEnclosure
		},
		computed: {
			user() {
				return this.$store.state.user
			},
			is_login(){
				return this.$store.state.is_login
			},
			router(){
				return this.$store.state.router
			}
		},

		data () {
			return {
        		loading: false,
				textShow:true,
				resourceShow:true,
				// 文字内容
				content:"",
				// 链接
				linkContent: '',
				//kw格式的
				menuImagesShow:false,
				menuVideoShow:false,
				menuEnclosureShow:false,
			}
		},
		props:{
			roomId: {
				type: String,
				default() {
					return ''
				}
			},

			menuType: {
				type: String,
				default() {
					return ''
				}
			},

			menuId: {
				type: String,
				default() {
					return '0'
				}
			},
		},
		created:function(){

		},
		mounted: function () {
		},
		methods:{
			//关闭添加图片组件
			closeImageModule(){
				this.menuImagesShow = false;
			},
			//关闭添加视频组件
			closeViodeModule() {
				this.menuVideoShow = false;
			},
			//关闭添加附件组件
			closeEnclosureModule(){
				this.menuEnclosureShow = false;
			},

			// 点击展示上传窗口
			set_upload_open(type) {
				console.log(type);
				if(this.resourceShow == false){
					var str = "动态与多媒体不能同事发布";
					switch (type) {
						case 1:
							str = "图片";
							break;
						case 2:
							str = "视频";
							break;
						case 3:
							str = "文件";
							break;
            case 4:
              str = "文章";
              break;
					}
					this.utils.msg("动态与" + str + "不能同时发布");
					return false;
				}else{
					switch (type) {
						case 1:
							this.menuImagesShow = true;
							break;
						case 2:
							this.menuVideoShow = true;
							break;
						case 3:
							this.menuEnclosureShow = true;
							break;
            case 4:
              this.$router.push({name: 'Acticle', query: {id: this.roomId, menuId: this.menuId}})
              break;
					}
				}

			},

			// 富文本输入
			content_input(e) {
				var str = e;
				str = str.replace(/<p>/g, "").replace(/<\/p>/g, "");
				str = str.charAt(str.length - 1);
				if(str != ''){
					this.resourceShow = false;
				}else{
					this.resourceShow = true;
				}
			},

			// 发布
			add() {
				var that = this
				let params = {};
				let content = {};
				content.images = [];
				content.videos = [];
				content.enclosure = [];
				content.link = that.linkContent;
				content.content = that.content;
				if (/^\s*$/.test(that.content)) {
					that.utils.err("请输入内容");
					return false;
				}


				params.roomId = this.$route.params.id;
				params.menuId = this.menuId;
				params.data = "{}";
				params.content = JSON.stringify(content);
				that.newApi.addRoomTemplateContent(params).then((ret)=>{
					if(ret.isSuccess == 1){
						that.utils.sus(ret.data);
						that.resourceShow = true;
						that.linkContent = "";
						that.content = "";
						that.resourceShow = true;
						that.$emit('custom-event');
						that.$EventBus.$emit("roomContentFresh", {menuId: this.menuId});
					}
				})

			},
		}
	}

</script>

<style>
	.input{
		padding:10px;
		background-color: #ffffff;
	}

	.input .bottom{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 18px;
	}
	.input .bottom .btns{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.input .bottom .btns .btn{
		width: 72px;
		display: flex;
		position: relative;
		margin-right: 22px;
		justify-content: center;
		align-items: center;
		height: 18px;
		font-size: 16px;
		color: #6B6B6B;
        cursor: pointer;
	}
	.input .bottom .send{
		width: 88px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		font-size: 16px;
		color: #ffffff;
		background: #2970FF;
		border-radius: 45px 45px 45px 45px;
	}

	.input textarea {
		background-color: #F0F2F5 !important;
	}

	.input .el-input-group {
		height: 20px;
	}
	/deep/ .input .el-input-group__prepend{
		color: #555555 !important;
		background-color: #FFFFFF;
	}

	.at_user{
		float: left;margin: 2.5px 5px;width: 90px;height: 40px;overflow: hidden;padding: 0 5px;
	}
	.at_user.active{
		float: left;margin: 2.5px 5px;width: 90px;height: 40px;background: #C7E0F4;overflow: hidden;padding: 0 5px;
	}
</style>
<style scoped>
	/deep/ .el-dialog__header{
		padding: 0;
	}

	/deep/ .el-dialog__body{
		padding: 0;
	}

	/deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
		color: #fff;
		background-color: #031F88;
	}

	/deep/ .el-tabs__item {
		width: 199px;
		text-align: center;
		border-bottom: #031F88 1px solid !important;
	}
	.file_style {
		display: flex;justify-content: center;margin-top:20px;
	}
	.format {
		height: 16px;color: #999999;font-size: 14px;
		line-height: 20px;
	}

	.folder {
		margin-left: 47px;
	}
	.content_type_folder {
		display: flex;
	}
  /deep/ .el-popper {
    z-index: 9 !important;
  }

	/deep/ .dragger-file {
		width: 600px !important;
	}
	/deep/  .dragger-file .upload-demo .el-upload-dragger {
		width: 600px !important;
	}
</style>
