<template>
    <div class="active-card">
        <div class="event-body">
            <div class="event-title">
                <span>付费文档</span>
                <span style="color: #FFFFFF; float: right; font-size: 14px; margin-right: 10px" v-if="type !== 'all'" class="hand op" @click="$parent.$parent.showMoreDocEvent('付费文档')">更多<i class="el-icon-arrow-right"></i></span>
            </div>
            <div class="event-content">
                <div>
                    <div style="font-size: 18px; font-weight: 600; word-break: break-all">{{data.title}}</div>
                    <div style="color: #2970FF">{{data.createTime}}</div>
                    <div><span>支付金额： </span> <span style="font-weight: 600">{{data.money}}元</span></div>
                    <div><span>付费人数： </span> <span style="font-weight: 600">{{data.buyCount}}人付费</span></div>
                </div>
                <div>
                    <img :src="data.cover" class="image-ico">
                    <div class="hand op image-btn" style="margin-bottom: 8px"  @click="showDetail(data.id)" >查看详情</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DocCard",
        components: {},
        data() {
            return {
            }
        },

        props: {
            data: {
                type: Object,
                default() {
                    return {}
                }
            },
            type: {
              type: String,
              default() {
                return '';
              }
            }
        },

        computed: {
          user() {
            return this.$store.state.user
          },
        },

        mounted() {
        },

        methods: {
          // 进入事件详情
          showDetail(id) {
            this.$router.push({ path: '/room/docDetail/' + id})
          },
        }
    }
</script>

<style scoped>
    .event-body {
        margin-bottom: 10px;
    }

    .event-body .event-title {
        background-image: url("../../imgs/title5.png");
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
    }



    .event-body .event-content {
        min-height: 120px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        line-height: 30px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-body .event-info {
        min-height: 120px;
        padding: 20px;
        line-height: 20px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-body .event-content .image-ico {
        width: 84px;
        height: 66px;
        margin-top: 10px;
    }

    .event-body .event-content .image-btn {
        width: 88px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-weight: 600;
        border-radius: 5px;
        background-color: #E4E6EB;
        color: #2970FF;
    }

    .active-card .top {
        text-align: center;
        position: relative;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }
</style>
