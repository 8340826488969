<template>
    <div>
        <div class="enclosure" v-if="show">
            <div class="head">
                <div class="user" @click="openInfo(data.user.uuid, data.user.userType)">
                    <img :src="data.user.headIco + '-200x200'" class="userIco" >
                    <div class="major">
                        <div class="userName">
                            <div>{{data.user.nickName}}</div>
                            <img v-show="data.user.isVip == 1" src="../imgs/newVip.png" style="width: 24px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.v == 1" src="../imgs/newV.png" style="width: 20px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.authenticationStatus >= 2" src="../imgs/newP.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                            <img v-show="data.user.expertAuthStatus === 2" src="../imgs/newS.png" style="width: 48px;height: 20px;margin-left: 10px;"/>
                        </div>
                        <div class="userMajor"><span v-if="data.user.address.cityName">{{data.user.address.cityName[1]}}</span><span v-if="data.user.major && data.user.address.cityName"> | </span>{{data.user.major}}</div>
                    </div>
                </div>

                <div class="action" v-if="user.uuid == data.user.uuid">
                    <el-dropdown @command="more_action">
					<span class="el-dropdown-link">
						<i class="el-icon-caret-bottom" style="font-size: 24px"></i>
					</span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-if="user.uuid == data.user.uuid" command="edit">编辑</el-dropdown-item>

<!--                            <el-dropdown-item v-if="user.uuid == data.user.uuid" command="weChart">微信分享</el-dropdown-item>-->

<!--                            <el-dropdown-item command="share">转移到其他Room</el-dropdown-item>-->

<!--                            <el-dropdown-item command="share">IM给好友</el-dropdown-item>-->

<!--                            <el-dropdown-item command="circle">转发朋友圈</el-dropdown-item>-->

                            <el-dropdown-item v-if="user.uuid == data.user.uuid"  command="delete" >删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>

            <!-- 文件夹 -->
            <div class="title">
                <div class="substance content">
                    <span class="contentType" @click="toRoom" v-show="this.$parent.showRoomName">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                        <span>{{data.roomName}}</span>
                    </span>
                    <span v-html="data.content.content" class="html hand" @click="content_info"></span>

                </div>

                <div class="file-box hand op" @click="downloadFiles(item.url, item.name)" v-for="(item, index) in data.content.enclosure" :key="index">
                    <img src="../imgs/files.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                    <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                </div>
            </div>
            <div>
                <div style="display: flex">
                    <div class="volume" style="margin-right: 20px">{{data.creationTime}}</div>
<!--                    <div class="volume">阅读量:{{data.clickCount}}</div>-->
                </div>
                <div>
                    <DynamicOperation :data="data"></DynamicOperation>
                </div>
            </div>
        </div>
        <!--转发-->
        <el-dialog :visible.sync="showTran" class="event-form">
            <div style="padding: 40px 40px;">
                <Transpond :data="data" :url="'/room/imageInfo/' + data.id"></Transpond>
            </div>
        </el-dialog>

        <!--编辑知识仓库附件格式-->
        <el-dialog :visible.sync="editOpenKw" width="705px" :close-on-click-modal="false">
            <addUploadAttachment @custom-event="closeEnclosureModule"  :menuId=this.kwEnclosure.menuId :edit-id="this.kwEnclosure.content.kwId" :info=uploadAttachmentInfo></addUploadAttachment>
        </el-dialog>

        <!--编辑知识仓库附件菜单-->
        <el-dialog :visible.sync="editOpenMenu" width="705px" :close-on-click-modal="false">
            <menu-enclosure @custom-event="closeEnclosureModule"  :menuId=this.kwEnclosure.menuId :edit-id="this.kwEnclosure.id"></menu-enclosure>
        </el-dialog>
    </div>
</template>

<script>
    import Transpond from "./Transpond";
    import DynamicOperation from "./DynamicOperation";
    import menuEnclosure from "./menuModule/menuEnclosure";
    import addUploadAttachment from "../knowledge/components/addUploadAttachment";
    export default {
        name: "Enclosure",
        components: {DynamicOperation, Transpond, menuEnclosure,addUploadAttachment},
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                showTran: false,
                show:true,
                editOpenKw:false,
                editOpenMenu:false,
                kwEnclosure:this.data,
                uploadAttachmentInfo:{
                    id:0,
                    auth:1,
                    material_name:"",
                    files:[],
                    types:""
                },
            }
        },
        props:{
            data:{
                type:Object,
                default(){
                    return {
                        enclosure:[],
                        content:"",
                    }
                }
            },

            //是否展示来自那个room
            showRoom:{
                type:Boolean,
                default(){
                    return true
                }
            }
        },
        created:function(){

        },
        mounted: function () {
            if(this.kwEnclosure.isKwFormat == 1){
                this.getKnowledgeInfo();
            }
        },
        methods:{
            //关闭附件组件
            closeEnclosureModule(){
                this.editOpenKw = false;
                this.editOpenMenu = false;
            },
            // 关闭转发
            closeShow() {
                this.showTran = false
            },

            // 跳转到Room
            toRoom() {
                this.$router.push({ path: '/room/info/' + this.data.roomId})
            },

            //跳转至详情页
            content_info(){
                this.utils.b('/room/imageInfo/' + this.data.id)
            },

            // 下载附件
            downloadFiles(url, name) {
                this.utils.download(url, name)
            },

            // 点击打开用户信息
            openInfo(uuid, userType) {
              if (!this.utils.toAuth(1)) {
                return false;
              }
                if (userType === 1) {
                    this.$router.push({ path: '/circle/mySpace/' + uuid})
                }
                if (userType === 2 || userType === 3) {
                    this.$router.push({ path: '/circle/organSpace/' + uuid})
                }
            },

            getKnowledgeInfo: function () {
                var that = this;
                this.newApi.getKnowledgeInfo({
                    id: that.kwEnclosure.content.kwId
                }).then((res) => {
                    that.uploadAttachmentInfo.id = that.kwEnclosure.content.kwId;
                    that.uploadAttachmentInfo.auth = res.data.auth;
                    that.uploadAttachmentInfo.files = res.data.enclosure;
                    that.uploadAttachmentInfo.types = JSON.parse(res.data.kwTypeArr);
                    that.uploadAttachmentInfo.material_name = res.data.title;
                }).catch((err) => {
                    console.log(err)
                })
            },

            //下拉菜单触发的方法
            more_action:function(e){
                if (this.is_login == 0) {
                    this.utils.err("请先登录");
                    return;
                }
                var that = this;
                if (e == "collection_user") {
                    this.collection_user();
                }
                if (e == "delete") {
                    that.$confirm('删除后无法恢复，确认删除吗？')
                        .then(_ => {
                            console.log(_);
                            this.newApi.delRoomContent({id:that.data.id}).then(ret =>{
                                if(ret.isSuccess == 1){
                                    that.utils.sus(ret.data);
                                    that.show = false;
                                    this.$EventBus.$emit("changeData");
                                }
                            })
                        })
                        .catch(_ => {
                            console.log(_);
                        });
                }
                if (e == "edit") {
                    if(this.kwEnclosure.isKwFormat == 0){
                        that.editOpenMenu =true;

                    }else if(this.kwEnclosure.isKwFormat == 1){
                        that.editOpenKw =true;
                    }

                }
                if (e == 'circle') {
                    that.showTran = true
                }
            }

        }
    }
</script>

<style scoped>
    .enclosure{
        width: 100%;
        padding: 24px 38px 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        background-color: #ffffff;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
        cursor: pointer;
    }
    .enclosure .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .enclosure .head .user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .enclosure .head .user .userIco{
        width: 60px;
        height: 60px;
        background-color: #707070 ;
        border-radius: 50%;
        margin-right: 12px;
    }
    .enclosure .head .user .major .userName{
        font-weight: 600;
        color: #444444;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items:flex-end;
    }
    .enclosure .head .user .major .userMajor{
        font-size: 13px;
        color: #999999;
        margin-top: 5px;

    }
    .enclosure .title{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        margin-top: 10px;
        line-height: 26px;
        font-size: 15px;
    }

    .enclosure .title::-webkit-scrollbar {
        display: none;
    }

    .enclosure .title .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }

    .enclosure .main{
        margin-top: 14px;
        height: 200px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .enclosure .main .thumb{
        width: 200px;
        height: 200px;
    }
    .enclosure .main .content{
        flex: 1;
        height: 200px;
        box-sizing: border-box;
        padding: 16px 22px;
        border: 1px solid #BDBFC1;
        border-left: none;
        display: flex;
        align-items: center;
        color: #444444;
        font-size: 16px;
        letter-spacing: 1px;
    }
    .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .enclosure .volume{
        height: 30px;
        line-height: 30px;
        color: #999999;
        font-size: 14px;
    }

    .enclosure .substance{
        margin-top: 4px;
        line-height: 26px;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .enclosure .content {
        text-overflow:ellipsis;
        -webkit-line-clamp:4;
        -webkit-box-orient:vertical;
        overflow:hidden;
        display:-webkit-box;
        margin-bottom: 6px;
    }

    .enclosure .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .enclosure .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }
    .mr5{
        margin-right: 5px;
    }

    .file-box {
        height: 32px;
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;
        transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }
</style>


<style>
.html img{
	width: 100% !important;
	height: auto !important;
}
</style>
