<template>
    <div class="video">
        <div>
            <div style="margin-bottom: 40px">
                <div class="title-form">视频类型</div>
                <div>
                    <el-radio v-model='videoForm.type' label="0" style="margin-bottom: 10px; margin-top: 10px; font-weight: 600;">
                        在线课程
                    </el-radio>
                    <el-radio v-model='videoForm.type' label="1" style="font-weight: 600">视频会议
                    </el-radio>
                </div>
            </div>

            <div>
                <div style="display: flex; margin-bottom: 40px">
                    <div style="flex: 1">
                        <div class="title-form" v-if="videoForm.type === '0'">课程名称</div>
                        <div class="title-form" v-else>会议名称</div>
                        <div>
                            <el-input
                                    type="textarea"
                                    v-model="videoForm.name"
                                    :show-word-limit="true"
                                    maxlength="100"
                                    :rows="2"
                                    minlength="4"
                                    placeholder="输入名称"
                                    style="width: 90%">
                            </el-input>
                        </div>
                    </div>
                    <div style="flex: 1">
                        <div class="title-form" v-if="videoForm.type === '0'">课程日期</div>
                        <div class="title-form" v-else>会议日期</div>
                        <div>
                            <el-date-picker
                                    v-model="videoForm.time"
                                    type="datetime"
                                    placeholder="选择日期及具体时间">
                            </el-date-picker>
                        </div>
                    </div>
                </div>

                <div style="margin-bottom: 40px">
                    <div class="title-form" v-if="videoForm.type === '0'">课程描述</div>
                    <div class="title-form" v-else>会议描述</div>
                    <div style="margin-bottom: 20px">
                        <fancy-editor v-model="videoForm.description"></fancy-editor>
                    </div>

                    <div style="margin-bottom: 20px">
                        <span class="hand op sc" style="padding: 6px 24px;color: #FF9800; width: 218px; height: 46px; border-radius: 30px; font-size: 18px;border: 2px solid #FF9800"><i class="el-icon-plus" style="font-weight: 600; margin-right: 18px"></i>上传附件</span>
                    </div>
                    <div class="title">
                        <div class="file-box hand op" @click="downloadFiles(item.url, item.name)" v-for="(item, index) in videoForm.files" :key="index">
                            <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                            <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                        </div>
                    </div>
                </div>

                <div style="margin-bottom: 40px">
                    <div class="title-form">事件封面</div>
                    <div>
                        <el-upload class="upload-demo" drag multiple
                                   action="https://jsonplaceholder.typicode.com/posts/"
                                   :show-file-list="false" :http-request="uploadBack"
                                   :before-upload="beforeBackUpload">
                            <img v-if="videoForm.image !== '' " :src="videoForm.image" width="100%" height="538px">
                            <i class="el-icon-plus icon-plus"></i>
                            <div style="font-size: 20px">将图片拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                    </div>
                </div>

                <div style="margin-bottom: 40px" v-if="videoForm.type === '0'">
                    <div style="margin-bottom: 30px">
                        <el-checkbox v-model="videoForm.more" style="font-weight: 600">课程收费<span style="color: #999999">（如确认收费该事件会变为收款事件）</span></el-checkbox>
                    </div>
                    <div v-if="videoForm.more === true">
                        <div style="display: flex; margin-bottom: 40px">
                            <div style="flex: 1">
                                <div class="title-form">课程金额</div>
                                <div>
                                    <el-input
                                            v-model="videoForm.money"
                                            :show-word-limit="true"
                                            maxlength="10"
                                            :rows="2"
                                            minlength="4"
                                            placeholder="课程金额"
                                            style="width: 90%">
                                    </el-input>
                                </div>
                            </div>
                            <div style="flex: 1">
                                <div class="title-form">到账延迟</div>
                                <div>
                                    <el-select v-model="videoForm.moneyTime" placeholder="请选择到账延迟">
                                        <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-bottom: 30px; display: flex; justify-content: center">
                    <div class="foot-btn" style="background-color: #F0F2F5; margin-right: 20px"><i class="el-icon-message"></i> {{draftBoxList.length}} 草稿箱</div>
                    <div class="foot-btn" style="background-color: #2970FF; color: #FFFFFF">确认创建事件</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "videoForm",
        data() {
            return {
                tag: '',
                videoForm: {
                    type: '0',
                    name: '',
                    time: '',
                    description: '',
                    files: [{
                        name: '会议记录模板',
                        url: '1',
                        type: '1'
                    }],
                    image: '',
                    more: false,
                    money: '',
                    moneyTime: ''
                },

                draftBoxList: [],

                options: [
                    {
                        value: '0',
                        label: '24小时',
                    },
                    {
                        value: '1',
                        label: '48小时',
                    }
                ],
            }
        },

        mounted() {
        },

        methods: {
            // 添加标签
            addTag() {
                if (this.tag) {
                    this.videoForm.tags.push(this.tag);
                    this.tag = ''
                }
            },

            // 下载附件
            downloadFiles(url, name) {
                this.utils.download(url, name)
            },

            // 上传图片
            uploadBack: function (params) {
                var file = params.file;
                var that = this;

                that.utils.upload(file, function (url) {
	                if (!url){
		                return false;
	                }
                    that.videoForm.image = url;
                })
            },

            beforeBackUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
        }
    }
</script>

<style scoped>
    .video {
        width: 100%;
    }

    .video .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .video .title{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
        font-size: 15px;
    }

    .video .title::-webkit-scrollbar {
        display: none;
    }

    .video .file-box {
        height: 32px;
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }

    .video .upload-demo {
        vertical-align: middle;
    }

    /deep/ .upload-demo .el-upload-dragger {
        width: 906px;
        height: 418px;
        background-color: #FBFBFB;
    }

    .video .icon-plus {
        color: #2970FF;
        font-size: 44px;
        font-weight: 600;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        margin: 160px 0 14px 0;
    }

    .video .foot-btn {
        width: 128px;
        height: 20px;
        font-size: 16px;
        border-radius: 5px;
        text-align: center;
        padding: 10px;
    }


</style>
