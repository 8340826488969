<template>
  <div style="padding:20px 40px; min-height: 800px">
    <div class="title-box">
      <div>活动报名</div>
      <div style="font-size: 14px">{{myEventList.length}}个事件</div>
    </div>
    <div style="display: flex; flex-wrap: wrap" class="event-box" v-if="myEventList.length > 0">
      <div class="event-body" v-for="(item, index) in myEventList" :key="index">
        <div class="event-title active">
          <span>{{item.name}}</span>
        </div>
        <div style="width: 100%; height: 112px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
          <img :src="item.cover" class="image-ico" style="width: 100%; height: 112px;">
        </div>
        <div class="event-content">
          <div>
            <div style="font-size: 18px; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.title}}</div>
            <div style="color: #2970FF">{{item.startTime}}</div>
            <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"><span>活动地点： </span> <span>{{item.location}}</span></div>
            <div><span>热度： </span><span v-if="Date.now() > Date.parse(item.endTime)">活动结束 {{item.signNum}}人报名</span>
              <span v-if="Date.now() < Date.parse(item.endTime)">正在报名 {{item.signNum}}人报名</span></div>
          </div>
          <div style="display: flex; justify-content: center">
            <div class="hand op image-btn" @click="toEvent(item.id, 'sign')">查看详情</div>
          </div>
        </div>
      </div>
    </div>

    <div class="title-box" style="margin-top: 10px">
      <div>付费文档</div>
      <div style="font-size: 14px">{{textList.length}}个事件</div>
    </div>
    <div style="display: flex; flex-wrap: wrap" class="event-box" v-if="textList.length > 0">
      <DocCard type="all" v-for="item in textList" :key="item.id" :data="item" style="margin-right: 20px;margin-bottom: 20px;"></DocCard>
    </div>

    <div class="title-box" style="margin-top: 10px">
      <div>本月日程</div>
      <div style="font-size: 14px">{{dailyList.length}}个事件</div>
    </div>
    <div style="display: flex; flex-wrap: wrap" class="event-box" v-if="dailyList.length > 0">
      <div class="event-body" v-for="(item, index) in dailyList" :key="index">
        <div class="event-title" style="background-color: #FF9800">
          <i class="el-icon-message-solid" style="margin-right: 6px; line-height: 40px"></i>
          日程提醒
        </div>
        <div class="event-info">
          <div style="margin-bottom: 10px; color: #F3425F">{{item.date}}</div>
          <div>
            {{item.content}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocCard from "@/pages/room/components/event/DocCard.vue";

export default {
  name: "MoreAll",
  components: {DocCard},
  data() {
    return {
      checkTag: 'now',

      myEventList: [],
      textList: [],
      dailyList: [],

    }
  },

  props: {
    roomId: {
      type: String,
      default() {
        return ''
      }
    }
  },

  mounted() {
    this.getRoomEnrollList();
    this.getDocList();
    this.getRoomCalendar()
  },

  methods: {
    // 获取Room的报名事件
    getRoomEnrollList() {
      this.newApi.getEnrollEventList({roomId: this.roomId, status: 2}).then(res => {
        this.myEventList = res.data
      })
    },

    // 获取付费文档列表
    getDocList() {
      this.newApi.getDocList({
        roomId: this.roomId
      }).then(res => {
        this.textList = res.data
      })
    },

    // 日程提醒
    getRoomCalendar(){
      let date = new Date();
      this.newApi.getCalendarEventMoreList({type: 2, date: date.getFullYear()+'-'+(date.getMonth() + 1)+'-'+ '01'+ ' 00:00:00', roomId: this.roomId}).then(res => {
        this.dailyList = res.data;
      })
    },

    // 进入事件详情
    toEvent(id, type) {
      this.$router.push(
          {
            name: 'Event',
            query: {
              id: id,
              type: type,
              roomId: this.roomId,
            }
          }
      )
    },
  }
}
</script>

<style scoped>
.title-box {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.event-body {
  width: 210px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.event-body .event-title {
  height: 40px;
  border-radius: 5px 5px 0 0;
  text-align: center;
  line-height: 40px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
}

.event-body .active {
  width: 210px;
  background-image: url("../../imgs/title1.png");
}

.event-body .event-content {
  min-height: 120px;
  padding: 10px 20px 10px 20px;
  line-height: 30px;
  background-color: #FFFFFF;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
}

.event-body .event-info {
  height: 120px;
  padding: 20px;
  line-height: 20px;
  overflow: auto;
  background-color: #FFFFFF;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
}

.event-body .event-info::-webkit-scrollbar {
  width: 0;
}

.event-body .event-content .image-ico {
  width: 100%;
  height: 112px;
}

.event-body .event-content .image-btn {
  width: 110px;
  height: 28px;
  margin-top: 6px;
  margin-right: 14px;
  text-align: center;
  line-height: 28px;
  font-weight: 600;
  border-radius: 5px;
  background-color: #E4E6EB;
}

.event-body .event-content .send-btn {
  width: 44px;
  height: 24px;
  margin-top: 6px;
  line-height: 28px;
  padding: 2px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #E4E6EB;
  font-weight: 600;
  text-align: center;
}
</style>
